import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import customLocalStorage from "../../services/local-storage";
import EmptyTaskPage from "../../shared-components/empty-task-page";
import { primaryButton } from "../../styles/common-styles";
import apiRequest from "../../services/api-request";
import SnackbarComponent from "../../shared-components/snackbar";
import MUIDataTable from "mui-datatables";
import { getMuiDatatableTheme } from "../../styles/material-ui-theme";
import MarketResearchPlannerApprovalPage from "./mr-research-planner-approval-page";
import MarketResearchPlannerBriefStatusDisplay from "../datatable-components/mr-planner-brief-status-display";
import ExpectedDeliveryDateDisplay from "../datatable-components/expected-delivery-date-display";
import DepartmentDisplay from "../datatable-components/department-display";
import DateCreatedDisplay from "../datatable-components/date-created-display";

export default function MarketResearchPlannerHomePage() {
  const initialState = {
    currentPage: "taskListPage",
    pendingTasks: [],
    selectedBrief: {},
    brandCoeId: 0,
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [roles, setRoles] = useState([]);

  const {
    currentPage,
    pendingTasks,
    selectedBrief,
    loading,
    snackBarSeverity,
  } = state;

  const { firstname, lastname, brandCoeId } =
    customLocalStorage.decrypt("brandPortalUser") || {};

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "pendingTasks") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }
      if (dataName === "roles") {
        setRoles(data?.data);
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const makeUsersApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetAllBriefs = async () => {
    handleLoader(true);
    await makeApiRequest({
     uri: `/market-research/briefs/tasks`,
      dataName: `pendingTasks`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllRoles = async () => {
    handleLoader(true);
    await makeUsersApiRequest({
      uri: `/user-management/roles`,
      dataName: `roles`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllBriefs();
      await handleGetAllRoles();
    })();
  }, []);

  const togglePage = (params) => {
    const { currentPage, currentBrief, selectedBrief, roles } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      currentBrief,
      selectedBrief,
      roles,
    }));
  };

  const briefColumns = [
    {
      name: "briefTitle",
      label: "Brief Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "briefType",
      label: "Brief Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "coeDepartment",
      label: "Department",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <DepartmentDisplay departmentName={value?.departmentName} />
        ),
      },
    },
    // {
    //   name: "approximateBudget",
    //   label: "Brief Budget",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "expectedDeliveryDate",
      label: "Expected Delivery Date",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <ExpectedDeliveryDateDisplay expectedDeliveryDate={value} />
        ),
      },
    },
    {
      name: "createdAt",
      label: "Date Created",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateCreatedDisplay dateCreated={value} />,
      },
    },
    {
      name: "briefStatus",
      label: "Status",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (status) => (
          <MarketResearchPlannerBriefStatusDisplay status={status} />
        ),
      },
    },
    {
      name: "briefId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const selectedBrief = pendingTasks?.filter(
            (brief) => brief?.briefId === value
          )[0];

          return (
            <Button
              style={primaryButton}
              onClick={() =>
                togglePage({
                  currentPage: "taskDetailsPage",
                  selectedBrief,
                })
              }
            >
              View Brief
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    download: false,
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, mt: 8 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box sx={{ borderColor: "divider", ml: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex" }}
        >
          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Your Approval Tasks
          </Typography>
        </Stack>

        <Typography textAlign="left" sx={{ mt: 1, mb: 3 }}>
          Welcome {firstname} {lastname}
        </Typography>
      </Box>

      <Box sx={{ mb: 4, mt: 5, ml: 3 }}>
        {currentPage === "taskListPage" && pendingTasks?.length > 0 && (
          <ThemeProvider theme={getMuiDatatableTheme()}>
            <MUIDataTable
              title=""
              data={pendingTasks}
              columns={briefColumns}
              options={options}
            />
          </ThemeProvider>
        )}

        {currentPage === "taskListPage" && pendingTasks?.length < 1 && (
          <Box sx={{ ml: -3 }}>
            <EmptyTaskPage
              emptyPageDescription="There are no tasks yet for you to complete. You will be notified via
          SMS and email when a task is assigned to you"
            />
          </Box>
        )}

        {currentPage === "taskDetailsPage" && (
          <MarketResearchPlannerApprovalPage
            selectedBrief={selectedBrief}
            togglePage={togglePage}
            roles={roles}
            brandCoeId={brandCoeId}
          />
        )}
      </Box>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
