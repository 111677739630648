import axios from "axios";
import customLocalStorage from "./local-storage";
import { encrypt, decrypt } from "./crypto-utils";
import { handleLogOut } from "./logout-handler";
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

const apiRequest = async (params) => {
  const {
    url,
    callBack,
    dataName,
    method,
    contentType = "application/json",
    headers = {},
    data,
  } = params;

  // Encrypt data if not uploading files
  let encryptedData = data;
  if (!url.includes("/upload")) {
    encryptedData = { data: encrypt(JSON.stringify(data)) };
  }

  // Create initial request configuration
  const config = {
    method,
    url,
    data: encryptedData,
    headers: {
      "Content-Type": contentType,
      ...headers,
    },
  };

  // Add authorization header if needed
  const { accessToken } = customLocalStorage.decrypt("brandPortalUser") || {};
  const apiKey = process.env.REACT_APP_API_KEY;
  // use appSecrets from local storage
  // const apiKey = customLocalStorage.decrypt("appSecrets").data.apiKey;

  config.headers["x-api-key"] = apiKey; // Add the x-api-key header to all requests

  if (!url.includes("/login/") && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  // Handle specific response configuration for file download
  if (url.includes("fileId")) {
    config.responseType = "arraybuffer";
    config.headers.Accept = "application/pdf";
  }

  try {
    const response = await axios(config);
    if (response.request.status === 401) {
      handleLogOut(url);
    } else if (response.data?.error && response.request.status >= 400) {
      callBack({
        data: response.data.error,
        dataName,
        severity: "error",
        status: response.request.status,
        message: response.data.error,
      });
    } else {
      const decryptedData = response.data?.data
        ? decrypt(response.data.data)
        : response.data;
      callBack({
        data: decryptedData,
        dataName,
        severity: "success",
        status: response.request.status,
        message: "Success",
      });
    }
  } catch (error) {
    const decryptedData = decrypt(error.response?.data?.data);
    const errorMessage =
      decryptedData?.message ||
      decryptedData?.error_description ||
      error.message;

    callBack({
      data: errorMessage,
      dataName,
      severity: "error",
      status: error.response?.status,
      message: errorMessage,
    });

    if (error.response?.status === 401) {
      handleLogOut(url);
    }
  }
};

export default apiRequest;
