import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { styled } from "@mui/system";
import customLocalStorage from "../services/local-storage";
import jwt_decode from "jwt-decode";
import * as qs from "query-string";
import moment from "moment";
import { Backdrop, CircularProgress, Box } from "@mui/material";
import debounce from "lodash.debounce";
import apiRequest from "../services/api-request";
import LoginPage from "../user-management/login";
import verifyRegistration from "./../services/verify-registration";
import TopNavBar from "./top-nav-bar";
import VerifyEmail from "../user-management/verify-email";
import SideDrawer from "./side-drawer";
import { drawerWidth } from "../styles/material-ui-theme";
import Setups from "../super-admin/setups/setups";
import UserManagement from "../super-admin/super-admin";
import BriefHomePage from "../shared-components/brief-owner/brief-owner-home";
import MarketResearchBriefListPage from "../market-research/mr-brief-owner/mr-brief-list-page";
import SnackbarComponent from "../shared-components/snackbar";
import MarketResearchLeadHomePage from "../market-research/market-research-lead/market-research-lead-home";
import MarketResearchAdminPage from "../market-research/admin/market-research-admin";
import MarketResearchResearchPlannerHomePage from "../market-research/research-planner/mr-research-planner-home";
import MarketResearchLeadAllTasksPage from "../market-research/market-research-lead/market-research-lead-all-tasks-page";
import BriefOwnerDashboardPage from "../shared-components/brief-owner/brief-owner-dashboard";
import PropTypes from "prop-types";
import EmptyEventsPage from "../events-management/empty-events-page";
import TaskManagementPage from "../super-admin/task-management/task-management";
import getAppSecrets from "../services/getAppSecrets";

const RootContainer = styled("div")({
  display: "flex",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // ...theme.mixins.toolbar,  
  justifyContent: "flex-end",
}));

const BackdropContainer = styled(Backdrop)(({ theme }) => ({
  zIndex: 1,
  color: "#fff",
}));

Home.propTypes = {
  onMenuItemClick: PropTypes.func.isRequired,
  selectedComponent: PropTypes.string,
};

Home.defaultProps = {
  selectedComponent: "",
};

export default function Home(props) {
  const { onMenuItemClick, selectedComponent } = props;

  const user = customLocalStorage.decrypt("brandPortalUser");
  // console.log({ user });

  //Record user activity
  if (user) {
    user.lastTimeActive = moment();
    customLocalStorage.encrypt("brandPortalUser", JSON.stringify(user));
  }

  const initialState = {
    loading: false,
    isSignedIn: false,
    otpRequired: false,
    isResetPassword: false,
    isResetPasswordPage: false,
    tfa_token: "",
    autoLogOutTriggered: false,
    isEmailVerified: false,
    isEmailVerifiedPage: false,
    isSideDrawerOpen: false,
    snackbarMessage: "",
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);

  // Drawer toggle open on mobile and desktop
  const [mobileOpen, setMobileOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const {
    isSignedIn,
    otpRequired,
    isResetPassword,
    isResetPasswordPage,
    tfa_token,
    isEmailVerified,
    isEmailVerifiedPage,
    isSideDrawerOpen,
    loading,
    snackbarMessage,
    snackBarSeverity,
  } = state;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const ContentContainer = styled("main")(({ theme }) => ({
    flexGrow: 1,
    // padding: theme.spacing(2),
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    marginLeft: isSignedIn ? (isSideDrawerOpen ? 0 : `-${drawerWidth}px`) : 0,
  }));

  const passwordResetPage = (password_reset_token) => {
    setState((prevState) => ({
      ...prevState,
      isResetPasswordPage: true,
      password_reset_token,
    }));
  };

  const handleLogOut = (params) => {
    let msg = params?.msg ? params?.msg : "Signed out";
    let severity = params?.severity ? params?.severity : "info";
    localStorage.clear();
    setState((prevState) => ({
      ...prevState,
      isSignedIn: false,
      menu: "Home",
      snackbarOpen: true,
      snackBarMessage: msg,
      snackBarSeverity: severity,
    }));
  };

  const handleRefreshToken = (params) => {
    const { data, status, severity } = params;

    if (status === 401) handleLogOut({ msg: data, severity });
    if (!data) return;

    const { accessToken } = data ? data.data : null;
    if (!accessToken) return;

    const { exp } = jwt_decode(accessToken);
    if (!exp) return;
    const user = customLocalStorage.decrypt("brandPortalUser");
    user.exp = exp;
    user.accessToken = accessToken;
    customLocalStorage.encrypt("brandPortalUser", JSON.stringify(user));
  };

  const refreshToken = async () => {
    const user = customLocalStorage.decrypt("brandPortalUser");
    console.log({ user });

    const { refreshToken } = user;

    const data = {
      refreshToken,
    };
    const params = {
      url: `${process.env.REACT_APP_API_URL}/user-management/login/refresh-token`,
      data,
      callBack: handleRefreshToken,
      method: `post`,
    };
    handleLoader(true);
    await apiRequest(params);
    handleLoader(false);
  };

  const checkTokenExpiry = async () => {
    const user = customLocalStorage.decrypt("brandPortalUser");
    const { exp } = user || {};
    const currentTimestamp = moment().unix();
    if (currentTimestamp > exp) {
      await refreshToken();
    }
  };

  const autoLogOut = debounce(async () => {
    const user = customLocalStorage.decrypt("brandPortalUser");
    // console.log({ user });

    const duration = moment
      .duration(moment().diff(user?.lastTimeActive))
      .asMinutes();
    const setTimeOut = process.env.REACT_APP_AUTOLOGOUT_DURATION;
    if (user?.lastTimeActive && duration > setTimeOut) {
      handleLogOut({ msg: `Auto Logout`, severity: `warning` });
    }
    await autoLogOut();
    await checkTokenExpiry();
  }, 10000);

  useEffect(() => {
    // 
    getAppSecrets();
    
    autoLogOut();
    const urlParams = qs.parse(window.location.search);
    const email_verification_token = urlParams?.email_verification_token;
    const password_reset_token = urlParams?.password_reset_token;

    if (email_verification_token) {
      setState((prevState) => ({...prevState, isEmailVerifiedPage: !state.isEmailVerifiedPage}));
      // setState((prevState) => ({...prevState, otpRequired: false}));
      // setState((prevState) => ({...prevState, isSignedIn: false}));
      verifyRegistration({ email_verification_token, toggleVerifyEmail });
    }

    if (password_reset_token) {
      passwordResetPage(password_reset_token);
    }

    const { userId, exp } = customLocalStorage.decrypt("brandPortalUser") || {};
    const currentTimestamp = moment().unix();
    if (userId) {
      if (currentTimestamp < exp) {
        setState((prevState) => ({
          ...prevState,
          isSignedIn: true,
        }));
      }
    }
  }, []);

  const handleSignedInUser = (userData) => {
    console.log({ userData });
    if (!userData?.accessToken) {
      alert(`Problem with sign in, access token missing`);
      return;
    }

    let tokenData = {};

    try {
      tokenData = jwt_decode(userData?.accessToken);
    } catch (error) {
      alert(`Problem accessing tokenData`);
      console.log({ error });
      return;
    }

    const accessToken = userData?.accessToken;
    const refreshToken = userData?.refreshToken;
    const tokenExpiry = userData?.tokenExpiry;

    const user = { ...tokenData, accessToken, refreshToken, tokenExpiry };

    customLocalStorage.encrypt("brandPortalUser", JSON.stringify(user));

    setState((prevState) => ({
      ...prevState,
      isSignedIn: true,
      otpRequired: false,
    }));
  };

  const togglePasswordResetRequest = (tfa_token) => {
    setState((prevState) => ({
      ...prevState,
      isResetPassword: !prevState.isResetPassword,
      tfa_token,
    }));
  };

  const toggleVerifyEmail = ({ isEmailVerifiedPage, isEmailVerified }) => {
    setState((prevState) => ({
      ...prevState,
      isEmailVerified,
      isEmailVerifiedPage,
    }));
  };

  const hasRole = (user, roleName) => user?.role?.includes(roleName);

  const isAdmin = hasRole(user, "ROLE_ADMIN");
  const isMarketResearchLead = hasRole(user, "ROLE_MARKET_RESEARCH_LEAD");
  const isMarketResearchPlanner = hasRole(user, "ROLE_RESEARCH_PLANNER");
  const isBriefOwner = hasRole(user, "ROLE_BRIEF_OWNER");
  const isSuperAdmin = hasRole(user, "ROLE_SUPER_ADMIN");

  const getDefaultComponent = () => {
    if (user?.brandName === "Market Research") {
      if (isAdmin) {
        return "MarketResearchAdminPage";
      } else if (isMarketResearchPlanner) {
        return "MarketResearchResearchPlannerHomePage";
      } else if (isMarketResearchLead) {
        return "MarketResearchLeadHomePage";
      }
    } else if (!user?.brandCoeId) {
      if (isSuperAdmin) {
        return "SuperAdmin";
      } else if (isBriefOwner) {
        // return "BriefHomePage";
        return "MarketResearchBriefListPage";
      }
    }
    return null;
  };

  const renderComponent = () => {
    if (!isSignedIn) {
      return (
        <Box>
          {!isSignedIn &&
            !isResetPassword &&
            !isResetPasswordPage &&
            !isEmailVerifiedPage && (
              <LoginPage
                togglePasswordResetRequest={togglePasswordResetRequest}
                tfa_token={tfa_token}
                handleSignedInUser={handleSignedInUser}
              />
            )}
          {!isSignedIn && !otpRequired && isEmailVerifiedPage && (
            <VerifyEmail
              toggleVerifyEmail={toggleVerifyEmail}
              isEmailVerified={isEmailVerified}
            />
          )}
          {/* <LoginBottomNavBar /> */}
        </Box>
      );
    }

    // Render the components for signed-in users based on the selectedMenuItem and user's role
    if (selectedComponent) {
      switch (selectedComponent) {
        case "Dashboard":
          return <BriefOwnerDashboardPage />;
        case "All Briefs":
          return <BriefHomePage data-testid="brief-owner-default-view" />;
        case "Events":
          return <EmptyEventsPage data-testid="events-default-view" />;
        case "Market Research and Customer Insights":
          return <MarketResearchBriefListPage />;
        case "SuperAdmin":
          return <UserManagement data-testid="super-admin-default-view" />;
        case "Setups":
          return <Setups />;
        case "User Management":
          return <UserManagement />;
        case "Tasks":
          return <TaskManagementPage />;
        case "MarketResearchLeadHomePage":
          return (
            <MarketResearchLeadHomePage data-testid="market-research-lead-default-view" />
          );
        case "MarketResearchLeadAllTasksPage":
          return <MarketResearchLeadAllTasksPage />;
        case "MarketResearchResearchPlannerHomePage":
          return (
            <MarketResearchResearchPlannerHomePage data-testid="market-research-planner-default-view" />
          );
        case "MarketResearchAdminPage":
          return <MarketResearchAdminPage />;
        default:
          return <></>;
      }
    } else {
      // Render the default component based on the user's role
      const defaultComponent = getDefaultComponent();
      switch (defaultComponent) {
        case "MarketResearchBriefListPage":
          return (
            <MarketResearchBriefListPage data-testid="brief-owner-default-view" />
          );
        // return <BriefHomePage data-testid="brief-owner-default-view" />;
        case "SuperAdmin":
          return <UserManagement data-testid="super-admin-default-view" />;
        case "MarketResearchAdminPage":
          return <MarketResearchAdminPage />;
        case "MarketResearchResearchPlannerHomePage":
          return (
            <MarketResearchResearchPlannerHomePage data-testid="market-research-planner-default-view" />
          );
        case "MarketResearchLeadHomePage":
          return (
            <MarketResearchLeadHomePage data-testid="market-research-lead-default-view" />
          );

        default:
          return null;
      }
    }
  };

  const defaultComponent = getDefaultComponent();

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status) => {
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <BackdropContainer open={loading}>
        <CircularProgress />
      </BackdropContainer>
      <RootContainer data-testid="root-container">
        {isSignedIn && (
          <TopNavBar
            handleDrawerToggle={handleDrawerToggle}
            isSideDrawerOpen={isSideDrawerOpen}
            isSignedIn={isSignedIn}
          />
        )}        
        {isSignedIn && (
          <SideDrawer
            data-testid="side-drawer"
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            isSideDrawerOpen={isSideDrawerOpen}
            onMenuItemClick={onMenuItemClick}
            defaultComponent={defaultComponent}
            selectedComponent={selectedComponent}
            isSuperAdmin={isSuperAdmin}
            isAdmin={isAdmin}
            isBriefOwner={isBriefOwner}
            isMarketResearchLead={isMarketResearchLead}
            isMarketResearchPlanner={isMarketResearchPlanner}
          />
        )}

        <ContentContainer
          className={clsx("content", {
            contentShift: isSignedIn && isSideDrawerOpen,
          })}
          data-testid="content-container"
        >
          <DrawerHeader />

          {isSignedIn ? (
            <div
              style={{
                backgroundColor: "#f9f9f9",
                flexGrow: 1,
                paddingTop: "50px",
                marginLeft: drawerWidth,
                minHeight: "100vh",
                paddingBottom: "50px",
              }}
            >
              {renderComponent()}
            </div>
          ) : (
            renderComponent()
          )}
        </ContentContainer>
      </RootContainer>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </>
  );
}
