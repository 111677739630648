import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { inputStyle, helperTextStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  setupsTableHeaderStyle,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = yup.object({
  departmentName: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY, "Maximum 50 characters")
    .required("Department name cannot be empty"),
  departmentShortCode: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_TEN, "Maximum 10 characters")
    .required("Department short code cannot be empty"),
  departmentDescription: yup
    .string()
    .max(
      process.env.REACT_APP_MAX_INPUT_LENGTH_FIVE_HUNDRED,
      "Maximum 500 characters"
    )
    .required("Department description is required"),
});

export default function DepartmentsSetup() {
  const initialState = {
    departments: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [isUpdatingDepartment, setIsUpdatingDepartment] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { departments, loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      departmentName: "",
      departmentShortCode: "",
      departmentDescription: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleAddDepartment(values);
      resetForm();
    },
  });

  const removeDuplicateDepartmentsFromArrayOfObjects = (objArray) => {
    if (objArray.length < 1) return [];

    return [
      ...new Map(objArray.map((item) => [item.departmentName, item])).values(),
    ];
  };

  const handleAddDepartment = (values) => {
    let updatedDepartments = [...departments];
    if (isUpdatingDepartment) {
      const indexOfDepartment = updatedDepartments.findIndex(
        (department) => department.id === values.id
      );
      updatedDepartments[indexOfDepartment] = values;
      setIsUpdatingDepartment(false);
    } else {
      updatedDepartments.push(values);
    }

    updatedDepartments =
      removeDuplicateDepartmentsFromArrayOfObjects(updatedDepartments);

    setState((prevState) => ({
      ...prevState,
      departments: updatedDepartments,
    }));

    handleSubmitDepartment(values);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitDepartment = async (department) => {
    const action = department.id ? "update" : "create";
    const uri = `/market-research/setup/coe-department`;
    const method = action === "update" ? "put" : "post";
    await makeApiRequest({
      uri,
      dataName: "addedDepartment",
      method,
      data: department,
    });
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "success") {
      if (dataName === "departments") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));
      }

      if (dataName === "addedDepartment") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const handleGetAllDepartments = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/setup/coe-department`,
      dataName: `departments`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllDepartments();
    })();
  }, []);

  const handleSelectDepartment = (department) => {
    setIsUpdatingDepartment(true);
    formik.setValues(department);
  };

  const handleDeleteDepartment = async (id) => {
    await makeApiRequest({
      uri: `/market-research/setup/coe-department/${id}`,
      dataName: `deletedDepartment`,
      method: "delete",
      data: {},
    });

    const updatedDepartments = departments.filter(
      (department) => department.id !== id
    );

    setState((prevState) => ({
      ...prevState,
      departments: updatedDepartments,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Add/Edit Departments
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Department Name"
              name="departmentName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.departmentName}
              fullWidth
              required
              error={
                formik.touched.departmentName &&
                Boolean(formik.errors.departmentName)
              }
            />
            {formik.touched.departmentName && formik.errors.departmentName && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.departmentName}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="Department Short Code"
              name="departmentShortCode"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.departmentShortCode}
              fullWidth
              required
              error={
                formik.touched.departmentShortCode &&
                Boolean(formik.errors.departmentShortCode)
              }
            />
            {formik.touched.departmentShortCode &&
              formik.errors.departmentShortCode && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.departmentShortCode}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Department Description"
              name="departmentDescription"
              type="text"
              size="small"
              multiline
              rows={3}
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.departmentDescription}
              fullWidth
              required
              error={
                formik.touched.departmentDescription &&
                Boolean(formik.errors.departmentDescription)
              }
            />
            {formik.touched.departmentDescription &&
              formik.errors.departmentDescription && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.departmentDescription}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Box>
          <Button
            data-testid="submit-department-button"
            type="submit"
            style={
              formik.isValid && formik.dirty ? primaryButton : disabledButton
            }
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isUpdatingDepartment ? "UPDATE" : "ADD"}
          </Button>
        </Box>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 4 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Departments
        </Typography>

        <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={setupsTableHeaderStyle}>
                  Department Name
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Department Short Code
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Department Description
                </TableCell>

                <TableCell style={setupsTableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments?.map((department) => (
                <TableRow
                  key={department}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {department?.departmentName}
                  </TableCell>

                  <TableCell>{department?.departmentShortCode}</TableCell>
                  <TableCell>{department?.departmentDescription}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleSelectDepartment(department);
                      }}
                    >
                      <EditIcon stroke="#121212" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteDepartment(department?.id);
                      }}
                    >
                      <DeleteIcon stroke="#ff2a58" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
