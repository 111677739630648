import React from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";

const ExpectedDeliveryDateDisplay = ({ expectedDeliveryDate }) => (
  <Stack direction="row" spacing={3}>
    <Typography sx={{ fontSize: 15, fontFamily: "Futura" }}>
      {moment(expectedDeliveryDate).format("D/MM/yyyy")}
    </Typography>
  </Stack>
);

ExpectedDeliveryDateDisplay.propTypes = {
  expectedDeliveryDate: PropTypes.string,
};

ExpectedDeliveryDateDisplay.defaultProps = {
  expectedDeliveryDate: "",
};

export default ExpectedDeliveryDateDisplay;
