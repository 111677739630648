import React, { useEffect, useState } from "react";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { primaryButton, numberContainer } from "../styles/common-styles";
import { AddCircle } from "@mui/icons-material";
import apiRequest from "../services/api-request";
import AddUser from "./add-user";
import { ThemeProvider } from "@mui/styles";
import customLocalStorage from "../services/local-storage";
import PropTypes from "prop-types";
import DeleteUserDialog from "./delete-user-dialog";
import SnackbarComponent from "../shared-components/snackbar";
import CustomEmailVerificationStatusCell from "../shared-components/mui-table-custom-user-status";
import CustomStatusCell from "../shared-components/mui-table-custom-status-cell";
import EditIcon from "../assets/icons-js-files/edit-icon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserManagement() {
  const { firstname, lastname } =
    customLocalStorage.decrypt("brandPortalUser") || {};

  const initialState = {
    users: [],
    currentUser: {},
    allRoles: [],
    allDepartments: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const {
    action,
    currentUser,
    users,
    allRoles,
    allDepartments,
    loading,
    snackBarSeverity,
  } = state;

  const handleApiData = (params) => {
    const { severity, data, dataName } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "users" || dataName === "allDepartments") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data ?? [],
        }));
      }

      if (dataName === "allRoles") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data ?? [],
        }));
      }
      if (dataName === "deletedUser") {
        window.location.reload();
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  // const makeDepartmentApiRequest = async (params) => {
  //   handleLoader(true);
  //   const { uri, dataName, method, data } = params;
  //   const requestParams = {
  //     handleLoader,
  //     url: `${process.env.REACT_APP_EVENTS_API_URL}${uri}`,
  //     data,
  //     callBack: handleApiData,
  //     method,
  //     dataName,
  //   };
  //   await apiRequest(requestParams);
  //   handleLoader(false);
  // };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      handleLoader,
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    makeApiRequest({
      //   uri: `/users?page=${page}&limit=${limit}`,
      uri: `/user-management/users`,
      dataName: `users`,
      method: `get`,
      data: {},
    });
    // makeDepartmentApiRequest({
    //   uri: `/market-research/setups/department`,
    //   dataName: `allDepartments`,
    //   method: `get`,
    //   data: {},
    // });
    makeApiRequest({
      uri: `/user-management/roles`,
      dataName: `allRoles`,
      method: `get`,
      data: {},
    });
  }, []);

  const userCount = users?.length;

  const handleOpenDeleteUserDialog = (currentUser) => {
    setUserIdToDelete(currentUser?.userId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteUser = async (userId) => {
    await makeApiRequest({
      uri: `/user-management/users/${userId}`,
      dataName: `deletedUser`,
      method: `delete`,
      data: {},
    });
  };

  const handleConfirmDeleteUser = async () => {
    if (userIdToDelete) {
      await handleDeleteUser(userIdToDelete);
      handleApiData({ dataName: "users" });
    }

    setDeleteConfirmationOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const userColumns = [
    // {
    //   name: "userId",
    //   label: "User ID",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "msisdn",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "brandTeam",
      label: "Brand Team",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Stack direction="row" spacing={3}>
              <Typography sx={{ fontSize: 15 }}>
                {value?.brandCoeName}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "role",
      label: "Roles",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Stack direction="column" spacing={1}>
            {value.map((role) => (
              <Typography key={role.id} sx={{ fontSize: 15 }}>
                {role.displayName}
              </Typography>
            ))}
          </Stack>
        ),
      },
    },
    {
      name: "emailVerificationStatus",
      label: "Email Verified",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <CustomEmailVerificationStatusCell status={value} />
        ),
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <CustomStatusCell status={value} />,
      },
    },
    {
      name: "userId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const currentUser = users?.filter((user) => user?.userId == value)[0];
          return (
            <Stack direction="row">
              <IconButton
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    currentUser,
                    action: "Update",
                  }))
                }
              >
                <EditIcon
                  stroke="#121212"
                  style={{ marginRight: "10px", width: "18px" }}
                />
              </IconButton>
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    download: false,
    jumpToPage: true,
  };

  const handleAddUser = (action) => {
    setState((prevState) => ({
      ...prevState,
      action: action,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Close",
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 8, ml: 8, mr: 8 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Typography
          variant="h5"
          textAlign="left"
          sx={{ fontWeight: "bold", pb: 2 }}
        >
          Manage Users
        </Typography>
        <Typography textAlign="left" sx={{ mb: 2 }}>
          Welcome {firstname} {lastname}
        </Typography>

        <Box sx={{ mt: 1, mb: 3 }}>
          <Typography variant="h6" textAlign="left" sx={{ fontWeight: "bold" }}>
            <span>Users</span>
            <span style={numberContainer}>{userCount}</span>
          </Typography>
        </Box>

        <Box>
          {(action === "Add" || action === "Update") && (
            <AddUser
              action={action}
              users={users}
              allRoles={allRoles}
              allDepartments={allDepartments}
              handleCloseDialog={handleCloseDialog}
              currentUser={currentUser}
            />
          )}
        </Box>

        <MUIDataTable
          style={{ padding: 10 }}
          title={
            <Button
              style={primaryButton}
              onClick={() => {
                handleAddUser(`Add`);
              }}
              startIcon={<AddCircle />}
            >
              New User
            </Button>
          }
          data={users}
          columns={userColumns}
          options={options}
        />

        <DeleteUserDialog
          handleConfirmDeleteUser={handleConfirmDeleteUser}
          handleCancelDelete={handleCancelDelete}
          deleteConfirmationOpen={deleteConfirmationOpen}
        />
      </Box>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </ThemeProvider>
  );
}
