import * as React from "react";
import { Box, Typography } from "@mui/material";
import { emptyPageImageStyle } from "../../styles/common-styles";
import CreateBriefButton from "../create-brief-button";
import PropTypes from "prop-types";

EmptyBriefListPage.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  emptyPageTitle: PropTypes.string,
  emptyPageDescription: PropTypes.string
};

EmptyBriefListPage.defaultProps = {
  buttonText: "",
  emptyPageTitle: "",
  emptyPageDescription: ""
};

export default function EmptyBriefListPage(props) {
  const { buttonText, emptyPageTitle, emptyPageDescription, onClick } = props;

  return (
    <Box
      sx={{
        background: "#fff",
        textAlign: "center",
        p: 10,
        ml: 3,
        borderRadius: 3,
      }}
    >
      <img
        src="/project-images/empty-brief-list.png"
        style={emptyPageImageStyle}
        alt="empty-brief"
      />

      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: 18,
          mt: 1,
          color: "#637381",
        }}
      >
        {emptyPageTitle}
      </Typography>

      <Box sx={{ width: "270px", m: "0 auto", pt: 2 }}>
        <Typography sx={{ opacity: 0.5, fontSize: 14 }}>
        {emptyPageDescription}
          {/* Click on create brief button to create your first brief. */}
        </Typography>
      </Box>

      <Box sx={{ m: "0 auto", pt: 3 }}>
        <CreateBriefButton buttonText={buttonText} onClick={onClick} />
      </Box>
    </Box>
  );
}
