export const primaryButton = {
  background: "#2CB34A",
  color: "#fff",
  width: "auto",
  borderRadius: "20px",
  marginTop: "1%",
  fontFamily: "Futura",
  fontWeight: "bold",
  "&:hover": {
    background: "#000",
  },
  textTransform: "none",
};

export const deleteButton = {
  background: "#EF373E",
  color: "#fff",
  width: "auto",
  borderRadius: "20px",
  border: "none",
  marginTop: "1%",
  fontWeight: "bold",
  "&:hover": {
    background: "#000",
  },
  textTransform: "none",
};

export const cancelButton = {
  background: "#B3B3B3",
  color: "#fff",
  width: "auto",
  borderRadius: "20px",
  marginTop: "1%",
  fontWeight: "bold",
  "&:hover": {
    background: "#000",
  },
  textTransform: "none",
};

export const disabledButton = {
  background: "#e5e5e5",
  borderRadius: "20px",
  textTransform: "none",
};

export const secondaryButton = {
  background: "#fff",
  color: "#2CB34A",
  width: "auto",
  height: "35px",
  border: "1px solid #2CB34A",
  borderRadius: "20px",
  marginTop: "1%",
  fontFamily: "Futura",
  fontWeight: "bold",
  elevation: 0,
  textTransform: "none",
  paddingRight: "10px",
  paddingLeft: "10px",
  "&:hover": {
    background: "#2CB34A",
    color: "#fff",
  },
};

export const loginPrimaryButton = {
  background: "#2CB34A",
  color: "#fff",
  width: "400px",
  borderRadius: "20px",
  "&:hover": {
    background: "#000",
  },
  textTransform: "none",
};

export const loginDisabledButton = {
  background: "#e5e5e5",
  borderRadius: "20px",
  width: "400px",
  textTransform: "none",
};

export const approveButton = {
  background: "#2CB34A",
  color: "#fff",
  width: "235px",
  borderRadius: "20px",
  marginTop: "1%",
  fontWeight: "bold",
  "&:hover": {
    background: "#000",
  },
  textTransform: "none",
};

export const rejectButton = {
  background: "#fff",
  color: "#EF373E",
  width: "235px",
  height: "35px",
  border: "2px solid red",
  borderRadius: "20px",
  marginTop: "1%",
  fontWeight: "bold",
  elevation: 0,
  textTransform: "none",
};

export const rejectButton2 = {
  background: "#fff",
  color: "#EF373E",
  width: "auto",
  border: "2px solid red",
  borderRadius: "20px",
  marginTop: "1%",
  fontWeight: "bold",
  elevation: 0,
};

export const secondaryApprovalButton = {
  background: "#fff",
  color: "#2CB34A",
  width: "235px",
  height: "35px",
  border: "2px solid #2CB34A",
  borderRadius: "20px",
  marginTop: "1%",
  fontWeight: "bold",
  elevation: 0,
  textTransform: "none",
};

export const numberContainer = {
  backgroundColor: "#2CB34A",
  color: "#fff",
  borderRadius: "5px",
  padding: 4,
  fontSize: "14px",
  marginLeft: "15px",
  width: "auto",
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#fff",
  border: "none",
  elevation: 3,
  boxShadow: 24,
  p: 4,
};

export const modalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  height: "auto",
  width: 600,
  backgroundColor: "#fff",
  border: "none",
  elevation: 3,
  boxShadow: 24,
  p: 4,
};

export const modalStyle3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "scroll",
  height: "auto",
  width: 800,
  backgroundColor: "#fff",
  border: "none",
  elevation: 3,
  boxShadow: 24,
  p: 4,
};

export const emptyPageImageStyle = {
  width: "230px",
  height: "183px",
};

export const inputStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px #000 solid",
      borderRadius: "10px",
    },
  },
  mb: 1,
};

export const autocompleteInputStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px #515151 solid",
      borderRadius: "8px",
    },
  },
};

export const sectionHeadingStyle = {
  fontWeight: "bold",
  color: "#2CB34A",
  fontSize: "1.2em",
};

export const setupsTableHeaderStyle = {
  fontWeight: "bold",
};
