import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import apiRequest from "../../services/api-request";
import customLocalStorage from "../../services/local-storage";
import SnackbarComponent from "../../shared-components/snackbar";
import EmptyTaskPage from "../../shared-components/empty-task-page";
import { primaryButton } from "../../styles/common-styles";
import { getMuiDatatableTheme } from "../../styles/material-ui-theme";
import MarketResearchLeadBriefApprovalPage from "./market-research-lead-approval-page";
import ExpectedDeliveryDateDisplay from "../datatable-components/expected-delivery-date-display";
import DateCreatedDisplay from "../datatable-components/date-created-display";
import DepartmentDisplay from "../datatable-components/department-display";
import MarketResearchLeadBriefStatusDisplay from "../datatable-components/mr-lead-brief-status-display";
import { FormikProvider, useFormik } from "formik";
import DepartmentFilterDropdown from "../../shared-components/brief-owner/filters/department-filter-dropdown";

export default function MarketResearchLeadHomePage() {
  const initialState = {
    currentPage: "taskListPage",
    pendingTasks: [],
    selectedBrief: {},
    brandCoeId: 0,
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [roles, setRoles] = useState([]);
  const [departments, setAllDepartments] = useState([]);
 
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const {
    currentPage,
    pendingTasks,
    selectedBrief,
    loading,
    snackBarSeverity,
  } = state;

  const { firstname, lastname, brandCoeId } =
    customLocalStorage.decrypt("brandPortalUser") || {};

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log({ values });
    },
  });

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "pendingTasks") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }
      if (dataName === "roles") {
        setRoles(data?.data);
        console.log({ roles });
      }
      if (dataName === "departments") {
        setAllDepartments(data?.data || []);
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetAllBriefs = async () => {
    handleLoader(true);
    await makeApiRequest({
     uri: `/market-research/briefs/tasks`,
      dataName: `pendingTasks`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllRoles = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/user-management/roles`,
      dataName: `roles`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  const handleGetAllCoeDepartments = async () => {
    await makeApiRequest({
      uri: `/market-research/setup/coe-department`,
      dataName: `departments`,
      method: `get`,
      data: "",
    });
  };

  const handleGetBriefStatuses = async () => {
    await makeApiRequest({
      uri: `/market-research/enum/brief-statuses`,
      dataName: `briefStatuses`,
      method: `get`,
      data: "",
    });
  };

  useEffect(() => {
    (async () => {
      await handleGetAllBriefs();
      await handleGetAllRoles();
      await handleGetAllCoeDepartments();
      await handleGetBriefStatuses();
    })();
  }, []);

  const togglePage = (params) => {
    const { currentPage, currentBrief, selectedBrief, roles } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      currentBrief,
      selectedBrief,
      roles,
    }));
  };

  const handleGetBriefDetails = async (briefId) => {
    await makeApiRequest({
     uri: `/market-research/briefs/${briefId}`,
      dataName: `selectedBriefDetails`,
      method: `get`,
      data: "",
    });
  };



  const handleGetBriefsByDepartment = async (selectedValues) => {
    setSelectedDepartments(selectedValues);
    const departments = selectedValues.join(",");
    await makeApiRequest({
     uri: `/market-research/briefs?departments=${departments}`,
      method: "get",
      dataName: `allBriefs`,
      data: "",
    });
  };

  const handleDepartmentChange = (selectedValues) => {
    setSelectedDepartments(selectedValues);
    handleGetBriefsByDepartment(selectedValues);
  };

  const briefColumns = [
    {
      name: "briefTitle",
      label: "Brief Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "briefType",
      label: "Brief Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "coeDepartment",
      label: "Department",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <DepartmentDisplay departmentName={value?.departmentName} />
        ),
      },
    },
    // {
    //   name: "approximateBudget",
    //   label: "Brief Budget",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "expectedDeliveryDate",
      label: "Expected Delivery Date",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <ExpectedDeliveryDateDisplay expectedDeliveryDate={value} />
        ),
      },
    },
    {
      name: "createdAt",
      label: "Date Created",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateCreatedDisplay dateCreated={value} />,
      },
    },
    {
      name: "briefStatus",
      label: "Status",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (status) => (
          <MarketResearchLeadBriefStatusDisplay status={status} />
        ),
      },
    },
    {
      name: "briefId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const selectedBrief = pendingTasks?.filter(
            (brief) => brief?.briefId === value
          )[0];

          return (
            <Button
              style={primaryButton}
              onClick={() =>
                togglePage({
                  currentPage: "taskDetailsPage",
                  selectedBrief,
                })
              }
            >
              View Brief
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    download: false,
    responsive: "stacked",
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, mt: 8 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box sx={{ borderColor: "divider", ml: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex" }}
        >
          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Your Approval Tasks
          </Typography>
        </Stack>

        <Typography textAlign="left" sx={{ mt: 2, mb: 3 }}>
          Welcome {firstname} {lastname}
        </Typography>
      </Box>

      <Box sx={{ mb: 4, mt: 5, ml: 3 }}>
        {currentPage === "taskListPage" && pendingTasks?.length > 0 && (
          <ThemeProvider theme={getMuiDatatableTheme()}>
            <FormikProvider value={formik}>
              <MUIDataTable
                title={
                  <Stack direction="row" spacing={3} sx={{ mb: 2, mt: 3 }}>
                    <DepartmentFilterDropdown
                      name="departmentIds"
                      label="Brief Department"
                      options={departments.map((dept) => ({
                        name: dept.departmentName,
                        id: dept.id,
                      }))}
                      selectedFilterId={selectedDepartments}
                      onFilterChange={handleDepartmentChange}
                    />
                  </Stack>
                }
                data={pendingTasks}
                columns={briefColumns}
                options={options}
              />
            </FormikProvider>
          </ThemeProvider>
        )}

        {currentPage === "taskListPage" && pendingTasks?.length < 1 && (
          <Box sx={{ ml: -3 }}>
            <EmptyTaskPage
              emptyPageDescription="There are no tasks yet for you to complete. You will be notified via
          SMS and email when a task is assigned to you"
            />
          </Box>
        )}

        {currentPage === "taskDetailsPage" && (
          <MarketResearchLeadBriefApprovalPage
            selectedBrief={selectedBrief}
            togglePage={togglePage}
            roles={roles}
            brandCoeId={brandCoeId}
          />
        )}
      </Box>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
