import React, { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Link,
  Box,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
} from "@mui/material";
import apiRequest from "../services/api-request";
import SnackbarComponent from "../shared-components/snackbar";
import SelfRegistration from "./self-registration";
import OtpValidation from "./otp-validation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  loginPrimaryButton,
  loginDisabledButton,
} from "../styles/common-styles";
import PropTypes from "prop-types";
import ActiveSessionDialog from "./active-session-dialog";

const logoStyle = {
  transform: "scale(0.50)",
  width: "100%",
};

const validationSchema = yup.object({
  username: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

LoginPage.propTypes = {
  handleSignedInUser: PropTypes.func.isRequired,
};

export default function LoginPage(props) {
  const { handleSignedInUser } = props;

  const initialState = {
    userId: "",
    msisdn: "",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    tfa_code: "",
    tfa_token: "",
    isOtpPage: false,
    isRegistrationPage: false,
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [activeUserSessionDialogOpen, setactiveUserSessionDialogOpen] =
    useState(false);
  const [overrideToken, setOverrideToken] = useState(null);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSignIn();
    },
  });

  const { values, errors, touched, isValid, handleChange, handleBlur } = formik;

  const {
    client_id,
    userId,
    msisdn,
    isOtpPage,
    isRegistrationPage,
    snackBarSeverity,
    loading,
  } = state;

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    handleChange(e);

    if (name === "username") {
      setUsername(value);
    }

    if (name === "password") {
      setPassword(value);
    }
  };

  // Function to handle success cases based on dataName
  const handleSuccess = (data, dataName) => {
    switch (dataName) {
      case "requestedOtp":
      case "newUserSession":
        handleNewUserSession(data);
        break;

      case "validatedOtp":
        handleValidatedOtp(data);
        break;

      default:
        console.warn("Unexpected dataName:", dataName);
    }

    snackBarHandle(true, data?.message, "success");
  };

  const handleNewUserSession = (data) => {
    console.log({ data });

    if (data?.data?.userId && data?.data?.hasActiveSession === false) {
      toggleOtpPage();
    } else {
      handleOpenActiveUserSessionDialog(data?.data?.overrideToken);
    }

    setState((prevState) => ({
      ...prevState,
      userId: data?.data?.userId,
      msisdn: data?.data?.msisdn,
    }));
  };

  const handleValidatedOtp = (data) => {
    if (data?.data?.access_token) {
      handleSignedInUser(data.data);
    }
  };

  const handleApiData = (params) => {
    const { data, dataName, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    } else if (severity === "success") {
      handleSuccess(data, dataName);
    } else {
      console.warn("Unhandled severity:", severity);
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };
  
  const handleSignIn = async (event) => {
    event?.preventDefault();
    await makeApiRequest({
      uri: `/user-management/login/request/otp`,
      dataName: `requestedOtp`,
      method: `post`,
      data: {
        client_id: client_id,
        username,
        password,
      },
    });
  };
  

  const handleOpenActiveUserSessionDialog = (overrideToken) => {
    setOverrideToken(overrideToken);
    setactiveUserSessionDialogOpen(true);
  };

  const handleOverrideUserSession = async (overrideToken) => {
    await makeApiRequest({
      uri: `/user-management/login/overwrite`,
      dataName: `newUserSession`,
      method: `post`,
      data: {
        token: overrideToken,
      },
    });
  };

  const handleConfirmOverrideUserSession = async () => {
    if (overrideToken) {
      await handleOverrideUserSession(overrideToken);
      handleApiData({ dataName: "newUserSession" });
    }
    setactiveUserSessionDialogOpen(false);
  };

  const handleCancelOverrideUserSession = () => {
    setactiveUserSessionDialogOpen(false);
  };

  const toggleOtpPage = () => {
    setState((prevState) => ({
      ...prevState,
      isOtpPage: !prevState.isOtpPage,
    }));
  };

  const toggleRegistrationPage = () => {
    setState((prevState) => ({
      ...prevState,
      isRegistrationPage: !prevState.isRegistrationPage,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box
        style={{
          background: `url(/project-images/login-background-2.png) no-repeat center center fixed`,
          backgroundSize: "cover",
          backgroundColor: "#fff",
          filter: "blur(35px)",
          width: "100%",
          height: "100%",
          position: "absolute",
          overflow: "hidden",
          zIndex: -2,
        }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          mt: 10,
        }}
      >
        <Card
          sx={{
            width: "60%",
            background: "#f5f5f5",
            textAlign: "center",
            borderRadius: "20px",
            pointerEvents: "none",
            cursor: "default",
          }}
          disabled
        >
          <CardActionArea sx={{ pointerEvents: "none" }}>
            <Grid container spacing={2}>
              <Grid
                item
                // xs={12}
                md={4}
                lg={4}
                sx={{ display: { xs: "none", md: "block" }, p: 0 }}
              >
                <CardMedia
                  component="img"
                  height="100%"
                  width="100%"
                  image="/project-images/card-rectangle-2.png"
                  alt="login"
                  style={{ objectFit: "cover" }}
                />
              </Grid>

              <Grid item md={8} lg={8} sx={{ m: "0 auto" }}>
                <CardContent
                  style={{
                    paddingRight: 40,
                    paddingLeft: 40,
                    paddingBottom: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    pointerEvents: "auto",
                  }}
                >
                  {!isOtpPage && !isRegistrationPage && (
                    <form noValidate onSubmit={handleSignIn}>
                      <Box sx={{ width: 300 }}>
                        <img
                          src="/project-images/saf-logo-green.png"
                          className="Branding"
                          alt="branding-element"
                          style={logoStyle}
                        />
                        <Typography
                          sx={{ fontSize: 20, fontWeight: "bold", mt: -3 }}
                        >
                          Brand Content Hub
                        </Typography>
                        <Typography
                          sx={{
                            mb: 3,
                            mt: 1,
                            fontSize: 13,
                          }}
                        >
                          Use your credentials to log in to the portal
                        </Typography>

                        <Box sx={{ width: 300 }}>
                          <FormControl fullWidth>
                            <TextField
                              value={values.username}
                              label={"Email Address"}
                              name="username"
                              size="small"
                              onChange={handleFormChange}
                              onBlur={handleBlur}
                              fullWidth
                              required
                              error={
                                touched.username && Boolean(errors.username)
                              }
                              helperText={touched.username && errors.username}
                            />
                          </FormControl>
                          <FormControl fullWidth sx={{ mt: 3 }}>
                            <TextField
                              value={values.password}
                              label="Password"
                              placeholder="Password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              size="small"
                              onChange={handleFormChange}
                              onBlur={handleBlur}
                              fullWidth
                              required
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              helperText={touched.password && errors.password}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                          <Stack
                            direction="row"
                            spacing={12}
                            sx={{ mb: 5, mt: 3 }}
                          >
                            <Button
                              type="submit"
                              style={
                                !isValid
                                  ? loginDisabledButton
                                  : loginPrimaryButton
                              }
                              disableElevation
                              disabled={!isValid}
                            >
                              Sign In
                            </Button>
                          </Stack>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                          <span>
                            <Typography sx={{ fontSize: 14 }}>
                              Don't have an account?
                              <Link
                                data-testid="register-link"
                                sx={{
                                  color: "#2CB34A",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  fontSize: 14,
                                  ml: 1,
                                }}
                                onClick={() => toggleRegistrationPage()}
                              >
                                Register to Submit Briefs
                              </Link>
                            </Typography>
                          </span>
                        </Box>
                      </Box>
                    </form>
                  )}

                  {isOtpPage && !isRegistrationPage && (
                    <OtpValidation
                      handleSignedInUser={handleSignedInUser}
                      userId={userId}
                      msisdn={msisdn}
                      toggleOtpPage={toggleOtpPage}
                    />
                  )}
                  {isRegistrationPage && !isOtpPage && (
                    <SelfRegistration
                      toggleRegistrationPage={toggleRegistrationPage}
                    />
                  )}
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Box>

      <ActiveSessionDialog
        handleConfirmOverrideUserSession={handleConfirmOverrideUserSession}
        handleCancelOverrideUserSession={handleCancelOverrideUserSession}
        activeUserSessionDialogOpen={activeUserSessionDialogOpen}
      />

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
