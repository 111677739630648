import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Container,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  deleteButton,
  primaryButton,
  secondaryButton,
} from "../../../styles/common-styles";
import { ExpandMore } from "@mui/icons-material";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import ConfirmationDialog from "../../../shared-components/confirmation-dialog";
import PropTypes from "prop-types";
import SuccessIcon from "../../../assets/icons-js-files/success-icon";
import customLocalStorage from "../../../services/local-storage";

const sectionHeadingStyle = {
  fontWeight: "bold",
  color: "#2CB34A",
  fontSize: "1.2em",
};

CustomisedBriefReview.propTypes = {
  handleBack: PropTypes.func.isRequired,
  selectedBrief: PropTypes.object,
  brief: PropTypes.object,
};

CustomisedBriefReview.defaultProps = {
  selectedBrief: {},
  brief: {},
};

export default function CustomisedBriefReview({
  brief,
  selectedBrief,
  handleBack,
}) {
  const initialState = {
    loading: false,
    snackBarSeverity: "info",
  };

  const briefId = brief?.briefId || selectedBrief?.briefId;

  const [state, setState] = useState(initialState);

  const [modalOpen, setModalOpen] = useState(false);
  const [briefIdToCancel, setBriefIdToCancel] = useState(null);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { loading, snackBarSeverity } = state;

  useEffect(() => {
    console.log("brief prop changed:", brief);
  }, [brief]);

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "submittedBrief") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));

        setModalOpen(true);
      }
      if (dataName === "cancelledBrief") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));
        window.location.reload();
      }

      snackBarHandle(true, data?.message, severity);
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitBriefForApproval = async () => {
    handleLoader(true);

    // Check if the status meets the condition for including approvalTask
    const includeApprovalTask =
      selectedBrief?.briefStatus ===
        "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD" ||
      selectedBrief?.briefStatus ===
        "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER" ||
      brief?.briefStatus === "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD" ||
      brief?.briefStatus === "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER";

    const payload = {
      briefId,
      comment: "Kindly review and approve",
      ...(includeApprovalTask && { approvalTask: "APPROVE" }),
    };

    await makeApiRequest({
      uri: `/market-research/briefs/submit-for-approval`,
      dataName: `submittedBrief`,
      method: `post`,
      data: payload,
    });

    handleLoader(false);
  };

  const handleOpenCancelBriefDialog = (selectedBrief) => {
    setBriefIdToCancel(selectedBrief?.briefId);
    setCancelConfirmationOpen(true);
  };

  const handleCancelBrief = async (briefId) => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/briefs/submit-for-approval`,
      dataName: `cancelledBrief`,
      method: `post`,
      data: {
        briefId,
        comment: "Brief cancelled by Brief Owner",
        approvalTask: "CANCEL",
      },
    });
    handleLoader(false);
  };

  const handleConfirmCancelBrief = async () => {
    console.log("Confirming cancellation for briefId:", briefIdToCancel);

    if (briefIdToCancel) {
      await handleCancelBrief(briefIdToCancel);
      // Refresh the component after cancellation
      await handleApiData({ dataName: "allBriefs" });
    }

    setCancelConfirmationOpen(false);
  };

  const handleCancelCancellation = () => {
    setCancelConfirmationOpen(false);
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const [breiefDetailFormInputPlaceholders, setBreiefDetailFormInputPlaceholders] = useState();
  useEffect(() => {
    const breiefDetailsFormData = customLocalStorage.decrypt("breiefDetailsFormData_stepper2");
    if (breiefDetailsFormData){
      setBreiefDetailFormInputPlaceholders(breiefDetailsFormData);
    }    
  }, [])
  

  return (
    <Box sx={{ p: 5 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Grid container spacing={2}>
        <Container sx={{ pt: 3 }}>
          <Typography textAlign="left" style={sectionHeadingStyle}>
            Brief Review and Summary
          </Typography>

          <Container>
            <Grid container sx={{ pt: 2, ml: -4 }}>
              <Grid item xs={6} md={6} lg={6}>
                <List>
                  {[
                    "Brief Title",
                    "Expected Delivery Date",
                    "Department",
                    "Approximate Budget",
                    "Budget Line",
                    "Date of Creation",
                  ].map((value) => {
                    const listItemId = `${value}`;
                    return (
                      <ListItem sx={{ p: 1 }} key="">
                        <Typography id={listItemId} sx={{ fontWeight: "bold" }}>
                          {`${value}`}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>

              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.briefTitle || brief?.briefTitle}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.expectedDeliveryDate ||
                        brief?.expectedDeliveryDate}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.coeDepartment?.departmentName ||
                        brief?.coeDepartment?.departmentName}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.approximateBudget ||
                        brief?.approximateBudget}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.budgetLine || brief?.budgetLine}
                    </Typography>
                  </ListItem>

                  <ListItem>
                    <Typography>
                      {selectedBrief?.createdAt
                        ? new Date(selectedBrief.createdAt).toLocaleString()
                        : brief?.createdAt
                        ? new Date(brief.createdAt).toLocaleString()
                        : ""}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Box sx={{ mt: 2, ml: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Target Segments</Typography>

        <Container>
          <List sx={{ ml: -5 }}>
            {(selectedBrief?.targetSegments || brief?.targetSegments)?.map(
              (segment) => (
                <ListItem key={segment?.id}>
                  <Typography>{segment?.targetSegmentName}</Typography>
                </ListItem>
              )
            )}
          </List>
        </Container>

        <Typography mt={2} sx={{ fontWeight: "bold" }}>
          Regions
        </Typography>

        <Container>
          <List sx={{ ml: -5 }}>
            {(selectedBrief?.regions || brief?.regions)?.map((region) => (
              <ListItem key={region?.id}>
                <Typography>{region?.regionName}</Typography>
              </ListItem>
            ))}
          </List>
        </Container>

        <Typography mt={2} sx={{ fontWeight: "bold" }}>
          Age Brackets
        </Typography>

        <Container>
          <List sx={{ ml: -5 }}>
            {(selectedBrief?.ageBrackets || brief?.ageBrackets)?.map(
              (bracket) => (
                <ListItem key={bracket?.id}>
                  <Typography>{bracket?.ageBracketName}</Typography>
                </ListItem>
              )
            )}
          </List>
        </Container>
      </Box>

      <Divider sx={{ mt: 2 }} />

      <Typography textAlign="left" style={sectionHeadingStyle} sx={{ mt: 2 }}>
        Brief Background and Objectives
      </Typography>

      <Box sx={{ mt: 2 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Study Background
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {/* {(
                selectedBrief?.studyBackground?.blocks ||
                brief?.studyBackground?.blocks ||
                []
              ).map((block) => (
                <ListItem key={block.key}>
                  <ListItemText primary={block.text} />
                </ListItem>
              ))} */}
              <ListItem>
                  <ListItemText primary={breiefDetailFormInputPlaceholders?.editorDataPlaceholder?.studyBackground} />
                </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Marketing Objectives
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(
                selectedBrief?.marketingObjectives?.blocks ||
                brief?.marketingObjectives?.blocks ||
                []
              ).map((block) => (
                <ListItem key={block.key}>
                  <ListItemText primary={block.text} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Research Objectives
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(
                selectedBrief?.researchObjectives?.blocks ||
                brief?.researchObjectives?.blocks ||
                []
              ).map((block) => (
                <ListItem key={block.key}>
                  <ListItemText primary={block.text} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Execution Plan
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(
                selectedBrief?.executionPlan?.blocks ||
                brief?.executionPlan?.blocks ||
                []
              ).map((block) => (
                <ListItem key={block.key}>
                  <ListItemText primary={block.text} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Stack
        direction="row"
        spacing={3}
        sx={{ mt: 2, justifyContent: "right" }}
      >
        <Button
          color="inherit"
          variant="contained"
          style={secondaryButton}
          disableElevation
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>

        {(selectedBrief?.briefStatus ===
          "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD" ||
          selectedBrief?.briefStatus ===
            "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER" ||
          brief?.briefStatus ===
            "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD" ||
          brief?.briefStatus ===
            "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER") && (
          <Button
            color="inherit"
            variant="contained"
            style={deleteButton}
            disableElevation
            onClick={handleOpenCancelBriefDialog}
            sx={{ mr: 1 }}
          >
            Cancel Brief
          </Button>
        )}
        <Button
          variant="contained"
          style={primaryButton}
          onClick={handleSubmitBriefForApproval}
          disableElevation
        >
          Submit Brief for Approval
        </Button>
      </Stack>

      <ConfirmationDialog
        open={cancelConfirmationOpen}
        handleClose={handleCancelCancellation}
        title="Confirm Cancellation"
        content="Are you sure you want to cancel brief?"
        cancelButtonText="Close"
        confirmButtonText="Confirm Cancellation"
        handleCancel={handleCancelCancellation}
        handleConfirm={handleConfirmCancelBrief}
      />

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{ textAlign: "center", borderRadius: "8px" }}
      >
        <Box sx={{ mt: 3 }}>
          <SuccessIcon stroke="#2CB34A" />
        </Box>
        <DialogTitle>
          <Typography
            sx={{ color: "#2CB34A", fontWeight: "bold", fontSize: 18 }}
          >
            Brief Submitted
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ width: "350px" }}>
            You have successfully submitted your brief for approval by the
            market research lead
          </Typography>
        </DialogContent>
        <DialogActions sx={{ m: "0 auto", pb: 4 }}>
          <Button style={primaryButton} onClick={handleCloseModal}>
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
