export const logoStyle = {
  width: "198px",
  height: "74px",
};

export const appBarStyle = {
  backgroundColor: "#fff",
  marginTop: "1%",
};

export const assetStyle = {
  transform: "scale(0.9)",
  width: "100%",
  marginTop: "5%",
};

export const formStyle = {
  paddingLeft: "10%",
  paddingRight: "10%",
  marginTop: "6%",
  marginBottom: "1%",
};

export const inputStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px #515151 solid",
      borderRadius: "8px",
    },
  },
  mb: 1,
};

export const autocompleteInputStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px #515151 solid",
        borderRadius: "8px",
      },
    },
  };


export const textStyle = {
  fontSize: "15px",
  marginTop: "5%",
};
