import React, { useState } from "react";
import { TextField, Autocomplete, Typography } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

const AutocompleteField = ({
  name,
  options,
  label,
  selectedDepartmentId,
  onDepartmentChange,
}) => {
  const [field, meta, helpers] = useField(name || "");

  const [selectedValue, setSelectedValue] = useState(
    field.value ? options.find((opt) => opt.id === field.value.id) : null
  );

  const showError = meta.touched && Boolean(meta.error);

  const handleDepartmentChange = (value) => {
    const departmentId = value ? value.id : null;
    setSelectedValue(value);
    helpers.setValue(departmentId);
    onDepartmentChange && onDepartmentChange(departmentId);
  };

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.departmentName || ""}
        value={options.find((opt) => opt.id === selectedDepartmentId) || null}
        onChange={(_, value) => handleDepartmentChange(value)}
        onBlur={() => helpers.setTouched(true)}
        onInputChange={(_, newInputValue) => {
          const newOption = options.find(
            (opt) => opt.departmentName === newInputValue
          );
          if (newOption) {
            handleDepartmentChange(newOption);
          }
        }}
        renderInput={(params) => {
          const errorMsg = meta.error ? meta.error : {};
          return (
            <TextField
              {...params}
              label={label}
              name={name}
              error={showError}
              helperText={showError ? errorMsg.value : ""}
            />
          );
        }}
      />
      {showError && (
        <Typography variant="caption" color="error">
          {meta.error.value}
        </Typography>
      )}
    </>
  );
};

AutocompleteField.propTypes = {
  onDepartmentChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  selectedDepartmentId: PropTypes.number
};

AutocompleteField.defaultProps = {
  name: "",
  label: "",
  options: [],
  selectedDepartmentId: 0,
};

export default AutocompleteField;
