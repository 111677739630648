import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import RegionsSetup from "./regions-setup";
import TargetSegmentsSetup from "./target-segments-setup";
import AgeBracketsSetup from "./age-brackets-setup";
import CommunicationTypesSetup from "./communication-types-setup";
import AgenciesSetup from "./agencies-setup";
import DepartmentsSetup from "./departments-setup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function MarketResearchSetups() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab sx={{ fontWeight: "bold" }} label="Regions" {...a11yProps(0)} />
        <Tab
          sx={{ fontWeight: "bold" }}
          label="Target Segments"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ fontWeight: "bold" }}
          label="Age Brackets"
          {...a11yProps(2)}
        />
        <Tab
          sx={{ fontWeight: "bold" }}
          label="Communication Types"
          {...a11yProps(3)}
        />
        <Tab sx={{ fontWeight: "bold" }} label="Agencies" {...a11yProps(4)} />
        <Tab
          sx={{ fontWeight: "bold" }}
          label="Departments"
          {...a11yProps(5)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Typography sx={{ fontWeight: "bold" }}>Regions</Typography>
        <RegionsSetup />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography sx={{ fontWeight: "bold" }}>Target Segments</Typography>
        <TargetSegmentsSetup />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Typography sx={{ fontWeight: "bold" }}>Age Brackets</Typography>
        <AgeBracketsSetup />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Typography sx={{ fontWeight: "bold" }}>Communication Types</Typography>
        <CommunicationTypesSetup />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <Typography sx={{ fontWeight: "bold" }}>Agencies</Typography>
        <AgenciesSetup />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <Typography sx={{ fontWeight: "bold" }}>Departments</Typography>
        <DepartmentsSetup />
      </TabPanel>
    </Box>
  );
}
