import { alpha, createTheme, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const drawerWidth = 270;

export const appBarStyle = {
  backgroundColor: "#2CB34A",
  top: 0,
  bottom: "auto",
};

export const logoStyle = {
  width: "130px",
  height: "45px",
};

const primaryMainColor =
  process.env.REACT_APP_PALETTE_PRIMARY_MAIN || "#2CB34A";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryMainColor,
    },
  },
  typography: {
    fontFamily: "Futura",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `@font-face {
          font-family: 'Futura';
          font-display: swap;
          font-weight: 600;
          src: local('Futura'), local('Futura'), url('./fonts/Futura.ttf') format('truetype') font-weight-normal;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        } `,
    },
  },
});

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export const CustomTitle = ({ children }) => (
  <div style={{ fontFamily: 'Futura' }}>
    {children}
  </div>
);


export const getMuiDatatableTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "6px",
            fontFamily: "Futura",
            paddingLeft: "20px",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#FAF8F8",
              fontFamily: "Futura",
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontFamily: "Futura",
            fontSize: "14px",
            fontWeight: "bold",
            padding: "20px",
          },
        },
      },
    },
  });
