import apiRequest from "./api-request";

const verifyRegistration = async (params) => {
  const { email_verification_token, toggleVerifyEmail } = params;

  const handleApiData = (params) => {
    const { severity } = params;
    if (severity === "success") {
      toggleVerifyEmail({ isEmailVerified: true, isEmailVerifiedPage: true });
    } else {
      toggleVerifyEmail({
        isEmailVerified: false,
        isEmailVerifiedPage: true,
        email_verification_token,
      });
    }
  };

  const makeApiRequest = async (params) => {
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
  };

  await makeApiRequest({
    uri: `/user-management/login/email-verification?email_verification_token=${email_verification_token}`,
    dataName: `verifiedAccount`,
    method: `get`,
    data: "",
  });
};

export default verifyRegistration;
