import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { inputStyle, helperTextStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  setupsTableHeaderStyle,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = yup.object({
  regionName: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY, "Maximum 50 characters")
    .required("Region name cannot be empty"),
  regionShortCode: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_TEN, "Maximum 10 characters")
    .required("Region short code cannot be empty"),
});

export default function RegionsSetup() {
  const initialState = {
    regions: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [isUpdatingRegion, setIsUpdatingRegion] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { regions, loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      regionName: "",
      regionShortCode: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleAddRegion(values);
      resetForm();
    },
  });

  const removeDuplicateRegionFromArrayOfObjects = (objArray) => {
    if (objArray.length < 1) return [];

    return [
      ...new Map(objArray.map((item) => [item.regionName, item])).values(),
    ];
  };

  const handleAddRegion = (values) => {
    let updatedRegions = [...regions];
    if (isUpdatingRegion) {
      const indexOfRegion = updatedRegions.findIndex(
        (region) => region.id === values.id
      );
      updatedRegions[indexOfRegion] = values;
      setIsUpdatingRegion(false);
    } else {
      updatedRegions.push(values);
    }

    updatedRegions = removeDuplicateRegionFromArrayOfObjects(updatedRegions);

    setState((prevState) => ({
      ...prevState,
      regions: updatedRegions,
    }));

    handleSubmitRegion(values);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitRegion = async (region) => {
    const action = region.id ? "update" : "create";
    const uri = `/market-research/setup/regions`;
    const method = action === "update" ? "put" : "post";
    await makeApiRequest({
      uri,
      dataName: "addedregion",
      method,
      data: region,
    });
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "success") {
      if (dataName === "regions") {
        console.log({ data });

        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }

      if (dataName === "addedregion") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const handleSelectRegion = (region) => {
    setIsUpdatingRegion(true);
    formik.setValues(region);
  };

  const handleDeleteRegion = async (id) => {
    await makeApiRequest({
      uri: `/market-research/setup/regions/${id}`,
      dataName: `deletedRegion`,
      method: `delete`,
      data: {},
    });

    const updatedRegions = regions.filter((region) => region.id !== id);

    setState((prevState) => ({
      ...prevState,
      regions: updatedRegions,
    }));
  };

  const handleGetAllRegions = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/setup/regions`,
      dataName: `regions`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllRegions();
    })();
  }, []);

  console.log({ regions });

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop
        sx={{ zIndex: 1, color: "#fff" }}
        open={loading}
        data-testid="backdrop"
      >
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Add/Edit Regions
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              inputProps={{ "data-testid": "region-name-input" }}
              label="Region Name"
              name="regionName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.regionName}
              fullWidth
              required
              error={
                formik.touched.regionName && Boolean(formik.errors.regionName)
              }
            />
            {formik.touched.regionName && formik.errors.regionName && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.regionName}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              inputProps={{ "data-testid": "region-shortcode-input" }}
              label="Region Short Code"
              name="regionShortCode"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.regionShortCode}
              fullWidth
              required
              error={
                formik.touched.regionShortCode &&
                Boolean(formik.errors.regionShortCode)
              }
            />
            {formik.touched.regionShortCode &&
              formik.errors.regionShortCode && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.regionShortCode}
                </FormHelperText>
              )}
          </FormControl>

          <Box>
            <Button
              data-testid="submit-region-button"
              type="submit"
              style={
                formik.isValid && formik.dirty ? primaryButton : disabledButton
              }
              disabled={!(formik.isValid && formik.dirty)}
            >
              {isUpdatingRegion ? "UPDATE" : "ADD"}
            </Button>
          </Box>
        </Stack>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 6 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Regions
        </Typography>

        <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={setupsTableHeaderStyle}>
                  Region Name
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Region Short Code
                </TableCell>

                <TableCell style={setupsTableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {regions?.map((region) => (
                <TableRow
                key={region}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {region?.regionName}
                  </TableCell>

                  <TableCell>{region?.regionShortCode}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleSelectRegion(region);
                      }}
                    >
                      <EditIcon data-testid="edit-icon" stroke="#121212" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteRegion(region?.id);
                      }}
                    >
                      <DeleteIcon data-testid="delete-icon" stroke="#ff2a58" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
