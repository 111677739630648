import React, { useState } from "react";
import { TextField, Autocomplete, Typography } from "@mui/material";
import { useField } from "formik";
import PropTypes from "prop-types";

const SelectUserToAssignTaskAutocompleteField = ({
  name,
  options,
  label,
  selectedUserId,
  onUserChange,
}) => {
  console.log("name:", name);

  const [field, meta, helpers] = useField(name || "");

  const [selectedValue, setSelectedValue] = useState(
    field.value
      ? options.find((opt) => opt.userId === field.value.userId)
      : null
  );

  const showError = meta.touched && Boolean(meta.error);

  const handleUserChange = (value) => {
    const userId = value ? value.userId : null;
    setSelectedValue(value);
    helpers.setValue(userId);
    onUserChange && onUserChange(userId);
  };

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) =>
          `${option.firstName} ${option.lastName} - ${option.email}`
        }
        value={options.find((opt) => opt.userId === selectedUserId) || null}
        onChange={(_, value) => handleUserChange(value)}
        onBlur={() => helpers.setTouched(true)}
        onInputChange={(_, newInputValue) => {
          const newOption = options.find((opt) => opt.email === newInputValue);
          if (newOption) {
            handleUserChange(newOption);
          }
        }}
        renderInput={(params) => {
          const errorMsg = meta.error ? meta.error : {};
          return (
            <TextField
              {...params}
              label={label}
              name={name}
              error={showError}
              helperText={showError ? errorMsg.value : ""}
            />
          );
        }}
      />
      {showError && (
        <Typography variant="caption" color="error">
          {meta.error.value}
        </Typography>
      )}
    </>
  );
};

SelectUserToAssignTaskAutocompleteField.propTypes = {
  onUserChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  selectedUserId: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
};

SelectUserToAssignTaskAutocompleteField.defaultProps = {
  label: "",
  name: "",
  selectedUserId: "",
  options: [],
};

export default SelectUserToAssignTaskAutocompleteField;
