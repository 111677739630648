import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import BrandCoeTeamsSetup from "./brand-coe-teams-setup";
import RolesSetup from "./roles-setup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function GeneralSetups() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab
          sx={{ fontWeight: "bold" }}
          label="Brand COE Teams"
          {...a11yProps(0)}
          data-testid="brand-teams-tab"
        />
        <Tab
          sx={{ fontWeight: "bold" }}
          label="Roles"
          {...a11yProps(1)}
          data-testid="roles-tab"
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Typography sx={{ fontWeight: "bold" }}>Brand COE Teams</Typography>
        <BrandCoeTeamsSetup />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography sx={{ fontWeight: "bold" }}>Roles</Typography>
        <RolesSetup />
      </TabPanel>
    </Box>
  );
}
