import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import SelectMarketResearchBriefType from "./select-brief-type";
import CustomisedBriefOverview from "./customised-brief/brief-overview-form-page";
import CustomisedBriefDetails from "./customised-brief/brief-details-form-page";
import CustomisedBriefReview from "./customised-brief/brief-review-form-page";
import PropTypes from "prop-types";
import ArrowBackIcon from "../../assets/icons-js-files/arrow-back-icon";
import SnackbarComponent from "../../shared-components/snackbar";
import apiRequest from "../../services/api-request";

MarketResearchBriefHomePage.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  handleGetAllBriefs: PropTypes.func.isRequired,
  updateSelectedBrief: PropTypes.func.isRequired,
  selectedBrief: PropTypes.object,
  targetSegments: PropTypes.array,
  regions: PropTypes.array,
  ageBrackets: PropTypes.array,
  departments: PropTypes.array,
  brandCoeId: PropTypes.string,
};

MarketResearchBriefHomePage.defaultProps = {
  selectedBrief: {},
  targetSegments: [],
  regions: [],
  ageBrackets: [],
  departments: [],
  brandCoeId: "",
};

export default function MarketResearchBriefHomePage(props) {
  const {
    handleGoBack,
    handleGetAllBriefs,
    brandCoeId,
    selectedBrief,
    targetSegments,
    regions,
    ageBrackets,
    departments,
    msBriefTypes,
  } = props;

  const initialState = {
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [activeStep, setActiveStep] = useState(0);

  const [briefId, setBriefId] = useState(selectedBrief?.briefId ?? "");

  const [msBriefType, setMsBriefType] = useState(
    selectedBrief?.briefType ?? {}
  );

  const [brief, setBrief] = useState(selectedBrief ?? {});
  const [fullBrief, setFullBrief] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { loading, snackBarSeverity } = state;

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "fullBrief") {
        setFullBrief(data?.data);
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetFullBrief = async () => {
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/${briefId}`,
      dataName: `fullBrief`,
      method: `get`,
      data: "",
    });
  };

  useEffect(() => {
    (async () => {
      if (selectedBrief) {
        await handleGetFullBrief();
      } else {
        setBrief({});
      }
    })();
  }, []);

  // Handle step change
  const handleStepChange = (newStep) => {
    setActiveStep(newStep);
  };

  // Handle next step
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  // Handle back step
  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(0, prevStep - 1));
    handleGetAllBriefs();
  };

  const getBrief = async (briefData) => {
    setBrief(briefData);
    setBriefId(briefData?.briefId);
    setMsBriefType(briefData?.briefType);
  };

  const getSelectedBrief = async (briefData) => {
    setBrief(briefData);
    setBriefId(briefData?.briefId);
    setMsBriefType(briefData?.briefType);
  };

  const getFinalUpdatedBrief = async (briefData) => {
    setBrief(briefData);
  };

  const getStepsAndComponents = () => {
    let steps = ["Select a brief type"];
    let components = [SelectMarketResearchBriefType];

    if (selectedBrief) {
      const { briefType } = selectedBrief;

      if (briefType === "Customised Brief") {
        steps = [
          ...steps,
          "Brief Overview",
          "Brief Details",
          "Review & Submit Brief",
        ];
        components = [
          ...components,
          CustomisedBriefOverview,
          CustomisedBriefDetails,
          CustomisedBriefReview,
        ];
      } else if (briefType === "Communication Testing Brief") {
        steps = [
          ...steps,
          "Brief Overview",
          "Brief Details",
          "Additional Information",
        ];
        // Update components for Communication Testing Brief if needed
      }
    } else if (msBriefType) {
      if (msBriefType === "Customised Brief") {
        steps = [
          ...steps,
          "Brief Overview",
          "Brief Details",
          "Review & Submit Brief",
        ];
        components = [
          ...components,
          CustomisedBriefOverview,
          CustomisedBriefDetails,
          CustomisedBriefReview,
        ];
      } else if (msBriefType === "Communication Testing Brief") {
        steps = [
          ...steps,
          "Brief Overview",
          "Brief Details",
          "Additional Information",
        ];
        // Update components for Communication Testing Brief if needed
      }
    }

    return { steps, components };
  };

  const { steps, components } = getStepsAndComponents();

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, pt: 3 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Grid container direction="row" alignItems="center">
          <IconButton
            data-testid="go-back-button"
            onClick={() => {
              handleGoBack();
            }}
          >
            <ArrowBackIcon
              stroke="#2CB34A"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
          </IconButton>
          <Typography
            textAlign="left"
            sx={{ fontWeight: "bold", color: "#2CB34A" }}
          >
            Back
          </Typography>
        </Grid>
        <Box sx={{ pl: 5, pt: 2 }}>
          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Create Market Research Brief
          </Typography>

          <Typography sx={{ pt: 2 }}>
            Please fill in all required details regarding your market research
            and customer insights study
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          background: "#fff",
          mt: 5,
          ml: 3,
          borderTopLeftRadius: 13,
          borderTopRightRadius: 13,
          borderBottomLeftRadius: 13,
          borderBottomRightRadius: 13,
        }}
      >
        <Box
          sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "30px",
            p: 2,
            borderTopLeftRadius: 13,
            borderTopRightRadius: 13,
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, _) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <SelectMarketResearchBriefType
            msBriefTypes={msBriefTypes}
            brandCoeId={brandCoeId}
            selectedBrief={fullBrief}
            targetSegments={targetSegments}
            regions={regions}
            ageBrackets={ageBrackets}
            departments={departments}
            handleNext={handleNext}
            handleBack={handleBack}
            getBrief={getBrief}
            getSelectedBrief={getSelectedBrief}
            handleGetAllBriefs={handleGetAllBriefs}
            setMsBriefType={props.setMsBriefType}
          />
        )}

        {activeStep > 0 && components[activeStep] && (
          <Box sx={{ pb: 10 }}>
            {React.createElement(components[activeStep], {
              activeStep: activeStep - 1,
              handleStepChange: handleStepChange,
              handleNext: handleNext,
              handleBack: handleBack,
              getBrief: getBrief,
              getFinalUpdatedBrief: getFinalUpdatedBrief,
              briefId: briefId,
              brief: brief,
              selectedBrief: fullBrief,
              updateSelectedBrief: props.updateSelectedBrief,
              targetSegments: targetSegments,
              regions: regions,
              ageBrackets: ageBrackets,
              departments: departments,
            })}
          </Box>
        )}
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
