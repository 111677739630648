export const inputStyle = {
  marginBottom: "5%",
};

export const formTitleStyle = {
  color: "#2CB34A",
  fontWeight: "bold",
  marginBottom: "1%",
};

export const formSubtitleStyle = {
  fontWeight: "bold",
  fontSize: "18px",
  marginBottom: "2%",
};

export const helperTextStyle = {
  color: "#C43302",
};

export const statusSuccessStyle = {
  color: "#2CB34A",
};

export const statusFailStyle = {
  color: "red",
};
