import React, { useState } from "react";
import { Box, Tooltip, IconButton, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { FormikProvider, useFormik } from "formik";
import EmptyTaskPage from "../../shared-components/empty-task-page";
import { getMuiDatatableTheme } from "../../styles/material-ui-theme";
import MarketResearchLeadAllTasksDetailsPage from "./mr-lead-all-tasks-details";
import ExpectedDeliveryDateDisplay from "../datatable-components/expected-delivery-date-display";
import DepartmentDisplay from "../datatable-components/department-display";
import DateCreatedDisplay from "../datatable-components/date-created-display";
import ViewIcon from "../../assets/icons-js-files/view-icon";
import EditIcon from "../../assets/icons-js-files/edit-icon";
import BriefOwnerBriefStatusDisplay from "../datatable-components/brief-owner-brief-status-display";
import apiRequest from "../../services/api-request";
import SnackbarComponent from "../../shared-components/snackbar";
import StatusFilterDropdown from "../../shared-components/brief-owner/filters/status-filter-dropdown";

MarketResearchLeadAllPendingTasksPage.propTypes = {
  pendingTasks: PropTypes.array,
  roles: PropTypes.array,
  brandCoeId: PropTypes.string,
};

MarketResearchLeadAllPendingTasksPage.defaultProps = {
  pendingTasks: [],
  roles: [],
  brandCoeId: "",
};

export default function MarketResearchLeadAllPendingTasksPage(props) {
  const { pendingTasks, roles, brandCoeId, user } = props;

  const initialState = {
    currentPage: "pendingTaskListPage",
    selectedBrief: {},
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [briefStatuses, setAllBriefStatuses] = useState([]);
  const [selectedBriefStatuses, setSelectedBriefStatuses] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { currentPage, selectedBrief, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log({ values });
    },
  });

  const togglePage = (params) => {
    const { currentPage, selectedBrief, roles, navigatedFrom } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      selectedBrief,
      roles,
      navigatedFrom,
    }));
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "briefStatuses") {
        setAllBriefStatuses(data?.data || []);
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetBriefsByStatus = async (selectedValues) => {
    setSelectedBriefStatuses(selectedValues);
    const statuses = selectedValues.join(",");
    await makeApiRequest({
      uri: `/market-research/briefs?statuses=${statuses}`,
      method: "get",
      dataName: `allBriefs`,
      data: "",
    });
  };

  const handleBriefStatusChange = (selectedValues) => {
    setSelectedBriefStatuses(selectedValues);
    handleGetBriefsByStatus(selectedValues);
  };

  const briefColumns = [
    {
      name: "briefTitle",
      label: "Brief Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "briefType",
      label: "Brief Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "coeDepartment",
      label: "Department",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <DepartmentDisplay departmentName={value?.departmentName} />
        ),
      },
    },
    {
      name: "expectedDeliveryDate",
      label: "Expected Delivery Date",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <ExpectedDeliveryDateDisplay expectedDeliveryDate={value} />
        ),
      },
    },
    {
      name: "createdAt",
      label: "Date Created",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateCreatedDisplay dateCreated={value} />,
      },
    },
    {
      name: "briefStatus",
      label: "Status",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (status) => (
          <BriefOwnerBriefStatusDisplay status={status} />
        ),
      },
    },
    {
      name: "briefId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[5];
          const viewTasks = [
            "BRIEF_IN_DRAFT",
            "BRIEF_COMPLETE",
            "BRIEF_EXTERNAL_REVIEW",
            "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD",
            "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER",
          ].includes(status);
          const selectedBrief = pendingTasks?.filter(
            (brief) => brief?.briefId == value
          )[0];

          return (
            <>
              {viewTasks && (
                <Tooltip title="View Brief">
                  <IconButton
                    onClick={() =>
                      togglePage({
                        currentPage: "mrAllTaskDetailsPage",
                        selectedBrief,
                      })
                    }
                  >
                    <ViewIcon
                      stroke="#121212"
                      style={{ marginRight: "10px", width: "18px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!viewTasks && (
                <Tooltip title="Reassign Task">
                  <IconButton
                    onClick={() =>
                      togglePage({
                        currentPage: "mrAllTaskDetailsPage",
                        selectedBrief,
                      })
                    }
                  >
                    <EditIcon
                      stroke="#121212"
                      style={{ marginRight: "10px", width: "18px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    download: false,
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ mb: 4, mt: 2, ml: 3 }}>
      <Typography sx={{ mb: 3, fontWeight: "bold" }}>
        List of all Pending Tasks
      </Typography>
      {currentPage === "pendingTaskListPage" && pendingTasks?.length > 0 && (
        <ThemeProvider theme={getMuiDatatableTheme()}>
          <FormikProvider value={formik}>
            <MUIDataTable
              title={
                <StatusFilterDropdown
                  name="statusIds"
                  label="Brief Status"
                  options={briefStatuses.map((status) => ({
                    name: status,
                    id: status,
                  }))}
                  selectedFilterId={selectedBriefStatuses}
                  onFilterChange={handleBriefStatusChange}
                />
              }
              data={pendingTasks}
              columns={briefColumns}
              options={options}
            />
          </FormikProvider>
        </ThemeProvider>
      )}

      {currentPage === "pendingTaskListPage" && pendingTasks?.length < 1 && (
        <Box sx={{ ml: -3 }}>
          <EmptyTaskPage emptyPageDescription="There are no pending tasks" />
        </Box>
      )}

      {currentPage === "mrAllTaskDetailsPage" && (
        <MarketResearchLeadAllTasksDetailsPage
          selectedBrief={selectedBrief}
          togglePage={togglePage}
          roles={roles}
          brandCoeId={brandCoeId}
          user={user}
          navigatedFrom="pendingTaskListPage"
        />
      )}

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
