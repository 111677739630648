import React from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";

const DateUpdatedDisplay = ({ dateUpdated }) => (
  <Stack direction="row" spacing={3}>
    <Typography sx={{ fontSize: 15, fontFamily: "Futura" }}>
      {moment(dateUpdated).format("D/MM/yyyy")}
    </Typography>
  </Stack>
);

DateUpdatedDisplay.propTypes = {
  dateUpdated: PropTypes.string,
};

DateUpdatedDisplay.defaultProps = {
  dateUpdated: "",
};

export default DateUpdatedDisplay;
