import * as React from "react";
import { Button, Stack, Typography } from "@mui/material";
// import { ReactComponent as BriefIcon } from "../assets/icons/clipboard-plus.svg";
import PropTypes from "prop-types";
import CreateBriefIcon from "../assets/icons-js-files/clipboard-plus-icon";

const CreateBriefButton = ({ buttonText, onClick }) => {
  return (
    <Button
      data-testid="create-brief-button"
      style={{
        background: "#2CB34A",
        color: "#fff",
        width: "200px",
        fontWeight: "bold",
        borderRadius: "20px",
        "&:hover": {
          background: "#000",
        },
        textTransform: "none",
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <CreateBriefIcon
          stroke="#fff"
          style={{
            width: "16px",
            height: "16px",
          }}
        />
        {/* <BriefIcon
          stroke="#fff"
          style={{
            width: "16px",
            height: "16px",
          }}
        /> */}
        <Typography sx={{ fontWeight: "bold" }}>{buttonText}</Typography>
      </Stack>
    </Button>
  );
};

CreateBriefButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

CreateBriefButton.defaultProps = {
  buttonText: "",
};

export default CreateBriefButton;
