import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { inputStyle, helperTextStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  setupsTableHeaderStyle,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = yup.object({
  communicationTypeName: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY, "Maximum 50 characters")
    .required("Communication type name cannot be empty"),
  communicationTypeShortCode: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_TEN, "Maximum 10 characters")
    .required("Communication type short code cannot be empty"),
  communicationTypeDescription: yup
    .string()
    .max(
      process.env.REACT_APP_MAX_INPUT_LENGTH_FIVE_HUNDRED,
      "Maximum 500 characters"
    )
    .required("Communication type description is required"),
});

export default function CommunicationTypesSetup() {
  const initialState = {
    communicationTypes: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [isUpdatingCommunicationType, setIsUpdatingCommunicationType] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { communicationTypes, loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      communicationTypeName: "",
      communicationTypeShortCode: "",
      communicationTypeDescription: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleAddCommunicationType(values);
      resetForm();
    },
  });

  const removeDuplicateCommunicationTypesFromArrayOfObjects = (objArray) => {
    if (objArray.length < 1) return [];

    return [
      ...new Map(
        objArray.map((item) => [item.communicationTypeName, item])
      ).values(),
    ];
  };

  const handleAddCommunicationType = (values) => {
    let updatedCommunicationTypes = [...communicationTypes];
    if (isUpdatingCommunicationType) {
      const indexOfCommunicationType = updatedCommunicationTypes.findIndex(
        (communicationType) => communicationType.id === values.id
      );
      updatedCommunicationTypes[indexOfCommunicationType] = values;
      setIsUpdatingCommunicationType(false);
    } else {
      updatedCommunicationTypes.push(values);
    }

    updatedCommunicationTypes =
      removeDuplicateCommunicationTypesFromArrayOfObjects(
        updatedCommunicationTypes
      );

    setState((prevState) => ({
      ...prevState,
      communicationTypes: updatedCommunicationTypes,
    }));

    handleSubmitCommunicationType(values);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitCommunicationType = async (communicationType) => {
    const action = communicationType.id ? "update" : "create";
    const uri = `/market-research/setup/communication-type`;
    const method = action === "update" ? "put" : "post";
    await makeApiRequest({
      uri,
      dataName: "addedCommunicationType",
      method,
      data: communicationType,
    });
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "success") {
      if (dataName === "communicationTypes") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));
      }

      if (dataName === "addedCommunicationType") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const handleGetAllCommunicationTypes = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/setup/communication-type`,
      dataName: `communicationTypes`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllCommunicationTypes();
    })();
  }, []);

  const handleSelectCommunicationType = (communicationType) => {
    setIsUpdatingCommunicationType(true);
    formik.setValues(communicationType);
  };

  const handleDeleteCommunicationType = async (id) => {
    await makeApiRequest({
      uri: `/market-research/setup/communication-type/${id}`,
      dataName: `deletedCommunicationTypes`,
      method: "delete",
      data: {},
    });

    const updatedCommunicationTypes = communicationTypes.filter(
      (communicationType) => communicationType.id !== id
    );

    setState((prevState) => ({
      ...prevState,
      communicationTypes: updatedCommunicationTypes,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Add/Edit Communication Types
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Communication Type Name"
              name="communicationTypeName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.communicationTypeName}
              fullWidth
              required
              error={
                formik.touched.communicationTypeName &&
                Boolean(formik.errors.communicationTypeName)
              }
            />
            {formik.touched.communicationTypeName &&
              formik.errors.communicationTypeName && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.communicationTypeName}
                </FormHelperText>
              )}
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="Communication Type Short Code"
              name="communicationTypeShortCode"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.communicationTypeShortCode}
              fullWidth
              required
              error={
                formik.touched.communicationTypeShortCode &&
                Boolean(formik.errors.communicationTypeShortCode)
              }
            />
            {formik.touched.communicationTypeShortCode &&
              formik.errors.communicationTypeShortCode && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.communicationTypeShortCode}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Communication Type Description"
              name="communicationTypeDescription"
              type="text"
              size="small"
              multiline
              rows={3}
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.communicationTypeDescription}
              fullWidth
              required
              error={
                formik.touched.communicationTypeDescription &&
                Boolean(formik.errors.communicationTypeDescription)
              }
            />
            {formik.touched.communicationTypeDescription &&
              formik.errors.communicationTypeDescription && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.communicationTypeDescription}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Box>
          <Button
            data-testid="submit-communication-type-button"
            type="submit"
            style={
              formik.isValid && formik.dirty ? primaryButton : disabledButton
            }
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isUpdatingCommunicationType ? "UPDATE" : "ADD"}
          </Button>
        </Box>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 4 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Communication Types
        </Typography>

        <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={setupsTableHeaderStyle}>
                  Communication Type
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Communication Type Short Code
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Communication Type Description
                </TableCell>

                <TableCell style={setupsTableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {communicationTypes?.map((communicationType) => (
                <TableRow
                  key={communicationType}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {communicationType?.communicationTypeName}
                  </TableCell>

                  <TableCell>
                    {communicationType?.communicationTypeShortCode}
                  </TableCell>
                  <TableCell>
                    {communicationType?.communicationTypeDescription}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleSelectCommunicationType(communicationType);
                      }}
                    >
                      <EditIcon stroke="#121212" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteCommunicationType(communicationType?.id);
                      }}
                    >
                      <DeleteIcon stroke="#ff2a58" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
