import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  disabledButton,
  inputStyle,
  primaryButton,
  secondaryButton,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from "draft-js";
import RichTextEditor from "../../../shared-components/rich-text-editor";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import customLocalStorage from "../../../services/local-storage";

const validationSchema = yup.object({
  approximateBudget: yup
    .number()
    .typeError("Must be a number")
    .positive("Must be a positive number")
    .integer("Must be an integer")
    .required("Approximate budget is required"),

  budgetLine: yup
    .string()
    .max(50, "Must be at most 50 characters")
    .required("Budget line is required"),

  // studyBackground: yup
  //   .string()
  //   .max(1000, "Must be at most 1000 characters")
  //   .required("Required"),

  // marketingObjectives: yup
  //   .string()
  //   .max(1000, "Must be at most 1000 characters")
  //   .required("Required"),

  // researchObjectives: yup
  //   .string()
  //   .max(1000, "Must be at most 1000 characters")
  //   .required("Required"),

  // executionPlan: yup
  //   .string()
  //   .max(1000, "Must be at most 1000 characters")
  //   .required("Required"),
});

CustomisedBriefDetails.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  getFinalUpdatedBrief: PropTypes.func.isRequired,
  briefId: PropTypes.string,
  selectedBrief: PropTypes.object,
};

CustomisedBriefDetails.defaultProps = {
  selectedBrief: {},
  briefId: "",
};

export default function CustomisedBriefDetails(props) {
  const { handleBack, handleNext, briefId, selectedBrief } = props;

  const initialState = {
    studyBackground:
      selectedBrief?.studyBackground ?? EditorState.createEmpty(),
    marketingObjectives:
      selectedBrief?.marketingObjectives ?? EditorState.createEmpty(),
    researchObjectives:
      selectedBrief?.researchObjectives ?? EditorState.createEmpty(),
    executionPlan: selectedBrief?.executionPlan ?? EditorState.createEmpty(),
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [approximateBudget, setApproximateBudget] = useState(
    selectedBrief?.approximateBudget || 0
  );
  const [budgetLine, setBudgetLine] = useState(selectedBrief?.budgetLine || "");
  const [updatedBrief, setUpdatedBrief] = useState({});

  //placeholder for editor state changes
  const [editorDataPlaceholder,setEditorDataPlaceholder] = useState({     
    studyBackground:  "",
    marketingObjectives: "",
    researchObjectives: "",
    executionPlan: ""
  })

  const [studyBackgroundChanges, setStudyBackgroundChanges] = useState(
    EditorState.createWithContent(
      ContentState.createFromText("")
    )
  );

  const [marketingObjectivesChanges, setMarketingObjectivesChanges] = useState(
    EditorState.createWithContent(
      ContentState.createFromText("")
    )
  );

  const [researchObjectivesChanges, setResearchObjectivesChanges] = useState(
    EditorState.createWithContent(
      ContentState.createFromText("")
    )
  );

  const [executionPlanChanges, setExecutionPlanChanges] = useState(
    EditorState.createWithContent(ContentState.createFromText(""))
  );

  // ##################### 
  const [breiefDetailFormInputPlaceholders, setBreiefDetailFormInputPlaceholders] = useState();
  
  const saveFormDataToLocalStorage = () => {
    const breiefDetailsFormData = {
      approximateBudget: approximateBudget,
      budgetLine: budgetLine,  
      editorDataPlaceholder: editorDataPlaceholder, 
    }
    customLocalStorage.encrypt("breiefDetailsFormData_stepper2", JSON.stringify(breiefDetailsFormData));
  }

  const handleBackAndSaveFormData = () => {
    saveFormDataToLocalStorage();
    handleBack();
  }
  
  useEffect(() => {
    const breiefDetailsFormData = customLocalStorage.decrypt("breiefDetailsFormData_stepper2");
    if (breiefDetailsFormData){
      setBreiefDetailFormInputPlaceholders(breiefDetailsFormData);
    }    
  }, [])

  useEffect(() => {
    if (breiefDetailFormInputPlaceholders){
      setStudyBackgroundChanges(
        createEditorStateChanges(studyBackground, breiefDetailFormInputPlaceholders?.editorDataPlaceholder?.studyBackground)
      );

      setMarketingObjectivesChanges(
        createEditorStateChanges(marketingObjectives, breiefDetailFormInputPlaceholders?.editorDataPlaceholder?.marketingObjectives)
      );

      setResearchObjectivesChanges(
        createEditorStateChanges(researchObjectives, breiefDetailFormInputPlaceholders?.editorDataPlaceholder?.researchObjectives)
      );

      setExecutionPlanChanges(
        createEditorStateChanges(executionPlan, breiefDetailFormInputPlaceholders?.editorDataPlaceholder?.executionPlan)
      );

    }
  }, [breiefDetailFormInputPlaceholders])
  
  // ##################### 
  
  const {
    studyBackground,
    marketingObjectives,
    researchObjectives,
    executionPlan,
    loading,
    snackBarSeverity,
  } = state;

  const formik = useFormik({
    initialValues: {
      approximateBudget: selectedBrief?.approximateBudget || "",
      budgetLine: selectedBrief?.budgetLine || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdateBrief(values);
    },
  });

  const { values, errors, touched, isValid, handleChange, handleBlur } = formik;

  const handleStudyBackgroundChanges = (value) => {
    const contentState = value.getCurrentContent();
    const contentStateRaw = convertToRaw(contentState);
    
    setStudyBackgroundChanges(value);

    // ######## 
    const inputTextValue = contentStateRaw.blocks[0].text;
    setEditorDataPlaceholder((prev) => ({
      ...prev,
      studyBackground: inputTextValue
    }));
    // ##########
  };

  const handleMarketingObjectiveChanges = (value) => {
    const contentState = value.getCurrentContent();
    const contentStateRaw = convertToRaw(contentState);

    setState((prev) => ({
      ...prev,
      marketingObjectives: EditorState.createWithContent(
        convertFromRaw(contentStateRaw)
      ),
    }));

    setMarketingObjectivesChanges(value);

    // ######## 
    const inputTextValue = contentStateRaw.blocks[0].text;
    setEditorDataPlaceholder((prev) => ({
      ...prev,
      marketingObjectives: inputTextValue
    }))
    // ##########
  };

  const handleResearchObjectiveChanges = (value) => {
    const contentState = value.getCurrentContent();
    const contentStateRaw = convertToRaw(contentState);

    setState((prev) => ({
      ...prev,
      researchObjectives: EditorState.createWithContent(
        convertFromRaw(contentStateRaw)
      ),
    }));

    setResearchObjectivesChanges(value);
    
    // ######## 
    const inputTextValue = contentStateRaw.blocks[0].text;
    setEditorDataPlaceholder((prev) => ({
      ...prev,
      researchObjectives: inputTextValue
    }))
    // ##########
  };

  const handleExecutionPlanChanges = (value) => {
    const contentState = value.getCurrentContent();
    const contentStateRaw = convertToRaw(contentState);

    setState((prev) => ({
      ...prev,
      executionPlan: EditorState.createWithContent(
        convertFromRaw(contentStateRaw)
      ),
    }));

    setExecutionPlanChanges(value);

    // ######## 
    const inputTextValue = contentStateRaw.blocks[0].text;
    setEditorDataPlaceholder((prev) => ({
      ...prev,
      executionPlan: inputTextValue
    }))
    // ##########
  };

  
  const handleFormChange = (e) => {
    handleChange(e);

    if (e.target.name === "approximateBudget") {
      setApproximateBudget(e.target.value);
    } else if (e.target.name === "budgetLine") {
      setBudgetLine(e.target.value);
    }
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "updatedBrief") {
        console.log(data?.data);
        setUpdatedBrief(data?.data);
        await props.getFinalUpdatedBrief(data?.data);
        await handleNext();
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleUpdateBrief = async (values) => {
    saveFormDataToLocalStorage(); // save for data

    handleLoader(true);

    setApproximateBudget(values.approximateBudget);
    setBudgetLine(values.budgetLine);

    await makeApiRequest({
      uri: `/market-research/briefs/details`,
      dataName: `updatedBrief`,
      method: `put`,
      data: {
        briefId,
        approximateBudget,
        budgetLine,
        studyBackground: convertToRaw(
          studyBackground.getCurrentContent()
        ),
        marketingObjectives: convertToRaw(
          marketingObjectives.getCurrentContent()
        ),
        researchObjectives: convertToRaw(
          researchObjectives.getCurrentContent()
        ),
        executionPlan: convertToRaw(executionPlan.getCurrentContent()),
      },
    });
    handleLoader(false);
  };

  const createEditorState = (field, defaultValue) => {
    // Check if the field exists and has the required structure for convertFromRaw
    if (field && field.blocks && field.entityMap) {
      try {
        return EditorState.createWithContent(convertFromRaw(field));
      } catch (error) {
        console.error("Failed to convert content from raw: ", error);
        return EditorState.createWithContent(
          ContentState.createFromText(defaultValue)
        );
      }
    }

    // Fallback to default value if field is invalid or empty
    return EditorState.createWithContent(
      ContentState.createFromText(defaultValue)
    );
  };

  const createEditorStateChanges = (field, defaultValue) => {
    return () => createEditorState(field, defaultValue);
  };

  useEffect(() => {
    let isMounted = true;

    // Check if selectedBrief is truthy
    if (selectedBrief && isMounted) {
      const {
        approximateBudget,
        budgetLine,
        studyBackground,
        marketingObjectives,
        researchObjectives,
        executionPlan,
      } = selectedBrief;

      // Update local state with values from selectedBrief
      setState((prevState) => ({
        ...prevState,
        approximateBudget,
        budgetLine,
        studyBackground: createEditorState(studyBackground, "Study Background"),
        marketingObjectives: createEditorState(
          marketingObjectives,
          "Marketing Objectives"
        ),
        researchObjectives: createEditorState(
          researchObjectives,
          "Research Objectives"
        ),
        executionPlan: createEditorState(executionPlan, "Execution Plan"),
      }));
      
      // else{
      //   setStudyBackgroundChanges(
      //     createEditorStateChanges(studyBackground, "Study Background")
      //   );
      //   setMarketingObjectivesChanges(
      //     createEditorStateChanges(marketingObjectives, "Marketing Objectives")
      //   );
      //   setResearchObjectivesChanges(
      //     createEditorStateChanges(researchObjectives, "Research Objectives")
      //   );
      //   setExecutionPlanChanges(
      //     createEditorStateChanges(executionPlan, "Execution Plan")
      //   );
      // }

    }

    return () => {
      isMounted = false;
    };
  }, [selectedBrief]);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };
  

  return (
    <Box sx={{ pl: 20, pr: 20, pt: 5 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }}>
        <TextField
          label="Approximate Budget"
          name="approximateBudget"
          type="text"
          size="small"
          value={breiefDetailFormInputPlaceholders ? breiefDetailFormInputPlaceholders?.approximateBudget :values.approximateBudget}
          onChange={handleFormChange}
          onBlur={handleBlur}
          error={touched.approximateBudget && Boolean(errors.approximateBudget)}
          helperText={touched.approximateBudget && errors.approximateBudget}
          style={inputStyle}
          fullWidth
        />

        <TextField
          label="Budget Line"
          name="budgetLine"
          type="text"
          size="small"
          value={breiefDetailFormInputPlaceholders ? breiefDetailFormInputPlaceholders?.budgetLine :values.budgetLine}
          onChange={handleFormChange}
          onBlur={handleBlur}
          error={touched.budgetLine && Boolean(errors.budgetLine)}
          helperText={touched.budgetLine && errors.budgetLine}
          style={inputStyle}
          fullWidth
        />

        <Box>
          <RichTextEditor
            title="Study Background"
            editorState={studyBackgroundChanges}            
            onEditorStateChange={handleStudyBackgroundChanges}
          />
        </Box>

        <Box>
          <RichTextEditor
            title="Marketing Objectives"
            editorState={marketingObjectivesChanges}
            onEditorStateChange={handleMarketingObjectiveChanges}
          />
        </Box>

        <Box>
          <RichTextEditor
            title="Research Objectives"
            editorState={researchObjectivesChanges}
            onEditorStateChange={handleResearchObjectiveChanges}
          />
        </Box>

        <Box>
          <RichTextEditor
            title="Execution Plan"
            editorState={executionPlanChanges}
            onEditorStateChange={handleExecutionPlanChanges}
          />
        </Box>
      </Stack>

      <Stack
        direction="row"
        spacing={3}
        sx={{ mt: 2, justifyContent: "right" }}
      >
        <Button
          color="inherit"
          variant="contained"
          style={secondaryButton}
          disableElevation
          onClick={handleBackAndSaveFormData}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          style={!isValid ? disabledButton : primaryButton}
          onClick={handleUpdateBrief}
          disableElevation
          disabled={!isValid}
        >
          Save & Continue
        </Button>
      </Stack>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
