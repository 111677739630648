import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { inputStyle } from "../../styles/form-styles";
import {
  disabledButton,
  primaryButton,
  secondaryButton,
} from "../../styles/common-styles";
import apiRequest from "../../services/api-request";
import { useFormik } from "formik";
import * as yup from "yup";
import SnackbarComponent from "../../shared-components/snackbar";
import PropTypes from "prop-types";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name must be at most 50 characters")
    .matches(
      /^[a-zA-Z\s'-]+$/,
      "First name can only contain letters, spaces, -, and '"
    ),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name must be at most 50 characters")
    .matches(
      /^[a-zA-Z\s'-]+$/,
      "Last name can only contain letters, spaces, -, and '"
    ),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  msisdn: yup.number().required("Phone Number is required"),
});

AddMarketResearchUser.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  marketResearchRoles: PropTypes.array,
  brandCoeId: PropTypes.string,
  action: PropTypes.string,
  currentUser: PropTypes.object,
};

AddMarketResearchUser.defaultProps = {
  marketResearchRoles: [],
  action: "",
  brandCoeId: "",
  currentUser: {},
};

export default function AddMarketResearchUser(props) {
  const {
    handleCloseDialog,
    marketResearchRoles,
    brandCoeId,
    action,
    currentUser,
  } = props;

  const initialState = {
    staff: false,
    twoFa: false,
    roleId: null,
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [firstName, setFirstName] = useState(currentUser?.firstName || "");
  const [lastName, setLastName] = useState(currentUser?.lastName || "");
  const [email, setEmail] = useState(currentUser?.email || "");
  const [msisdn, setMsisdn] = useState(currentUser?.msisdn || "");
  const [selectedRoles, setSelectedRoles] = useState(
    props?.currentUser.role || []
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [active, setActive] = useState(currentUser?.active || false);

  const { departments, loading, snackBarSeverity } = state;

  const handleToggleChange = (event) => {
    setActive(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      email,
      msisdn,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmitUserData();
    },
  });

  // Destructure formik props
  const { handleChange, values, errors, touched, isValid } = formik;

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    handleChange(e);

    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "msisdn") {
      setMsisdn(value);
    }
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, data, dataName } = params;
    if (severity === "success") {
      if (dataName === "user") {
        handleCloseDialog();
        window.location.reload();
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitUserData = async () => {
    const action = props?.currentUser.userId ? "update" : "create";
    let uri = "/users";
    let method = "post";
    let userData = {
      firstName,
      lastName,
      email,
      msisdn,
      staff: true,
      twoFa: true,
      departments,
      roles: selectedRoles,
      brandCoeId,
    };

    if (action === "update") {
      uri = `/users`;
      method = "put";
      userData = {
        ...userData,
        userId: props.currentUser.userId,
        active,
      };
    }

    await makeApiRequest({
      uri,
      dataName: "createdUser",
      method,
      data: userData,
    });
  };

  useEffect(() => {
    if (action === "Update" && props.currentUser) {
      const { currentUser } = props;
      setSelectedRoles(props.currentUser?.role || []);
      setState((prevState) => ({
        ...prevState,
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        msisdn: msisdn || "",
        brandCoeId: brandCoeId,
        selectedBrandTeam: currentUser?.brandTeam || null,
        // selectedRoles: currentUser.role || [],
      }));
    }
  }, [action, props.currentUser]);

  const handleRoleSelection = (role) => {
    setSelectedRoles((prevRoles) => [...prevRoles, role]);
    console.log({ selectedRoles });
  };

  const handleRoleDeselection = (roleId) => {
    setSelectedRoles((prevRoles) =>
      prevRoles.filter((role) => role.id !== roleId)
    );
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <Dialog
        open={!(action === "Close")}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <form onSubmit={handleSubmitUserData}>
          <DialogContent>
            <Typography sx={{ fontWeight: "bold", mb: 4 }}>
              {action} User
            </Typography>

            <Stack direction="row" spacing={5}>
              <TextField
                inputProps={{ "data-testid": "first-name-input" }}
                label="First Name"
                name="firstName"
                type="text"
                size="small"
                style={inputStyle}
                onChange={handleFormChange}
                value={values.firstName}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName ? errors.firstName : ""}
                fullWidth
                required
              />
              <TextField
                inputProps={{ "data-testid": "last-name-input" }}
                label="Last Name"
                name="lastName"
                type="text"
                size="small"
                onChange={handleFormChange}
                value={values.lastName}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName ? errors.lastName : ""}
                fullWidth
                required
              />
            </Stack>
            <Stack sx={{ mt: 2 }}>
              <TextField
                inputProps={{ "data-testid": "email-input" }}
                label="Email"
                name="email"
                type="email"
                size="small"
                onChange={handleFormChange}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email ? errors.email : ""}
                fullWidth
                required
                disabled={action == "Update"}
              />
            </Stack>
            <Stack sx={{ mt: 3 }}>
              <TextField
                inputProps={{ "data-testid": "msisdn-input" }}
                label="Phone Number"
                name="msisdn"
                type="text"
                size="small"
                onChange={handleFormChange}
                value={values.msisdn}
                error={touched.msisdn && Boolean(errors.msisdn)}
                helperText={touched.msisdn ? errors.msisdn : ""}
                fullWidth
                required
                disabled={action == "Update"}
              />
            </Stack>

            {action === "Update" && (
              <>
                <Typography sx={{ fontWeight: "bold", mb: 1, mt: 3 }}>
                  Account Active:
                </Typography>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch checked={active} onChange={handleToggleChange} />
                    }
                    label=""
                  />
                </FormGroup>
              </>
            )}

            <Box>
              {marketResearchRoles && (
                <FormControl fullWidth sx={{ mt: 4 }}>
                  <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                    Select Roles:
                  </Typography>
                  {(marketResearchRoles || []).map((role) => {
                    const roleDisplayName = role.displayName || "";
                    const roleId = role.id;
                    const isChecked = selectedRoles?.some(
                      (selectedRole) => selectedRole.id === roleId
                    );

                    return (
                      <FormControlLabel
                        key={roleId}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              if (isChecked) {
                                handleRoleSelection(role);
                              } else {
                                handleRoleDeselection(role.id);
                              }
                            }}
                          />
                        }
                        label={roleDisplayName}
                      />
                    );
                  })}
                </FormControl>
              )}
            </Box>
            <Stack direction="row" spacing={4} sx={{ mt: 2, mb: 3 }}>
              <Button
                onClick={() => {
                  handleCloseDialog();
                }}
                color="primary"
                variant="outlined"
                style={secondaryButton}
              >
                Cancel
              </Button>

              <Button
                data-testid="submit-button"
                variant="contained"
                style={isValid ? primaryButton : disabledButton}
                onClick={() => {
                  handleSubmitUserData();
                }}
                disableElevation
                disabled={!isValid}
              >
                {action}
              </Button>
            </Stack>
          </DialogContent>
        </form>
      </Dialog>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
