import React from "react";
import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const DepartmentDisplay = ({ departmentName }) => (
  <Stack direction="row" spacing={3}>
    <Typography sx={{ fontSize: 15, fontFamily: "Futura" }}>
      {departmentName}
    </Typography>
  </Stack>
);

DepartmentDisplay.propTypes = {
  departmentName: PropTypes.string,
};

DepartmentDisplay.defaultProps = {
  departmentName: "",
};

export default DepartmentDisplay;
