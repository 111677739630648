import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Autocomplete,
} from "@mui/material";
import { inputStyle, helperTextStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  setupsTableHeaderStyle,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = yup.object().shape({
  agencyName: yup
    .string()
    .required("Agency name cannot be empty")
    .matches(/^[a-zA-Z\s-,'"]+$/, "Invalid characters in agency name")
    .max(50, `Max length is ${process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY}`),
  agencyShortCode: yup
    .string()
    .required("Agency short code cannot be empty")
    .matches(/^[a-zA-Z]+$/, "Invalid characters in agency short code")
    .max(10, `Max length is ${process.env.REACT_APP_MAX_INPUT_LENGTH_TEN}`),
  agencyDescription: yup
    .string()
    .required("Agency Description is required")
    .matches(/^[a-zA-Z0-9\s-,'"]+$/, "Invalid characters in agency description")
    .max(
      500,
      `Max length is ${process.env.REACT_APP_MAX_INPUT_LENGTH_FIVE_HUNDRED}`
    ),
  agencyType: yup.object().shape({
    id: yup.number().required("Agency Type is required"),
  }),
});

export default function AgenciesSetup() {
  const initialState = {
    agencies: [],
    agencyTypes: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [isUpdatingAgency, setIsUpdatingAgency] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { agencies, agencyTypes, loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      agencyName: "",
      agencyShortCode: "",
      agencyDescription: "",
      agencyType: { id: "", value: "" },
    },
    validationSchema,
    onSubmit: (values) => {
      handleAddAgencies(values);
    },
  });

  const removeDuplicateAgenciesfromArrayOfObjects = (objArray) => {
    if (objArray?.length < 1) return [];
    let uniqueObjArray = [
      ...new Map(objArray.map((item) => [item["agencyName"], item])).values(),
    ];
    return uniqueObjArray;
  };

  const handleAddAgencies = (values) => {
    let updatedAgencies = [...agencies];

    if (updatedAgencies?.length >= 15) {
      alert("Can't add more than 15 Agencies");
      return;
    }
    if (isUpdatingAgency && selectedAgencyId) {
      const indexOfAgency = updatedAgencies.findIndex((agency) => {
        return agency?.id === selectedAgencyId;
      });
      updatedAgencies[indexOfAgency] = { ...values, id: selectedAgencyId };
      setIsUpdatingAgency(false);
      setSelectedAgencyId(null);
    } else {
      updatedAgencies.push({ ...values, agencyType: values.agencyType.value });
    }

    updatedAgencies =
      removeDuplicateAgenciesfromArrayOfObjects(updatedAgencies);

    setState((prevState) => ({
      ...prevState,
      agencies: updatedAgencies,
    }));

    handleSubmitAgencies(values);
    formik.resetForm();
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitAgencies = async (currentAgency) => {
    const action = currentAgency?.id ? "update" : "create";
    let uri = `/market-research/setup/agencies`;
    let method = `post`;
    if (action === "update") {
      uri = `/setup/agencies`;
      method = `put`;
    }
    await makeApiRequest({
      uri,
      dataName: `addedAgency`,
      method,
      data: {
        ...currentAgency,
        agencyType: currentAgency.agencyType.value,
      },
    });
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "success") {
      if (dataName === "agencies") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }

      if (dataName === "agencyTypes") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));
      }

      if (dataName === "addedAgency") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const handleGetAllAgencies = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/setup/agencies`,
      dataName: `agencies`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  const handleGetAgencyTypes = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/enum/agency-types`,
      dataName: `agencyTypes`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllAgencies();
      await handleGetAgencyTypes();
    })();
  }, []);

  const handleSelectAgency = (agency) => {
    setIsUpdatingAgency(true);
    setSelectedAgencyId(agency.id);
    formik.setValues({
      agencyName: agency.agencyName,
      agencyShortCode: agency.agencyShortCode,
      agencyDescription: agency.agencyDescription,
      agencyType: { id: agency.agencyTypeId, value: agency.agencyType },
    });
  };

  const handleDeleteAgency = async (agencyId) => {
    await makeApiRequest({
      uri: `/market-research/setup/agency/${agencyId}`,
      dataName: `deletedAgency`,
      method: `delete`,
      data: {},
    });

    setState((prevState) => ({
      ...prevState,
      agencies: prevState.agencies.filter((agency) => agency.id !== agencyId),
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Add/Edit Agencies
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.agencyType?.id && formik.errors.agencyType?.id
            )}
          >
            <Autocomplete
              id="agencyType"
              options={agencyTypes.map((agencyType) => ({
                id: agencyType.id,
                label: agencyType.value,
              }))}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) =>
                formik.setFieldValue(
                  "agencyType",
                  value ? { id: value.id, value: value.label } : {}
                )
              }
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agency Type"
                  variant="outlined"
                  size="small"
                  style={inputStyle}
                />
              )}
            />
            {formik.touched.agencyType?.id && formik.errors.agencyType?.id && (
              <FormHelperText style={helperTextStyle}>
                {formik.errors.agencyType.id}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="Agency Name"
              name="agencyName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.agencyName}
              fullWidth
              required
              error={Boolean(
                formik.touched.agencyName && formik.errors.agencyName
              )}
            />
            {formik.touched.agencyName && formik.errors.agencyName && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.agencyName}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Agency Short Code"
              name="agencyShortCode"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.agencyShortCode}
              fullWidth
              required
              error={Boolean(
                formik.touched.agencyShortCode && formik.errors.agencyShortCode
              )}
            />
            {formik.touched.agencyShortCode &&
              formik.errors.agencyShortCode && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.agencyShortCode}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Agency Description"
              name="agencyDescription"
              type="text"
              size="small"
              multiline
              rows={3}
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.agencyDescription}
              fullWidth
              required
              error={Boolean(
                formik.touched.agencyDescription &&
                  formik.errors.agencyDescription
              )}
            />
            {formik.touched.agencyDescription &&
              formik.errors.agencyDescription && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -3 }}>
                  {formik.errors.agencyDescription}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <Button
            data-testid="submit-agency-button"
            type="submit"
            style={
              formik.isValid && formik.dirty ? primaryButton : disabledButton
            }
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isUpdatingAgency ? "UPDATE" : "ADD"}
          </Button>
        </Box>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 4 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Agencies
        </Typography>

        <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={setupsTableHeaderStyle}>
                  Agency Type
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Agency Name
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Agency Short Code
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Agency Description
                </TableCell>

                <TableCell style={setupsTableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agencies?.map((agency) => (
                <TableRow
                  key={agency}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {agency?.agencyType}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {agency?.agencyName}
                  </TableCell>

                  <TableCell>{agency?.agencyShortCode}</TableCell>
                  <TableCell>{agency?.agencyDescription}</TableCell>

                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleSelectAgency(agency);
                      }}
                    >
                      <EditIcon stroke="#121212" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteAgency(agency?.id);
                      }}
                    >
                      <DeleteIcon stroke="#ff2a58" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
