import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  Button,
  Stack,
  Divider,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import apiRequest from "../../services/api-request";
import {
  disabledButton,
  primaryButton,
  secondaryButton,
} from "../../styles/common-styles";
import SnackbarComponent from "../../shared-components/snackbar";
import PropTypes from "prop-types";

SelectMarketResearchBriefType.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  getSelectedBrief: PropTypes.func.isRequired,
  brandCoeId: PropTypes.string,
  selectedBrief: PropTypes.object,
  msBriefTypes: PropTypes.array,
};

SelectMarketResearchBriefType.defaultProps = {
  selectedBrief: {},
  brandCoeId: "",
  msBriefTypes: [],
};

export default function SelectMarketResearchBriefType(props) {
  const {
    msBriefTypes,
    brandCoeId,
    selectedBrief,
    getSelectedBrief,
    handleNext,
    handleBack,
  } = props;

  const initialState = {
    msBriefType: {},
    loading: false,
    brief: {},
    briefId: 0,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [saveContinueDisabled, setSaveContinueDisabled] = useState(false);

  const { msBriefType, loading, snackBarSeverity } = state;

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "success") {
      if (dataName === "brief") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));

        const { briefId } = data?.data || "";

        if (briefId) {
          await props.getBrief(data?.data);
          await handleNext();
        }
      }
    }
    if (data?.message) {
      setState((prevState) => ({
        ...prevState,
        snackbarMessage: data.message,
        snackBarSeverity: severity,
      }));
    }
  };

  const handleAddBriefType = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/briefs`,
      dataName: `brief`,
      method: `post`,
      data: {
        brandCoeId,
        briefType: msBriefType.msBriefTypeName,
      },
    });
    handleLoader(false);
  };

  const handleMsBriefTypeChange = (_, newValue) => {
    if (newValue) {
      setState((prevState) => ({
        ...prevState,
        msBriefType: newValue,
      }));
      if (newValue.msBriefTypeName === "Communication Testing Brief") {
        setSaveContinueDisabled(true);
      } else {
        setSaveContinueDisabled(false);
      }
    } else {
      // If newValue is null (selection cleared), reset the state and enable the button
      setState((prevState) => ({
        ...prevState,
        msBriefType: null,
      }));
      setSaveContinueDisabled(true);
    }
  };

  const handleSelectBriefType = async () => {
    if (msBriefType && Object.keys(msBriefType).length > 0) {
      console.log(msBriefType)
      await handleAddBriefType();
      console.log("after")
    } else if (
      selectedBrief &&
      Object.keys(selectedBrief?.briefType).length > 0
    ) {
      await getSelectedBrief(selectedBrief);
      await handleNext();
    } else {
      // Optionally show an error message or handle the case where msBriefType is not set
    }
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ pl: 20, pr: 20, pb: 20, pt: 5 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Typography sx={{ mb: 2 }}>Select Brief Type:</Typography>

      <FormControl fullWidth data-testid="select-mr-brief-type-form">
        <Autocomplete
          style={{ height: "30px" }}
          options={msBriefTypes}
          value={
            selectedBrief?.briefType
              ? {
                  msBriefTypeId:
                    msBriefTypes.find(
                      (type) =>
                        type.msBriefTypeName === selectedBrief?.briefType
                    )?.msBriefTypeId || "",
                  msBriefTypeName: selectedBrief?.briefType,
                }
              : msBriefType
          }
          getOptionLabel={(option) => option?.msBriefTypeName ?? ""}
          getOptionSelected={(option, value) =>
            option?.msBriefTypeId === value.msBriefTypeId
          }
          disabled={!!selectedBrief.briefId}
          onChange={handleMsBriefTypeChange}
          renderInput={(params) => (
            <TextField {...params} label="Type of Brief" variant="outlined" />
          )}
        />
      </FormControl>

      {msBriefType && (
        <Box sx={{ mt: 10 }}>
          <Typography>{msBriefType.msBriefTypeDescription}</Typography>
        </Box>
      )}

      <Box sx={{ p: 5 }}>
        <Divider
          sx={{
            border: "0.5px dotted #000",
            opacity: 0.3,
          }}
        />

        <Stack
          direction="row"
          spacing={3}
          sx={{ mt: 2, justifyContent: "right" }}
        >
          <Button
            color="inherit"
            variant="contained"
            style={secondaryButton}
            disableElevation
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={saveContinueDisabled ? disabledButton : primaryButton}
            onClick={() => handleSelectBriefType()}
            disableElevation
            disabled={saveContinueDisabled}
          >
            Save & Continue
          </Button>
        </Stack>
      </Box>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
