import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  IconButton,
  Card,
} from "@mui/material";
import apiRequest from "../../services/api-request";
import MarketResearchBriefHomePage from "../../market-research/mr-brief-owner/mr-brief-home-page";
import SnackbarComponent from "../snackbar";
import PropTypes from "prop-types";
import ArrowBackIcon from "../../assets/icons-js-files/arrow-back-icon";
import EmptyEventsPage from "../../events-management/empty-events-page";

SelectBrandTeamBriefPage.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  allBriefs: PropTypes.array,
  targetSegments: PropTypes.array,
  regions: PropTypes.array,
  ageBrackets: PropTypes.array,
  departments: PropTypes.array,
};

SelectBrandTeamBriefPage.defaultProps = {
  allBriefs: [],
  targetSegments: [],
  regions: [],
  ageBrackets: [],
  departments: [],
};

export default function SelectBrandTeamBriefPage(props) {
  const {
    allBriefs,
    targetSegments,
    regions,
    ageBrackets,
    departments,
    handleGoBack,
    setSelectedBrief,
    handleGetAllBriefs,
    msBriefTypes,
  } = props;

  const initialState = {
    currentPage: "selectBrandTeamPage",
    loading: false,
    selectedBrandCoeId: 0,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [brandTeams, setAllBrandTeams] = useState([]);

  const { loading, currentPage, selectedBrandCoeId, snackBarSeverity } = state;

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "brandTeams") {
        setAllBrandTeams(data?.data || []);
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  const handleGetAllBrandTeams = async () => {
    handleLoader(true);
    // const page = 1;
    // const limit = 10;
    await makeApiRequest({
      uri: `/market-research/setup/brand-coe`,
      dataName: `brandTeams`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllBrandTeams();
    })();
  }, []);

  const getImageUrl = (brandTeam) => {
    switch (brandTeam) {
      case "events":
        return "/project-icons/shapes.svg";
      case "research":
        return "/project-icons/speedometer.svg";
      case "assets":
        return "/project-icons/emoji-normal.svg";
      case "education":
        return "/project-icons/emoji-happy.svg";
      // Add more cases for other brandTeam values if needed
      default:
        return "";
    }
  };

  const toggleBriefLandingPage = (currentPage, brandCoeId) => {
    setState((prevState) => ({
      ...prevState,
      currentPage,
      selectedBrandCoeId: brandCoeId,
    }));
    setSelectedBrief({ brandCoeId }); // Ensure selectedBrief has the brand team info
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, pt: 3 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      {currentPage === "selectBrandTeamPage" && (
        <>
          <Box>
            <Grid container direction="row" alignItems="center">
              <IconButton
                onClick={() => {
                  handleGoBack();
                }}
              >
                <ArrowBackIcon
                  stroke="#2CB34A"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </IconButton>
              <Typography
                textAlign="left"
                sx={{ fontWeight: "bold", color: "#2CB34A" }}
              >
                Back
              </Typography>
            </Grid>
            <Box sx={{ pl: 5, pt: 2 }}>
              <Typography
                variant="h5"
                textAlign="left"
                sx={{ fontWeight: "bold" }}
              >
                Brief Department
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              background: "#fff",
              mt: 5,
              ml: 3,
              pt: 5,
              pb: 20,
              pl: 8,
              pr: 8,
              borderTopLeftRadius: 13,
              borderTopRightRadius: 13,
              borderBottomLeftRadius: 13,
              borderBottomRightRadius: 13,
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Select the brief department to get started
              </Typography>

              <Grid container spacing={4} sx={{ mt: 4 }}>
                {brandTeams?.map((brandTeam) => (
                  <Grid item xs={12} lg={6} md={6}>
                    <Card
                      sx={{
                        p: 3,
                        borderRadius: 5,
                        boxShadow:
                          "0px 13px 100px 0px rgba(199, 199, 199, 0.25)",

                        transition: "background-color 0.3s",
                        ":hover": {
                          background:
                            process.env.REACT_APP_PALETTE_PRIMARY_MAIN,
                          color: "#fff",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() =>
                        toggleBriefLandingPage(
                          brandTeam?.brandCoeName,
                          brandTeam?.id
                        )
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={2}>
                          <Box
                            sx={{
                              width: "45px",
                              height: "45px",
                              background: "#f9f9f9",
                              borderRadius: 3,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={getImageUrl(brandTeam.brandCoeShortCode)}
                              alt=""
                              style={{
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid xs={12} md={12} lg={10}>
                          <Box>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontWeight: "bold",
                                fontSize: 18,
                                mb: 3,
                                ml: 2,
                                mt: 2,
                              }}
                            >
                              {brandTeam.brandCoeName}
                            </Typography>
                            <Typography sx={{ ml: 2, textAlign: "left" }}>
                              {brandTeam.brandCoeDescription}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </>
      )}

      {currentPage === "Events" && (
        <Box sx={{ mt: -10 }}>
          <EmptyEventsPage handleGoBack={handleGoBack} />
        </Box>
      )}

      {currentPage === "Market Research" && (
        <MarketResearchBriefHomePage
          handleGoBack={handleGoBack}
          handleGetAllBriefs={handleGetAllBriefs}
          brandCoeId={selectedBrandCoeId}
          allBriefs={allBriefs}
          targetSegments={targetSegments}
          regions={regions}
          ageBrackets={ageBrackets}
          departments={departments}
          msBriefTypes={msBriefTypes}
          setMsBriefType={props.setMsBriefType}
        />
      )}

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
