import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import PropTypes from "prop-types";

SnackbarComponent.propTypes = {
  handleCloseSnackBar: PropTypes.func.isRequired,
  snackBarHandle: PropTypes.func.isRequired,
  snackbarOpen: PropTypes.bool,
  snackbarMessage: PropTypes.string,
  snackBarSeverity: PropTypes.string,
};

SnackbarComponent.defaultProps = {
  snackbarOpen: false,
  snackbarMessage: "",
  snackBarSeverity: "",
};

export default function SnackbarComponent(props) {
  const {
    snackbarMessage,
    snackBarSeverity,
    snackbarOpen,
    handleCloseSnackBar,
    snackBarHandle,
  } = props;

  const initialState = {
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackBarVerticalPosition:
      process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION || "top",
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION || "right",
  };

  const [state] = useState(initialState);

  const {
    snackBarAutoHideDuration,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
  } = state;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: snackBarVerticalPosition,
        horizontal: snackBarHorizontalPosition,
      }}
      open={snackbarOpen}
      autoHideDuration={snackBarAutoHideDuration}
      onClose={() => snackBarHandle(false)}
    >
      <Alert
        onClose={handleCloseSnackBar}
        severity={snackBarSeverity}
        sx={{ width: "100%" }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
