import React from "react";
import PropTypes from "prop-types";

const ArrowBackIcon = ({ stroke }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12H4M4 12L10 18M4 12L10 6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowBackIcon.propTypes = {
  stroke: PropTypes.string,
};

ArrowBackIcon.defaultProps = {
  stroke: "",
};

export default ArrowBackIcon;
