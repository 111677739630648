import React, { useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { logoStyle } from "../styles/user-management-styles";
import { primaryButton } from "../styles/common-styles";
import apiRequest from "../services/api-request";
import SnackbarComponent from "../shared-components/snackbar";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";

VerifyEmail.propTypes = {
  toggleVerifyEmail: PropTypes.func.isRequired,
  isEmailVerified: PropTypes.bool,
  email_verification_token: PropTypes.string,
};

VerifyEmail.defaultProps = {
  isEmailVerified: false,
  email_verification_token: "",
};

export default function VerifyEmail(props) {
  const { isEmailVerified, toggleVerifyEmail, email_verification_token } =
    props;

  console.log({ email_verification_token });

  const initialState = {
    snackBarSeverity: "info",
    tokenData: {},
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { snackBarSeverity, tokenData } = state;

  const handleApiData = (params) => {
    // console.log{params});
    const { data, severity, message } = params;
    if (severity === "success" && data?.operationResult === "SUCCESS") {
      setState((prevState) => ({
        ...prevState,
        snackbarOpen: true,
        snackbarMessage: message,
        snackBarSeverity: severity,
      }));
      alert(`Successfully verified.`);
      window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
    } else {
      setState((prevState) => ({
        ...prevState,
        tokenData: jwt_decode(email_verification_token),
      }));
    }
  };

  const userEmail = tokenData?.userEmail;

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleRequestEmailToken = async () => {
    await makeApiRequest({
      uri: `/user-management/login/request-email-token/userEmail=${userEmail}`,
      dataName: `requestedEmailToken`,
      method: `get`,
      data: "",
    });
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleProceedToLogin = () => {
    toggleVerifyEmail({
      isEmailVerified,
      isEmailVerifiedPage: false,
    });
    window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
  }

  return (
    <Box>
      <Box
        style={{
          background: `url(/project-images/login-background-2.png) no-repeat center center fixed`,
          backgroundSize: "cover",
          backgroundColor: "#fff",
          filter: "blur(35px)",
          width: "100%",
          height: "100%",
          position: "absolute",
          overflow: "hidden",
          zIndex: -2,
        }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          mt: 10,
        }}
      >
        <Box textAlign="center" sx={{ width: 1000 }}>
          <img
            src="/project-images/saf-logo-green.png"
            style={logoStyle}
            alt="saf-logo"
          />
          {!isEmailVerified && (
            <Box>
              <Paper
                textAlign="center"
                elevation={2}
                sx={{ p: 3, width: "60%", margin: "0 auto" }}
              >
                <img
                  src="/project-images/success-verify-email.png"
                  alt="success"
                />
                <Typography sx={{ mt: 4, mb: 4 }}>
                  Your email was verified successfully
                </Typography>
                <Button
                  variant="contained"
                  style={primaryButton}
                  disableElevation
                  onClick={handleProceedToLogin}
                >
                  Proceed to Log In
                </Button>
              </Paper>
              <Typography sx={{ mt: 4, mb: 4, fontSize: 13 }}>
                Copyright ©{new Date().getFullYear()} Safaricom PLC. All rights
                reserved.
              </Typography>
            </Box>
          )}

          {/* {!isEmailVerified && (
            <Box>
              <Paper
                textAlign="center"
                elevation={2}
                sx={{ p: 3, width: "60%", margin: "0 auto" }}
              >
                <img
                  src="/project-images/fail-verify-email.png"
                  alt="success"
                />
                <Typography sx={{ mt: 4, mb: 4 }}>
                  There was a problem verifying your email
                </Typography>
                <Button
                  variant="contained"
                  style={primaryButton}
                  disableElevation
                  onClick={() => handleRequestEmailToken()}
                >
                  Resend Verification Link
                </Button>
              </Paper>
              <Typography sx={{ mt: 4, mb: 4, fontSize: 13 }}>
                Copyright ©{new Date().getFullYear()} Safaricom PLC. All rights
                reserved.
              </Typography>

              <SnackbarComponent
                snackbarMessage={snackbarMessage}
                snackBarSeverity={snackBarSeverity}
                snackbarOpen={snackbarOpen}
                snackBarHandle={snackBarHandle}
                handleCloseSnackBar={handleCloseSnackBar}
              />
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  );
}
