import React, { useState } from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Backdrop,
  Typography,
  CircularProgress,
} from "@mui/material";
import MarketResearchSetups from "./market-research/market-research-setups.js";
import GeneralSetups from "./general/general-setups.js";

export default function Setups() {
  const [alignment, setAlignment] = useState("market-research");

  const initialState = {
    currentSelection: "market-research",
    loading: false,
  };

  const [state, setState] = useState(initialState);

  const { currentSelection, loading } = state;

  const handleToggleButtonChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleTogglePage = (currentSelection) => {
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  return (
    <Box sx={{ mt: 8, ml: 8, mr: 8 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Typography
        variant="h5"
        textAlign="left"
        sx={{ fontWeight: "bold", pb: 2 }}
      >
        Setups
      </Typography>

      <Box
        sx={{
          bgcolor: "background.paper",
          p: 3,
          mb: 10,
        }}
      >
        <Box sx={{ mb: 5 }}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleToggleButtonChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="market-research"
              sx={{ textTransform: "none" }}
              onClick={() => handleTogglePage("market-research")}
            >
              Market Research
            </ToggleButton>

            {/* <ToggleButton
              value="events"
              sx={{ textTransform: "none" }}
              onClick={() => handleTogglePage("events")}
            >
              Events Management
            </ToggleButton> */}

            <ToggleButton
              value="general-setups"
              sx={{ textTransform: "none" }}
              onClick={() => handleTogglePage("general-setups")}
            >
              General
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Box>
          {currentSelection === "market-research" && <MarketResearchSetups />}
          {/* {currentSelection === "events" && <EventsManagementSetups />} */}
          {currentSelection === "general-setups" && <GeneralSetups />}
        </Box>
      </Box>
    </Box>
  );
}
