import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  IconButton,
  Modal,
  Fade,
  TextField,
  FormControl,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import apiRequest from "../../services/api-request";
import SnackbarComponent from "../../shared-components/snackbar";
import {
  approveButton,
  disabledButton,
  modalStyle,
  primaryButton,
  rejectButton,
  secondaryApprovalButton,
} from "../../styles/common-styles";
import SelectUserToAssignTaskAutocompleteField from "./select-user-to-assign-task-autocomplete";
import BriefDetails from "../../shared-components/brief-details";
import ArrowBackIcon from "../../assets/icons-js-files/arrow-back-icon";

const validationSchema = yup.object({
  comment: yup
    .string()
    .required("Comment is required")
    .max(500, "Comment must be at most 500 characters")
    .matches(
      /^[a-zA-Z0-9\s.'\-\[\]\(\)]+$/,
      "Comment can only contain letters, numbers, spaces, periods, hyphens, apostrophes, and brackets"
    ),
});

MarketResearchLeadBriefApprovalPage.propTypes = {
  togglePage: PropTypes.func.isRequired,
  roles: PropTypes.array,
  brandCoeId: PropTypes.string,
  selectedBrief: PropTypes.object,
};

MarketResearchLeadBriefApprovalPage.defaultProps = {
  selectedBrief: {},
  brandCoeId: "",
  roles: [],
};

export default function MarketResearchLeadBriefApprovalPage(props) {
  const { selectedBrief, roles, brandCoeId, togglePage } = props;
  console.log("my roles",roles);
  const { briefId } = selectedBrief;

  const initialState = {
    currentPage: "taskDetailsPage",
    currentSelection: "",
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [researchPlannerUsers, setResearchPlannerUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [comment, setComment] = useState("");
  const [fullBrief, setFullBrief] = useState({});

  const { currentSelection, loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      comment,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmitApproval();
    },
    validateOnMount: true,
  });

  const { values, errors, touched, isValid, handleChange, handleBlur } = formik;

  const handleUserChange = (userId) => {
    setSelectedUserId(userId);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "fullBrief") {
        setFullBrief(data?.data);
      }
      if (dataName === "researchPlannerUsers") {
        setResearchPlannerUsers(data?.data);
      }
      if (dataName === "reviewedBrief") {
        window.location.reload();
      }
      if (dataName === "reviewedReport") {
        window.location.reload();
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    handleChange(e);

    if (name === "comment") {
      setComment(value);
    }
  };

  const handleGetFullBrief = async () => {
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/${briefId}`,
      dataName: `fullBrief`,
      method: `get`,
      data: "",
    });
  };

  const handleGetResearchPlannerUsers = async () => {
    const getResearchPlannerRoleId = (roleName) => {
      const role = roles.find((item) => item.name === roleName);
      
      return role ? role.id : null;
    };

    const researchPlannerRoleId = getResearchPlannerRoleId("ROLE_RESEARCH_PLANNER");
// /users/role/{{role id}}/brand-coe/4
    handleLoader(true);
    await makeApiRequest({
      uri: `/user-management/users/role/${researchPlannerRoleId}/brand-coe/${brandCoeId}`,
      dataName: `researchPlannerUsers`,
      method: `get`,
      data: "",
    });
  };

  useEffect(() => {
    (async () => {
      await handleGetFullBrief();
      await handleGetResearchPlannerUsers();
    })();
  }, []);

  const handleApproval = (currentSelection) => {
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleSubmitApproval = async () => {
    handleLoader(true);

    let approvalTaskValue;

    switch (currentSelection) {
      case "approveBrief":
        approvalTaskValue = "APPROVE";
        break;
      case "rejectBrief":
        approvalTaskValue = "REJECT";
        break;
      case "requestForChanges":
        approvalTaskValue = "REQUEST_CHANGES";
        break;
      default:
        break;
    }

    await makeApiRequest({
      uri: `/market-research/briefs/tasks`,
      dataName: `reviewedBrief`,
      method: `post`,
      data: {
        briefId: selectedBrief?.briefId,
        approvalTask: approvalTaskValue,
        comment,
        userId: selectedUserId,
      },
    });
    handleLoader(false);
    handleCloseModal();
  };

  const handleSubmitReport = async () => {
    handleLoader(true);

    let approvalTaskValue;

    switch (currentSelection) {
      case "approveReport":
        approvalTaskValue = "APPROVE";
        break;
      case "requestForChangesForReport":
        approvalTaskValue = "REQUEST_CHANGES";
        break;
      default:
        break;
    }

    await makeApiRequest({
      uri: `/market-research/briefs/tasks`,
      dataName: `reviewedReport`,
      method: `post`,
      data: {
        briefId: selectedBrief?.briefId,
        approvalTask: approvalTaskValue,
        comment,
      },
    });
    handleLoader(false);
    handleCloseModal();
  };

  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_MARKET_RESEARCH_LEAD":
        return {
          text: "Review Brief",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD_REPORT":
        return {
          text: "Review Report",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      default:
        return {
          text: "Unknown Status",
        };
    }
  };

  const statusDisplay = getStatusDisplay(selectedBrief?.briefStatus);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Grid container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() =>
            togglePage({
              currentPage: "taskListPage",
              selectedBrief,
            })
          }
        >
          <ArrowBackIcon
            stroke="#2CB34A"
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </IconButton>

        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          Brief #{selectedBrief?.briefId}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={9} lg={9}>
          <BriefDetails
            selectedBrief={fullBrief}
            statusDisplay={statusDisplay}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          {selectedBrief?.briefStatus === "BRIEF_MARKET_RESEARCH_LEAD" && (
            <Box>
              <Button
                variant="contained"
                style={approveButton}
                sx={{ mb: 3 }}
                onClick={() => handleApproval("approveBrief")}
              >
                Approve Brief
              </Button>

              <Button
                variant="contained"
                style={secondaryApprovalButton}
                sx={{ mb: 3 }}
                onClick={() => handleApproval("requestForChanges")}
              >
                Request Additional Info
              </Button>

              <Button
                variant="contained"
                style={rejectButton}
                onClick={() => handleApproval("rejectBrief")}
              >
                Reject Brief
              </Button>
            </Box>
          )}

          {selectedBrief?.briefStatus ===
            "BRIEF_MARKET_RESEARCH_LEAD_REPORT" && (
            <Box>
              <Button
                variant="contained"
                style={approveButton}
                sx={{ mb: 3 }}
                onClick={() => handleApproval("approveReport")}
              >
                Approve Report
              </Button>

              <Button
                variant="contained"
                style={secondaryApprovalButton}
                sx={{ mb: 3 }}
                onClick={() => handleApproval("requestForChangesForReport")}
              >
                Request Report Changes
              </Button>
            </Box>
          )}
        </Grid>

        <FormikProvider value={formik}>
          <Modal
            aria-labelledby="approval-modal"
            aria-describedby="approval-modal-description"
            open={currentSelection}
            onClose={handleCloseModal}
            closeAfterTransition
          >
            <Fade in={currentSelection !== ""}>
              <Box>
                <Box style={modalStyle} sx={{ p: 5 }}>
                  {currentSelection === "approveBrief" && (
                    <>
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          id="approval-modal"
                          variant="h6"
                          component="h2"
                          sx={{ color: "#2CB34A", fontWeight: "bold" }}
                        >
                          Select Research Planner to Assign Brief
                        </Typography>
                      </Box>

                      <FormControl fullWidth>
                        <SelectUserToAssignTaskAutocompleteField
                          name="userId"
                          label="Research Planner User"
                          options={researchPlannerUsers}
                          defaultValue=""
                          selectedBrief={fullBrief}
                          selectedUserId={selectedUserId}
                          onUserChange={handleUserChange}
                        />
                      </FormControl>

                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={touched.comment && Boolean(errors.comment)}
                          helperText={touched.comment && errors.comment}
                        />
                      </Box>
                    </>
                  )}

                  {currentSelection === "requestForChanges" && (
                    <>
                      <Typography
                        id="approval-modal"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#2CB34A", fontWeight: "bold" }}
                      >
                        Request for Additional Information
                      </Typography>

                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={touched.comment && Boolean(errors.comment)}
                          helperText={touched.comment && errors.comment}
                        />
                      </Box>
                    </>
                  )}

                  {currentSelection === "approveReport" && (
                    <>
                      <Typography
                        id="approval-modal"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#2CB34A", fontWeight: "bold" }}
                      >
                        Approve Report
                      </Typography>

                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={touched.comment && Boolean(errors.comment)}
                          helperText={touched.comment && errors.comment}
                        />
                      </Box>
                    </>
                  )}

                  {currentSelection === "requestForChangesForReport" && (
                    <>
                      <Typography
                        id="approval-modal"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#2CB34A", fontWeight: "bold" }}
                      >
                        Request Report Changes
                      </Typography>

                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={touched.comment && Boolean(errors.comment)}
                          helperText={touched.comment && errors.comment}
                        />
                      </Box>
                    </>
                  )}

                  <Box sx={{ mt: 3 }}>
                    {currentSelection === "approveBrief" && (
                      <Button
                        style={!isValid ? disabledButton : primaryButton}
                        onClick={() => handleSubmitApproval()}
                        disabled={!isValid}
                      >
                        Submit Approval
                      </Button>
                    )}

                    {currentSelection === "requestForChanges" && (
                      <Button
                        style={isValid ? primaryButton : disabledButton}
                        onClick={() => handleSubmitApproval()}
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    )}

                    {currentSelection === "approveReport" && (
                      <Button
                        style={!isValid ? disabledButton : primaryButton}
                        onClick={() => handleSubmitReport()}
                        disabled={!isValid}
                        F
                      >
                        Submit Approval for Report
                      </Button>
                    )}

                    {currentSelection === "requestForChangesForReport" && (
                      <Button
                        style={!isValid ? disabledButton : primaryButton}
                        onClick={() => handleSubmitReport()}
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box>
                  {currentSelection === "rejectBrief" && (
                    <Box style={modalStyle} sx={{ p: 5 }}>
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          id="reject-brief-modal"
                          variant="h6"
                          component="h2"
                          sx={{ color: "#2CB34A", fontWeight: "bold" }}
                        >
                          Reason for rejecting brief
                        </Typography>
                      </Box>
                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleFormChange}
                          onBlur={handleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={touched.comment && Boolean(errors.comment)}
                          helperText={touched.comment && errors.comment}
                        />
                      </Box>

                      <Button
                        style={isValid ? primaryButton : disabledButton}
                        onClick={() => handleSubmitApproval()}
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Fade>
          </Modal>
        </FormikProvider>
      </Grid>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
