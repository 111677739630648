import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { cancelButton, primaryButton } from "../styles/common-styles";
import PropTypes from "prop-types";

ActiveSessionDialog.propTypes = {
  handleConfirmOverrideUserSession: PropTypes.func.isRequired,
  handleCancelOverrideUserSession: PropTypes.func.isRequired,
  activeUserSessionDialogOpen: PropTypes.bool,
};

ActiveSessionDialog.defaultProps = {
  activeUserSessionDialogOpen: false,
};

export default function ActiveSessionDialog(props) {
  const {
    handleConfirmOverrideUserSession,
    handleCancelOverrideUserSession,
    activeUserSessionDialogOpen,
  } = props;

  return (
    <Dialog
      sx={{ textAlign: "center" }}
      open={activeUserSessionDialogOpen}
      onClose={handleCancelOverrideUserSession}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ color: "#EF373E", pt: 5 }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          You have another active session
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Do you want to override the existing session and start a new session?
          This will end the existing session
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 5, justifyContent: "center" }}>
        <Button
          onClick={handleConfirmOverrideUserSession}
          style={primaryButton}
          autoFocus
        >
          Override Session
        </Button>

        <Button onClick={handleCancelOverrideUserSession} style={cancelButton}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
