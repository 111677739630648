import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const MarketResearchPlannerBriefStatusDisplay = ({ status }) => {
  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_RESEARCH_PLANNER_REVIEW":
        return {
          text: "Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_EXTERNAL_REVIEW":
        return {
          text: "Upload Report",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD_REPORT":
        return {
          text: "Report Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
        case "BRIEF_STUDY_COMPLETED":
          return {
            text: "Study complete",
            backgroundColor: "rgba(44, 179, 74, 0.15)",
            textColor: "#2CB34A",
            fontSize: "12px",
            borderRadius: "20px",
            fontWeight: "bold",
            width: "auto",
            paddingRight: "10px",
            paddingLeft: "10px",
          };
      default:
        return {
          text: "Unknown Status",
        };
    }
  };

  const {
    text,
    backgroundColor,
    textColor,
    fontSize,
    fontWeight,
    borderRadius,
    padding,
  } = getStatusDisplay(status);

  return (
    <Button
      sx={{
        backgroundColor,
        color: textColor,
        fontSize,
        fontWeight,
        borderRadius,
        fontFamily: "Futura",
        textTransform: "none",
        padding,
      }}
    >
      {text}
    </Button>
  );
};

MarketResearchPlannerBriefStatusDisplay.propTypes = {
  status: PropTypes.string,
};

MarketResearchPlannerBriefStatusDisplay.defaultProps = {
  status: "",
};

export default MarketResearchPlannerBriefStatusDisplay;
