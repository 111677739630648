import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { FormikProvider, useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import MarketResearchBriefHomePage from "./mr-brief-home-page";
import CreateBriefButton from "../../shared-components/create-brief-button";
import SnackbarComponent from "../../shared-components/snackbar";
import EmptyBriefListPage from "../../shared-components/brief-owner/empty-brief-list-page";
import apiRequest from "../../services/api-request";
import { getMuiDatatableTheme } from "../../styles/material-ui-theme";
import MarketResearchBriefOwnerDetailsPage from "./mr-brief-owner-details-page";
import ConfirmationDialog from "../../shared-components/confirmation-dialog";
import DateCreatedDisplay from "../datatable-components/date-created-display";
import EditIcon from "../../assets/icons-js-files/edit-icon";
import ViewIcon from "../../assets/icons-js-files/view-icon";
import DeleteIcon from "../../assets/icons-js-files/delete-icon";
import CancelIcon from "../../assets/icons-js-files/cancel-icon";
import BriefOwnerBriefStatusDisplay from "../datatable-components/brief-owner-brief-status-display";
import StatusFilterDropdown from "../../shared-components/brief-owner/filters/status-filter-dropdown";
import customLocalStorage from "../../services/local-storage";

const msBriefTypes = [
  {
    msBriefTypeName: "Customised Brief",
    msBriefTypeId: "1",
    msBriefTypeDescription: "",
  },
  {
    msBriefTypeName: "Communication Testing Brief",
    msBriefTypeId: "2",
    msBriefTypeDescription: "Coming soon",
  },
];
export default function MarketResearchBriefListPage() {
  const initialState = {
    currentPage: "mrBriefListPage",
    marketResearchBriefs: [],
    brandTeams: [],
    brandCoeId: 0,
    briefStatuses: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [regions, setRegions] = useState([]);
  const [targetSegments, setTargetSegments] = useState([]);
  const [ageBrackets, setAgeBrackets] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [briefIdToDelete, setBriefIdToDelete] = useState(null);

  const [briefIdToCancel, setBriefIdToCancel] = useState(null);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  const [selectedBrief, setSelectedBrief] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [selectedBriefStatuses, setSelectedBriefStatuses] = useState([]);

  const {
    currentPage,
    marketResearchBriefs,
    brandTeams,
    briefStatuses,
    loading,
    snackBarSeverity,
  } = state;

  const buttonText = "Create MR Brief";
  const emptyPageTitle = "No Market Research Briefs Created";
  const emptyPageDescription =
    "Click on create brief button to create your first brief.";

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
  });

  const updateSelectedBrief = (newBrief) => {
    setSelectedBrief(newBrief);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (
        dataName === "marketResearchBriefs" ||
        dataName === "brandTeams" ||
        dataName === "departments" ||
        dataName === "briefStatuses"
      ) {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }

      if (dataName === "targetSegments") {
        setTargetSegments(data?.data || []);
      }

      if (dataName === "regions") {
        setRegions(data?.data || []);
      }

      if (dataName === "ageBrackets") {
        setAgeBrackets(data?.data || []);
      }

      if (dataName === "departments") {
        setDepartments(data?.data || []);
      }

      if (dataName === "deletedBrief" || dataName === "cancelledBrief") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));
        window.location.reload();
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  const handleGetAllBriefs = async () => {
    handleLoader(true);
    // const page = 0;
    // const size = 100;
    await makeApiRequest({
      //uri: `/market-research/briefs?page=${page}&size=${size}`,
      uri: `/market-research/briefs`,
      dataName: `marketResearchBriefs`,
      method: `get`,
      data: "",
    });
    await makeApiRequest({
      uri: `/market-research/setup/target-segments`,
      dataName: `targetSegments`,
      method: `get`,
      data: "",
    });
    await makeApiRequest({
      uri: `/market-research/setup/regions`,
      dataName: `regions`,
      method: `get`,
      data: "",
    });
    await makeApiRequest({
      uri: `/market-research/setup/age-bracket`,
      dataName: `ageBrackets`,
      method: `get`,
      data: "",
    });
    await makeApiRequest({
      uri: `/market-research/setup/brand-coe`,
      dataName: `brandTeams`,
      method: `get`,
      data: "",
    });
    await makeApiRequest({
      uri: `/market-research/setup/coe-department`,
      dataName: `departments`,
      method: `get`,
      data: "",
    });
    await makeApiRequest({
      uri: `/market-research/enum/brief-statuses`,
      dataName: `briefStatuses`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllBriefs();
    })();
  }, []);

  // Set brandCoeId dynamically based on the "Market Research and Customer Insights" team name
  const getBrandCoeId = (teamName) => {
    const team = brandTeams.find((item) => item.brandCoeName === teamName);
    return team ? team.id : null;
  };

  const marketResearchTeamName = "Market Research";
  const brandCoeId = getBrandCoeId(marketResearchTeamName);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleOpen = (currentPage) => {
    setState((prevState) => ({
      ...prevState,
      currentPage,
    }));
  };

  const resetState = () => {
    setState((prevState) => ({
      ...prevState,
      currentBrief: {},
    }));
    window.location.reload();
  };

  const handleGoBack = () => {
    setState((prevState) => ({
      ...prevState,
      currentPage: "mrBriefListPage",
    }));
    resetState();
  };

  const togglePage = (params) => {
    const {
      currentPage,
      targetSegments,
      regions,
      ageBrackets,
      departments,
      selectedBrief,
      navigatedFrom,
    } = params;

    if (
      (params.currentPage === "mrBriefingForm" && params.selectedBrief) ||
      (params.currentPage === "mrBriefDetailsPage" && params.selectedBrief)
    ) {
      setSelectedBrief(selectedBrief);
    } else {
      setSelectedBrief(null);
    }

    setState((prevState) => ({
      ...prevState,
      currentPage,
      selectedBrief,
      targetSegments,
      regions,
      ageBrackets,
      departments,
      navigatedFrom,
    }));
  };

  const handleGetBriefsByStatus = async (selectedValues) => {
    setSelectedBriefStatuses(selectedValues);
    const statuses = selectedValues.join(",");
    await makeApiRequest({
      uri: `/market-research/briefs?statuses=${statuses}`,
      method: "get",
      dataName: `allBriefs`,
      data: "",
    });
  };

  const handleBriefStatusChange = (selectedValues) => {
    setSelectedBriefStatuses(selectedValues);
    handleGetBriefsByStatus(selectedValues);
  };

  const handleOpenDeleteBriefDialog = (selectedBrief) => {
    setBriefIdToDelete(selectedBrief?.briefId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteBrief = async (briefId) => {
    await makeApiRequest({
      uri: `/market-research/briefs/${briefId}`,
      dataName: `deletedBrief`,
      method: `delete`,
      data: {},
    });
  };

  const handleConfirmDeleteBrief = async () => {
    if (briefIdToDelete) {
      await handleDeleteBrief(briefIdToDelete);
      setDeleteConfirmationOpen(false);
      await handleApiData({ dataName: "allBriefs" });
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleOpenCancelBriefDialog = (selectedBrief) => {
    setBriefIdToCancel(selectedBrief?.briefId);
    setCancelConfirmationOpen(true);
  };

  const handleCancelBrief = async (briefId) => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/briefs/submit-for-approval`,
      dataName: `cancelledBrief`,
      method: `post`,
      data: {
        briefId,
        comment: "Brief cancelled by Brief Owner",
        approvalTask: "CANCEL",
      },
    });
    handleLoader(false);
  };

  const handleConfirmCancelBrief = async () => {
    if (briefIdToCancel) {
      await handleCancelBrief(briefIdToCancel);
      await handleApiData({ dataName: "allBriefs" });
    }
    setCancelConfirmationOpen(false);
  };

  const handleCancelCancellation = () => {
    setCancelConfirmationOpen(false);
  };

  const briefColumns = [
    {
      name: "briefTitle",
      label: "Brief Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "briefType",
      label: "Brief Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "approximateBudget",
      label: "Brief Budget",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Date Created",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateCreatedDisplay dateCreated={value} />,
      },
    },
    {
      name: "briefStatus",
      label: "Status",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (status) => (
          <BriefOwnerBriefStatusDisplay status={status} />
        ),
      },
    },
    {
      name: "briefId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[4];
          const isEditable = [
            "BRIEF_IN_DRAFT",
            "BRIEF_COMPLETE",
            "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD",
            "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER",
          ].includes(status);
          const selectedBrief = marketResearchBriefs?.find(
            (brief) => brief?.briefId === value
          );

          return (
            <>
              {isEditable && (
                <Tooltip title="Edit Brief">
                  <IconButton
                    onClick={() =>
                      togglePage({
                        currentPage: "mrBriefingForm",
                        selectedBrief,
                      })
                    }
                  >
                    <EditIcon
                      stroke="#121212"
                      style={{
                        marginRight: "10px",
                        width: "10px",
                        height: "10px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!isEditable && (
                <Tooltip title="View Brief">
                  <IconButton
                    onClick={() =>
                      togglePage({
                        currentPage: "mrBriefDetailsPage",
                        selectedBrief,
                      })
                    }
                  >
                    <ViewIcon
                      stroke="#121212"
                      style={{ marginRight: "10px", width: "10px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {["BRIEF_IN_DRAFT", "BRIEF_COMPLETE", "BRIEF_REJECTED"].includes(
                status
              ) && (
                <Tooltip title="Delete Brief">
                  <IconButton
                    onClick={() => handleOpenDeleteBriefDialog(selectedBrief)}
                  >
                    <DeleteIcon stroke="#ff2a58" />
                  </IconButton>
                </Tooltip>
              )}
              {[
                "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD",
                "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER",
              ].includes(status) && (
                <Tooltip title="Cancel Brief">
                  <IconButton
                    onClick={() => handleOpenCancelBriefDialog(selectedBrief)}
                  >
                    <CancelIcon
                      stroke="#ff2a58"
                      style={{
                        marginRight: "10px",
                        width: "18px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    download: false,
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Clear local storage data on component unmount: breiefOverViewFormData, breiefDetailsFormData_stepper2
    customLocalStorage.removeItem("breiefOverViewFormData");
    customLocalStorage.removeItem("breiefDetailsFormData_stepper2");
  }, [])
  

  return (
    <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, height: "100%" }}>
      <Backdrop
        sx={{ zIndex: 1, color: "#fff" }}
        open={loading}
        data-testid="backdrop"
      >
        <CircularProgress color="success" data-testid="circular-progress" />
      </Backdrop>

      <Box sx={{ borderColor: "divider", mb: 4, mt: 8, ml: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex" }}
        >
          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Market Research & Customer Insights Briefs
          </Typography>

          {currentPage === "mrBriefListPage" && (
            <CreateBriefButton
              buttonText={buttonText}
              onClick={() => handleOpen("mrBriefingForm")}
            />
          )}
        </Stack>
      </Box>

      <Box sx={{ mb: 4, mt: 5, ml: 3 }}>
        {currentPage === "mrBriefListPage" &&
          marketResearchBriefs?.length > 0 && (
            <ThemeProvider theme={getMuiDatatableTheme()}>
              <FormikProvider value={formik}>
                <MUIDataTable
                  title={
                    <Stack direction="row" spacing={3} sx={{ mb: 2, mt: 3 }}>
                      <StatusFilterDropdown
                        name="statusIds"
                        label="Brief Status"
                        options={briefStatuses.map((status) => ({
                          name: status,
                          id: status,
                        }))}
                        selectedFilterId={selectedBriefStatuses}
                        onFilterChange={handleBriefStatusChange}
                      />
                    </Stack>
                  }
                  data={marketResearchBriefs}
                  columns={briefColumns}
                  options={options}
                />
              </FormikProvider>
            </ThemeProvider>
          )}

        {currentPage === "mrBriefListPage" &&
          marketResearchBriefs?.length < 1 && (
            <Box sx={{ ml: -3 }}>
              <EmptyBriefListPage
                buttonText={buttonText}
                emptyPageTitle={emptyPageTitle}
                emptyPageDescription={emptyPageDescription}
                onClick={() => handleOpen("mrBriefingForm")}
              />
            </Box>
          )}

        {currentPage === "mrBriefingForm" && (
          <MarketResearchBriefHomePage
            handleGoBack={handleGoBack}
            handleGetAllBriefs={handleGetAllBriefs}
            allBriefs={marketResearchBriefs}
            selectedBrief={selectedBrief}
            updateSelectedBrief={updateSelectedBrief}
            brandCoeId={brandCoeId}
            msBriefTypes={msBriefTypes}
            targetSegments={targetSegments}
            regions={regions}
            ageBrackets={ageBrackets}
            departments={departments}
          />
        )}

        {currentPage === "mrBriefDetailsPage" && (
          <MarketResearchBriefOwnerDetailsPage
            selectedBrief={selectedBrief}
            togglePage={togglePage}
            navigatedFrom="mrBriefListPage"
          />
        )}
      </Box>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        handleClose={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this brief?"
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        handleCancel={handleCancelDelete}
        handleConfirm={handleConfirmDeleteBrief}
        loading={loading}
      />

      <ConfirmationDialog
        open={cancelConfirmationOpen}
        handleClose={handleCancelCancellation}
        title="Confirm Cancellation"
        content="Are you sure you want to cancel brief?"
        cancelButtonText="Close"
        confirmButtonText="Confirm Cancellation"
        handleCancel={handleCancelCancellation}
        handleConfirm={handleConfirmCancelBrief}
      />

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
