import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
} from "@mui/material";
import { helperTextStyle, inputStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  secondaryButton,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = Yup.object({
  displayName: Yup.string()
    .matches(/^[a-zA-Z -,'"]+$/, "Role display name is invalid")
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_HUNDRED, "Too long")
    .required("Role display name cannot be empty"),
  name: Yup.string()
    .matches(/^[a-zA-Z -,'_]+$/, "Role name is invalid")
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_HUNDRED, "Too long")
    .required("Role name cannot be empty"),
});

export default function RolesSetup() {
  const initialState = {
    roles: [],
    brandTeams: [],
    loading: false,
    snackbarOpen: false,
    snackbarMessage: "",
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [isUpdatingRole, setIsUpdatingRole] = useState(false);

  const {
    roles,
    brandTeams,
    loading,
    snackbarOpen,
    snackbarMessage,
    snackBarSeverity,
  } = state;

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity, message } = params;

    if (severity === "success") {
      if (dataName === "roles" || dataName === "brandTeams") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data ?? [],
        }));
      }

      if (dataName === "deletedRole") {
        window.location.reload();
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const makeBrandTeamApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitRoles = async (currentRole) => {
    const action = currentRole?.id ? "update" : "create";
    let uri = `/user-management/roles`;
    let method = `post`;
    if (action === "update") {
      uri = `/user-management/roles`;
      method = `put`;
    }
    await makeApiRequest({
      uri,
      dataName: `addedRole`,
      method,
      data: {
        ...currentRole,
      },
    });
  };

  const handleAddRoles = (values) => {
    let { roles, currentRole } = state;

    if (isUpdatingRole) {
      const indexOfRole = roles.findIndex((role) => {
        return role?.id == currentRole?.id;
      });
      roles[indexOfRole] = currentRole;
      setIsUpdatingRole(false);
    } else {
      roles.push(currentRole);
    }
    roles = [
      ...new Map(roles.map((item) => [item["displayName"], item])).values(),
    ];

    setState((prevState) => ({
      ...prevState,
      roles,
      currentRole: {},
    }));

    handleSubmitRoles(currentRole);
  };

  const handleSelectRole = (role) => {
    setIsUpdatingRole(true);
    setState((prevState) => ({
      ...prevState,
      currentRole: role,
    }));
    formik.setValues({
      name: role.name,
      displayName: role.displayName,
      brandTeam: brandTeams.find((team) => team.id === role.coeBrandId) || null,
    });
  };

  const handleDeleteRole = async (id) => {
    await makeApiRequest({
      uri: `/user-management/roles/${id}`,
      dataName: `deletedRole`,
      method: `delete`,
      data: {},
    });
  };

  const handleGetAllRoles = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/user-management/roles`,
      dataName: `roles`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  const handleGetAllBrandTeams = async () => {
    handleLoader(true);
    await makeBrandTeamApiRequest({
      uri: `/market-research/setup/brand-coe`,
      dataName: `brandTeams`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllRoles();
      await handleGetAllBrandTeams();
    })();
  }, []);

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleCloseSnackBar = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  const formik = useFormik({
    initialValues: {
      displayName: "",
      name: "",
      brandTeam: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setState((prevState) => ({
        ...prevState,
        currentRole: {
          ...prevState.currentRole,
          name: values.name,
          displayName: values.displayName,
          coeBrandId: values.brandTeam ? values.brandTeam.id : null,
        },
      }));
      handleAddRoles();
    },
  });

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>Add/Edit Roles</Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <Autocomplete
              options={brandTeams}
              value={formik.values.brandTeam}
              getOptionLabel={(option) => option?.brandCoeName ?? ""}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              onChange={(event, newValue) => {
                formik.setFieldValue("brandTeam", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Brand Team" variant="outlined" />
              )}
            />
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Role Display Name"
              name="displayName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.displayName}
              fullWidth
              required
              error={
                formik.touched.displayName && Boolean(formik.errors.displayName)
              }
            />
            {formik.touched.displayName && formik.errors.displayName && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.displayName}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Role Name"
              name="name"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              fullWidth
              required
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            {formik.touched.name && formik.errors.name && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.name}
              </FormHelperText>
            )}
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 2 }}>
          <Button
            data-testid="submit-role-button"
            type="submit"
            style={formik.isValid ? primaryButton : disabledButton}
            disabled={!formik.isValid}
          >
            {isUpdatingRole ? "UPDATE" : "ADD"}
          </Button>
          {isUpdatingRole && (
            <Button
              style={secondaryButton}
              onClick={() => {
                setIsUpdatingRole(false);
                formik.resetForm();
              }}
            >
              CANCEL
            </Button>
          )}
        </Stack>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 5 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Roles List
        </Typography>

        <List>
          {roles &&
            roles.map((brandTeam) => (
              <Box key={brandTeam?.brandCoeId} sx={{ mt: 3 }}>
                {brandTeam && (
                  <>
                    <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                      {brandTeam?.brandCoeName}
                    </Typography>

                    {brandTeam.roles?.map((role) => (
                      <ListItem key={role.id}>
                        <ListItemText
                          primary={role?.displayName || "No Display Name"}
                          secondary={role?.name}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleSelectRole(role)}
                          >
                            <EditIcon stroke="#121212" />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteRole(role.id)}
                          >
                            <DeleteIcon stroke="#ff2a58" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    <Divider />
                  </>
                )}
              </Box>
            ))}
        </List>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
