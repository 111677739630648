import "./App.css";
import React, { useState, useEffect } from "react";
import Home from "./navigation/home";
import CacheBuster from "./CacheBuster";

function App() {
  const [selectedComponent, setSelectedComponent] = useState(null);

  useEffect(() => {
    // Retrieve the previously selected component from local storage
    const storedComponent = localStorage.getItem("selectedComponent");
    if (storedComponent) {
      setSelectedComponent(storedComponent);
    }
  }, []);

  const handleMenuItemClick = (componentName) => {
    setSelectedComponent(componentName);
    localStorage.setItem("selectedComponent", componentName); // Store the selected component in local storage
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Home
            selectedComponent={selectedComponent}
            onMenuItemClick={handleMenuItemClick}
          />
        );
      }}
    </CacheBuster>
  );
}

export default App;
