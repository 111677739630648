import apiRequest from "./api-request";

const getAppSecrets = async () => {

  const handleApiData = (params) => {
    const { severity } = params;
    if (severity === "success") {
      // save response to localstorage
      localStorage.setItem("appSecrets", JSON.stringify(params));
    } else {
      localStorage.setItem("appSecrets", null);
    }
  };
  const makeApiRequest = async (params) => {
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
  };

  await makeApiRequest({
    uri: `/getAppSecrets`,
    dataName: `getAppSecrets`,
    method: `get`,
    data: "",
  });
};

export default getAppSecrets;
