import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { cancelButton, deleteButton } from "../styles/common-styles";
import PropTypes from "prop-types";

DeleteUserDialog.propTypes = {
  handleConfirmDeleteUser: PropTypes.func.isRequired,
  handleCancelDelete: PropTypes.func.isRequired,
  deleteConfirmationOpen: PropTypes.bool,
};

DeleteUserDialog.defaultProps = {
  deleteConfirmationOpen: false,
};

export default function DeleteUserDialog(props) {
  const {
    handleConfirmDeleteUser,
    handleCancelDelete,
    deleteConfirmationOpen,
  } = props;

  return (
    <Dialog
      sx={{ textAlign: "center" }}
      open={deleteConfirmationOpen}
      onClose={handleCancelDelete}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ color: "#EF373E", pt: 5 }}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          Confirm Delete
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this user?</Typography>
      </DialogContent>
      <DialogActions sx={{ pb: 5, justifyContent: "center" }}>
        <Button onClick={handleCancelDelete} style={cancelButton}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDeleteUser}
          style={deleteButton}
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
