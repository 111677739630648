import React, { useState } from "react";
import {
  Button,
  Link,
  TextField,
  Box,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import apiRequest from "../services/api-request";
import { useFormik } from "formik";
import * as yup from "yup";
import SnackbarComponent from "../shared-components/snackbar";
import PropTypes from "prop-types";

const otpInputStyle = {
  width: "40px",
  color: "#2CB34A",
};

const otpPrimaryButton = {
  background: "#2CB34A",
  color: "#fff",
  width: "200px",
  borderRadius: "20px",
  textTransform: "none",
};

const otpDisabledButton = {
  background: "#e5e5e5",
  borderRadius: "20px",
  width: "200px",
  textTransform: "none",
};

const validationSchema = yup.object({
  tfa_code: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^\d$/, "Digit must be a number")
        .required("Digit is required")
    )
    .length(4, "Please enter all 4 digits"),
});

OtpValidation.propTypes = {
  handleSignedInUser: PropTypes.func.isRequired,
  toggleOtpPage: PropTypes.func.isRequired,
  userId: PropTypes.string,
  msisdn: PropTypes.string,
};

OtpValidation.defaultProps = {
  userId: "",
  msisdn: "",
};

export default function OtpValidation(props) {
  const { handleSignedInUser, userId, msisdn, toggleOtpPage } = props;

  const initialState = {
    loading: false,
    snackBarSeverity: "info",
    otpValues: ["", "", "", ""],
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { loading, snackBarSeverity, otpValues } = state;

  // const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const [isFourDigits, setIsFourDigits] = useState(false);

  const formik = useFormik({
    initialValues: {
      tfa_code: ["", "", "", ""],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleOtpVerification(values);
    },
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: true,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldTouched,
  } = formik;

  const handleInput = (event, currentIndex) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === `tfa_code[${currentIndex}]`) {
      // Handle OTP input fields
      value = value.replace(/\D/g, "").slice(0, 1);

      // Update the value in Formik
      handleChange({
        target: {
          name: `tfa_code[${currentIndex}]`,
          value,
        },
      });

      // Set touched state for the current array element
      setFieldTouched(`tfa_code[${currentIndex}]`, true, true);

      // Set error if the field is empty
      // if (value === "") {
      //   formik.setFieldError(`tfa_code[${currentIndex}]`, "Digit is required");
      // } else {
      //   // Clear the error if the field is not empty
      //   formik.setFieldError(`tfa_code[${currentIndex}]`, undefined);
      // }

      // Add this block to update the isFourDigits state variable
      if (value !== "" && currentIndex === 3) {
        setIsFourDigits(true);
      } else if (value === "" && currentIndex === 3) {
        setIsFourDigits(false);
      }

      if (value === "" && currentIndex > 0) {
        document.getElementById(`otp_digit_${currentIndex - 1}`).focus();
      } else if (
        currentIndex < otpValues.length - 1 &&
        event.nativeEvent.inputType !== "deleteContentBackward"
      ) {
        document.getElementById(`otp_digit_${currentIndex + 1}`).focus();
      }
    }
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "validatedOtp") {
        console.log({ data });
        if (data?.data.accessToken) {
          const userData = data?.data || [];
          handleSignedInUser(userData);
        }
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleOtpVerification = async (event) => {
    event?.preventDefault();

    const combinedOtp = values.tfa_code.join("");

    await makeApiRequest({
      uri: `/user-management/login/validate/otp`,
      dataName: `validatedOtp`,
      method: `post`,
      data: {
        code: combinedOtp,
        userId: userId,
      },
    });
  };

  const handleOtpRegeneration = async (event) => {
    event?.preventDefault();

    await makeApiRequest({
      uri: `/user-management/login/regenerate/otp`,
      dataName: `regeneratedOtp`,
      method: `post`,
      data: {
        userId: userId,
      },
    });
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <form noValidate onSubmit={handleOtpVerification}>
        <Box>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              // color: "#2CB34A",
            }}
          >
            Verify Account
          </Typography>
          <Typography
            sx={{
              mb: 4,
              mt: 1,
              fontSize: 12,
              width: "250px",
            }}
          >
            Please enter the 4 digits verification code sent to{" "}
            <span>{msisdn}</span>
          </Typography>

          <Stack
            direction="row"
            spacing={3}
            sx={{ justifyContent: "center", mb: 3 }}
          >
            {[0, 1, 2, 3].map((index) => (
              <TextField
                key={index}
                value={values.tfa_code[index] || ""}
                name={`tfa_code[${index}]`}
                size="small"
                style={otpInputStyle}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: formik.errors[`tfa_code[${index}]`]
                        ? "1px red solid"
                        : "1px #515151 solid",
                      borderRadius: "8px",
                    },
                  },
                  mb: 1,
                }}
                onChange={(e) => {
                  handleChange(e);
                  handleInput(e, index);
                }}
                onBlur={handleBlur}
                fullWidth
                inputProps={{
                  maxLength: 1,
                  "data-testid": `otp_digit_${index}`,
                }}
                id={`otp_digit_${index}`}
                error={
                  touched[`tfa_code[${index}]`] &&
                  Boolean(errors[`tfa_code[${index}]`])
                }
                helperText={
                  touched[`tfa_code[${index}]`] && errors[`tfa_code[${index}]`]
                }
              />
            ))}
          </Stack>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={!isFourDigits ? otpDisabledButton : otpPrimaryButton}
            disableElevation
            type="submit"
            disabled={!isFourDigits}
          >
            Verify & Proceed
          </Button>
        </Box>

        <Box sx={{ mt: 4 }}>
          <span>
            <Typography sx={{ fontSize: 14 }}>
              Didn't receive OTP?
              <Link
                sx={{
                  color: "#2CB34A",
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: 14,
                  ml: 1,
                }}
                onClick={() => handleOtpRegeneration()}
              >
                Resend Code
              </Link>
            </Typography>
          </span>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Link
            sx={{
              color: "#2CB34A",
              cursor: "pointer",
              ml: 1,
            }}
            onClick={() => toggleOtpPage()}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
              Go Back to Login
            </Typography>
          </Link>
        </Box>
      </form>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
