import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import customLocalStorage from "../services/local-storage";
import {
  Box,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import UserCircleIcon from "../assets/icons-js-files/user-circle-icon";
import PhoneIcon from "../assets/icons-js-files/phone-icon";
import RolesIcon from "../assets/icons-js-files/roles-icon";
import LogoutIcon from "../assets/icons-js-files/logout-icon";
import MailIcon from "../assets/icons-js-files/mail-icon";

const StyledMenu = styled(Menu)(() => ({
  paper: {
    border: "1px solid #d3d4d5",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:focus": {
    backgroundColor: theme.palette.common.white,
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      color: "#2CB34A",
    },
  },
}));

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const user = customLocalStorage.decrypt("brandPortalUser");

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mr: 6 }}>
        <IconButton
          size="medium"
          edge="end"
          aria-label="account of current user"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar>
            {user?.firstname?.charAt(0)?.toUpperCase()}
            {user?.lastname?.charAt(0)?.toUpperCase()}
          </Avatar>
        </IconButton>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          <Typography sx={{ color: "#000", ml: 2, fontSize: 15 }}>
            {user?.firstname}
          </Typography>
          <ExpandMore style={{ color: "000", marginTop: 2 }} />
        </Stack>
      </Stack>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <UserCircleIcon
              stroke="#000"
              style={{ width: "14px", height: "14px", opacity: 0.8 }}
            />
          </ListItemIcon>

          <ListItemText primary={`${user?.firstname} ${user?.lastname}`} />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon>
            <MailIcon
              stroke="#000"
              style={{ width: "14px", height: "14px", opacity: 0.8 }}
            />
          </ListItemIcon>

          <ListItemText primary={`${user?.email}`} />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon>
            <PhoneIcon
              stroke="#000"
              style={{ width: "14px", height: "14px", opacity: 0.8 }}
            />
          </ListItemIcon>

          <ListItemText primary={`${user?.msisdn}`} />
        </StyledMenuItem>

        <StyledMenuItem>
          <ListItemIcon>
            <RolesIcon
              stroke="#000"
              style={{ width: "14px", height: "14px", opacity: 0.8 }}
            />
          </ListItemIcon>

          <ListItemText
            primary={(user ? user.roleDisplayName : "").join(", ")}
          />
        </StyledMenuItem>

        <StyledMenuItem onClick={handleLogOut} data-testid="logout">
          <ListItemIcon>
            <LogoutIcon
              stroke="#ff2a58"
              style={{ width: "14px", height: "14px", opacity: 0.8 }}
            />
          </ListItemIcon>
          <ListItemText primary="Logout" sx={{ color: "#ff2a58" }} />
        </StyledMenuItem>
      </StyledMenu>
    </Box>
  );
};

export default Profile;
