import React, { useState } from "react";
import {
  Button,
  Link,
  TextField,
  Box,
  Typography,
  FormControl,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  loginDisabledButton,
  loginPrimaryButton,
  primaryButton,
} from "../styles/common-styles";
import apiRequest from "../services/api-request";
import { useFormik } from "formik";
import * as yup from "yup";
import SnackbarComponent from "../shared-components/snackbar";
import PropTypes from "prop-types";
import SuccessIcon from "../assets/icons-js-files/success-icon";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("First Name is required")
    .max(50, "First Name must be at most 50 characters")
    .matches(
      /^[a-zA-Z\s'-]+$/,
      "First Name can only contain letters, spaces, -, and '"
    ),
  lastName: yup
    .string()
    .required("Last Name is required")
    .max(50, "First Name must be at most 50 characters")
    .matches(
      /^[a-zA-Z\s'-]+$/,
      "First Name can only contain letters, spaces, -, and '"
    ),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  msisdn: yup
    .string()
    .required("Phone Number is required")
    .matches(/^\d+$/, "Phone Number can only contain numbers"),
});

SelfRegistration.propTypes = {
  toggleRegistrationPage: PropTypes.func.isRequired,
};

export default function SelfRegistration(props) {
  const { toggleRegistrationPage } = props;

  const initialState = {
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      msisdn: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleRegistration();
    },
  });

  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  const handleApiData = (params) => {
    const { data, dataName, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "registeredUser") {
        setModalOpen(true);
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleRegistration = async () => {
    await makeApiRequest({
      uri: `/user-management/login/register`,
      dataName: `registeredUser`,
      method: `post`,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        msisdn: values.msisdn,
      },
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <form noValidate onSubmit={handleRegistration}>
        <Box>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: "#2CB34A",
            }}
          >
            Get Started! Register to start submitting briefs
          </Typography>
        </Box>

        <Typography
          sx={{
            mb: 3,
            mt: 1,
            fontSize: 13,
            fontFamily: "Futura",
          }}
        >
          Please fill in all details
        </Typography>

        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <FormControl fullWidth>
            <TextField
              value={values.firstName}
              label="First Name"
              name="firstName"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              required
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              value={values.lastName}
              label="Last Name"
              name="lastName"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              required
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </FormControl>
        </Stack>

        <Box>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              value={values.email}
              label="Email Address"
              name="email"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              required
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              value={values.msisdn}
              label="Phone Number"
              name="msisdn"
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              required
              error={touched.msisdn && Boolean(errors.msisdn)}
              helperText={touched.msisdn && errors.msisdn}
            />
          </FormControl>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            style={!isValid ? loginDisabledButton : loginPrimaryButton}
            disableElevation
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Submit
          </Button>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Link
            data-testid="go-back"
            sx={{
              color: "#2CB34A",
              cursor: "pointer",
              ml: 1,
            }}
            onClick={() => toggleRegistrationPage()}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
              Go Back to Login
            </Typography>
          </Link>
        </Box>
      </form>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        sx={{ textAlign: "center" }}
      >
        <IconButton sx={{ pt: 3 }}>
          <SuccessIcon stroke="#2CB34A" />
        </IconButton>
        <DialogTitle>
          <Typography
            sx={{ color: "#2CB34A", fontWeight: "bold", fontSize: 18 }}
          >
            Registration was successful
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            You have successfully registered as a brief owner. An email has been
            shared with you. Please click on the email to activate your account
          </Typography>
        </DialogContent>
        <DialogActions sx={{ m: "0 auto", pb: 4 }}>
          <Button style={primaryButton} onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
