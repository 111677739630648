import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import SnackbarComponent from "../../shared-components/snackbar";
import BriefDetails from "../../shared-components/brief-details";
import PropTypes from "prop-types";
import ArrowBackIcon from "../../assets/icons-js-files/arrow-back-icon";
import apiRequest from "../../services/api-request";

MarketResearchBriefOwnerDetailsPage.propTypes = {
  togglePage: PropTypes.func.isRequired,
  selectedBrief: PropTypes.object,
  navigatedFrom: PropTypes.string,
};

MarketResearchBriefOwnerDetailsPage.defaultProps = {
  selectedBrief: {},
  navigatedFrom: "",
};

export default function MarketResearchBriefOwnerDetailsPage(props) {
  const { selectedBrief, togglePage } = props;
  const { briefId } = selectedBrief;

  const initialState = {
    currentPage: "mrBriefDetailsPage",
    currentSelection: "",
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [fullBrief, setFullBrief] = useState({});

  const { loading, snackBarSeverity } = state;

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "fullBrief") {
        setFullBrief(data?.data);
      }
      // if (dataName === "brief") {
      //   setState((prevState) => ({
      //     ...prevState,
      //     [dataName]: data?.data || [],
      //   }));
      // }

      snackBarHandle(true, data?.message, severity);
    }
  };

  const handleGetFullBrief = async () => {
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/${briefId}`,
      dataName: `fullBrief`,
      method: `get`,
      data: "",
    });
  };

  useEffect(() => {
    (async () => {
      await handleGetFullBrief();
    })();
  }, []);

  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_COMPLETE":
        return {
          text: "Completed",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px 15px 5px 15px",
        };
      case "BRIEF_IN_DRAFT":
        return {
          text: "Draft",
          backgroundColor: "#E5E5E5",
          textColor: "#464646",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD":
        return {
          text: "Research Lead Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px",
        };
      case "BRIEF_RESEARCH_PLANNER_REPORT":
        return {
          text: "Study Underway",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD_REPORT":
        return {
          text: "Report Under Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_RESEARCH_PLANNER_REVIEW":
        return {
          text: "Research Planner Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD":
        return {
          text: "Changes Requested",
          backgroundColor: "rgba(236, 186, 9, 0.15)",
          textColor: "#ECBA09",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_EXTERNAL_REVIEW":
        return {
          text: "Study Underway",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CANCELLED":
        return {
          text: "Brief Cancelled",
          backgroundColor: "rgba(239, 55, 62, 0.15)",
          textColor: "#EF373E",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_REJECTED":
        return {
          text: "Brief Rejected",
          backgroundColor: "rgba(239, 55, 62, 0.15)",
          textColor: "#EF373E",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_STUDY_COMPLETED":
        return {
          text: "Study complete",
          backgroundColor: "rgba(44, 179, 74, 0.15)",
          textColor: "#2CB34A",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      default:
        return {
          text: "Unknown Status",
        };
    }
  };

  const statusDisplay = getStatusDisplay(selectedBrief?.briefStatus);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Grid container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() =>
            togglePage({
              currentPage: props.navigatedFrom,
              selectedBrief,
            })
          }
        >
          <ArrowBackIcon
            stroke="#2CB34A"
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </IconButton>

        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          Brief #{selectedBrief?.briefId} Details
        </Typography>
      </Grid>

      <BriefDetails selectedBrief={fullBrief} statusDisplay={statusDisplay} />

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
