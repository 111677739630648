import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import { cancelButton, deleteButton } from "../styles/common-styles";
import PropTypes from "prop-types";

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  content,
  cancelButtonText,
  confirmButtonText,
  handleConfirm,
  loading
}) => {
  return (
    <>
      <Backdrop
        sx={{ zIndex: 1000, color: "#fff" }}
        open={loading}
        data-testid="backdrop"
      >
        <CircularProgress color="success" data-testid="circular-progress" />
      </Backdrop>
      <Dialog
        sx={{ textAlign: "center" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "#EF373E", pt: 5 }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions sx={{ pb: 5, justifyContent: "center" }}>
          <Button onClick={handleClose} style={cancelButton}>
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm} style={deleteButton} autoFocus>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  open: false,
  title: "",
  content: "",
  cancelButtonText: "",
  confirmButtonText: "",
};

export default ConfirmationDialog;
