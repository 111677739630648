import React, { useState } from "react";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import EmptyTaskPage from "../../shared-components/empty-task-page";
import MUIDataTable from "mui-datatables";
import { getMuiDatatableTheme } from "../../styles/material-ui-theme";
import { ThemeProvider } from "@mui/material/styles";
import MarketResearchLeadAllTasksDetailsPage from "./mr-lead-all-tasks-details";
import PropTypes from "prop-types";
import ExpectedDeliveryDateDisplay from "../datatable-components/expected-delivery-date-display";
import DepartmentDisplay from "../datatable-components/department-display";
import DateUpdatedDisplay from "../datatable-components/date-updated-display";
import ViewIcon from "../../assets/icons-js-files/view-icon";

MarketResearchLeadAllCompletedTasksPage.propTypes = {
  completedTasks: PropTypes.array,
};

MarketResearchLeadAllCompletedTasksPage.defaultProps = {
  completedTasks: [],
};

export default function MarketResearchLeadAllCompletedTasksPage(props) {
  const { completedTasks } = props;

  const initialState = {
    currentPage: "completedTaskListPage",
    selectedBrief: {},
  };

  const [state, setState] = useState(initialState);

  const { currentPage, selectedBrief } = state;

  const togglePage = (params) => {
    const { currentPage, selectedBrief, roles, navigatedFrom } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      selectedBrief,
      roles,
      navigatedFrom,
    }));
  };

  const briefColumns = [
    {
      name: "briefTitle",
      label: "Brief Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "briefType",
      label: "Brief Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "coeDepartment",
      label: "Department",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <DepartmentDisplay departmentName={value?.departmentName} />
        ),
      },
    },
    {
      name: "expectedDeliveryDate",
      label: "Expected Delivery Date",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <ExpectedDeliveryDateDisplay expectedDeliveryDate={value} />
        ),
      },
    },
    {
      name: "updatedAt",
      label: "Last Updated",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateUpdatedDisplay dateUpdated={value} />,
      },
    },
    {
      name: "briefStatus",
      label: "Status",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (status) => {
          const getStatusDisplay = (status) => {
            switch (status) {
              case "BRIEF_STUDY_COMPLETED":
                return {
                  text: "Study complete",
                  backgroundColor: "rgba(44, 179, 74, 0.15)",
                  textColor: "#2CB34A",
                  fontSize: "12px",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  width: "auto",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                };
              default:
                return {
                  text: "Unknown Status",
                };
            }
          };

          const {
            text,
            backgroundColor,
            textColor,
            fontSize,
            fontWeight,
            borderRadius,
            width,
            padding,
          } = getStatusDisplay(status);

          return (
            <Button
              status={status}
              sx={{
                backgroundColor,
                color: textColor,
                fontSize,
                fontWeight,
                borderRadius,
                width,
                fontFamily: "Futura",
                textTransform: "none",
                padding,
              }}
            >
              {text}
            </Button>
          );
        },
      },
    },
    {
      name: "briefId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const selectedBrief = completedTasks?.filter(
            (brief) => brief?.briefId == value
          )[0];

          return (
            <Tooltip title="View Brief">
              <IconButton
                onClick={() =>
                  togglePage({
                    currentPage: "mrAllTaskDetailsPage",
                    selectedBrief,
                  })
                }
              >
                <ViewIcon
                  stroke="#121212"
                  style={{ marginRight: "10px", width: "18px" }}
                />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    download: false,
  };

  return (
    <Box sx={{ mb: 4, mt: 2, ml: 3 }} data-testid="completed-tasks-content">
      <Typography sx={{ mb: 3, fontWeight: "bold" }}>
        List of all Completed Tasks
      </Typography>
      {currentPage === "completedTaskListPage" &&
        completedTasks?.length > 0 && (
          <ThemeProvider theme={getMuiDatatableTheme()}>
            <MUIDataTable
              title=""
              data={completedTasks}
              columns={briefColumns}
              options={options}
            />
          </ThemeProvider>
        )}

      {currentPage === "completedTaskListPage" &&
        completedTasks?.length < 1 && (
          <Box sx={{ ml: -3 }}>
            <EmptyTaskPage emptyPageDescription="There are no completed tasks at this time. Try again later" />
          </Box>
        )}

      {currentPage === "mrAllTaskDetailsPage" && (
        <MarketResearchLeadAllTasksDetailsPage
          selectedBrief={selectedBrief}
          togglePage={togglePage}
          navigatedFrom="completedTaskListPage"
        />
      )}
    </Box>
  );
}
