import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import PropTypes from "prop-types";
import apiRequest from "../../services/api-request";
import customLocalStorage from "../../services/local-storage";
import SnackbarComponent from "../../shared-components/snackbar";
import MarketResearchLeadAllCompletedTasksPage from "../../market-research/market-research-lead/market-research-lead-all-completed-tasks";
import MarketResearchLeadAllPendingTasksPage from "../../market-research/market-research-lead/market-research-lead-all-pending-tasks";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TaskManagementPage() {
  const initialState = {
    currentPage: "superAdminTaskManagementPage",
    loading: false,
    pendingTasks: [],
    completedTasks: [],
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [value, setValue] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [roles, setRoles] = useState([]);

  const user = customLocalStorage.decrypt("brandPortalUser") || {};

  //   const { brandCoeId } = customLocalStorage.decrypt("brandPortalUser") || {};

  const { loading, pendingTasks, completedTasks, snackBarSeverity } = state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "pendingTasks" || dataName === "completedTasks") {
        console.log({ data });

        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }
      if (dataName === "roles") {
        setRoles(data?.data);
        // console.log({ roles });
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };


  const handleGetAllPendingTasks = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/pending`,
      dataName: `pendingTasks`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllCompletedTasks = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/completed`,
      dataName: `completedTasks`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllRoles = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/user-management/roles`,
      dataName: `roles`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllPendingTasks();
      await handleGetAllCompletedTasks();
      await handleGetAllRoles();
    })();
  }, []);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider>
      <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, mt: 8 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Box sx={{ borderColor: "divider", mb: 2, ml: 3 }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <Typography
              variant="h5"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              All Tasks
            </Typography>
          </Stack>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider", ml: 2 }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs">
            <Tab
              label="Pending Tasks"
              {...a11yProps(0)}
              data-testid="pending-tasks-tab"
            />
            <Tab
              label="Completed Tasks"
              {...a11yProps(1)}
              data-testid="completed-tasks-tab"
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0} data-testid="pending-tasks-tab-panel">
          <Box sx={{ ml: -2 }}>
            <MarketResearchLeadAllPendingTasksPage
              pendingTasks={pendingTasks}
              roles={roles}
              user={user}
              //   brandCoeId={brandCoeId}
            />
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          data-testid="completed-tasks-tab-panel"
        >
          <MarketResearchLeadAllCompletedTasksPage
            completedTasks={completedTasks}
          />
        </TabPanel>
      </Box>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </ThemeProvider>
  );
}
