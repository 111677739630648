import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import EmptyBriefListPage from "../shared-components/brief-owner/empty-brief-list-page";

const buttonText = "Create Event Brief";
const emptyPageTitle = "Events management is coming soon to Brand Content Hub";
const emptyPageDescription =
  "Click the link below to create and manage your event briefs";

export default function EmptyEventsPage() {
  return (
    <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, mt: 8 }}>
      <Box sx={{ borderColor: "divider", ml: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex" }}
        >
          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Events Management
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ mb: 4, mt: 5, ml: 3 }}>
        <Box sx={{ ml: -3 }}>
          <EmptyBriefListPage
            buttonText={buttonText}
            emptyPageTitle={emptyPageTitle}
            emptyPageDescription={emptyPageDescription}
            onClick={() => {
              const newWindow = window.open(
                "https://events-management.it-automation.apps.hqocp.safaricom.net/",
                "_blank",
                "noopener,noreferrer"
              );
              if (newWindow) newWindow.opener = null; // Ensures the new window does not have a reference to the opening window
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
