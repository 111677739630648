import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { inputStyle, helperTextStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  setupsTableHeaderStyle,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { useFormik } from "formik";
import * as yup from "yup";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = yup.object({
  ageBracketName: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY, "Maximum 50 characters")
    .required("Age bracket cannot be empty"),
  ageBracketShortCode: yup
    .string()
    .max(process.env.REACT_APP_MAX_INPUT_LENGTH_TEN, "Maximum 10 characters")
    .required("Age bracket short code cannot be empty"),
  ageBracketDescription: yup
    .string()
    .max(
      process.env.REACT_APP_MAX_INPUT_LENGTH_FIVE_HUNDRED,
      "Maximum 500 characters"
    )
    .required("Age Bracket description is required"),
});

export default function AgeBracketsSetup() {
  const initialState = {
    ageBrackets: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [isUpdatingAgeBracket, setIsUpdatingAgeBracket] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { ageBrackets, loading, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {
      ageBracketName: "",
      ageBracketShortCode: "",
      ageBracketDescription: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleAddAgeBracket(values);
      resetForm();
    },
  });

  const removeDuplicateAgeBracketsFromArrayOfObjects = (objArray) => {
    if (objArray.length < 1) return [];

    return [
      ...new Map(objArray.map((item) => [item.ageBracketName, item])).values(),
    ];
  };

  const handleAddAgeBracket = (values) => {
    let updatedAgeBrackets = [...ageBrackets];
    if (isUpdatingAgeBracket) {
      const indexOfAgeBracket = updatedAgeBrackets.findIndex(
        (ageBracket) => ageBracket.id === values.id
      );
      updatedAgeBrackets[indexOfAgeBracket] = values;
      setIsUpdatingAgeBracket(false);
    } else {
      updatedAgeBrackets.push(values);
    }

    updatedAgeBrackets =
      removeDuplicateAgeBracketsFromArrayOfObjects(updatedAgeBrackets);

    setState((prevState) => ({
      ...prevState,
      ageBrackets: updatedAgeBrackets,
    }));

    handleSubmitAgeBracket(values);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitAgeBracket = async (ageBracket) => {
    const action = ageBracket.id ? "update" : "create";
    const uri = `/market-research/setup/age-bracket`;
    const method = action === "update" ? "put" : "post";
    await makeApiRequest({
      uri,
      dataName: "addedAgeBrackets",
      method,
      data: ageBracket,
    });
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "success") {
      if (dataName === "ageBrackets") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data ?? [],
        }));
      }

      if (dataName === "addedAgeBrackets") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const handleSelectAgeBracket = (ageBracket) => {
    setIsUpdatingAgeBracket(true);
    formik.setValues(ageBracket);
  };

  const handleDeleteAgeBracket = async (id) => {
    await makeApiRequest({
      uri: `/market-research/setup/age-bracket/${id}`,
      dataName: "deletedAgeBrackets",
      method: "delete",
      data: {},
    });

    const updatedAgeBrackets = ageBrackets.filter(
      (ageBracket) => ageBracket.id !== id
    );

    setState((prevState) => ({
      ...prevState,
      ageBrackets: updatedAgeBrackets,
    }));
  };

  const handleGetAllAgeBrackets = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/setup/age-bracket`,
      dataName: `ageBrackets`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllAgeBrackets();
    })();
  }, []);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Add/Edit Age Brackets
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Age Bracket"
              name="ageBracketName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ageBracketName}
              fullWidth
              required
              error={
                formik.touched.ageBracketName &&
                Boolean(formik.errors.ageBracketName)
              }
            />
            {formik.touched.ageBracketName && formik.errors.ageBracketName && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.ageBracketName}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Age Bracket Short Code"
              name="ageBracketShortCode"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ageBracketShortCode}
              fullWidth
              required
              error={
                formik.touched.ageBracketShortCode &&
                Boolean(formik.errors.ageBracketShortCode)
              }
            />
            {formik.touched.ageBracketShortCode &&
              formik.errors.ageBracketShortCode && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.ageBracketShortCode}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Age Bracket Description"
              name="ageBracketDescription"
              type="text"
              size="small"
              multiline
              rows={3}
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ageBracketDescription}
              fullWidth
              required
              error={
                formik.touched.ageBracketDescription &&
                Boolean(formik.errors.ageBracketDescription)
              }
            />
            {formik.touched.ageBracketDescription &&
              formik.errors.ageBracketDescription && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.ageBracketDescription}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Box sx={{ mt: 2 }}>
          <Button
            type="submit"
            style={
              formik.isValid && formik.dirty ? primaryButton : disabledButton
            }
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isUpdatingAgeBracket ? "UPDATE" : "ADD"}
          </Button>
        </Box>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 4 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Age Brackets
        </Typography>

        <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={setupsTableHeaderStyle}>
                  Age Bracket
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Age Bracket Short Code
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Age Bracket Description
                </TableCell>

                <TableCell style={setupsTableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ageBrackets?.map((ageBracket) => (
                <TableRow
                  key={ageBracket}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {ageBracket?.ageBracketName}
                  </TableCell>

                  <TableCell>{ageBracket?.ageBracketShortCode}</TableCell>
                  <TableCell>{ageBracket?.ageBracketDescription}</TableCell>

                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleSelectAgeBracket(ageBracket);
                      }}
                    >
                      <EditIcon stroke="#121212" />{" "}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        // console.log({ ageBrackets });
                        handleDeleteAgeBracket(ageBracket?.id);
                      }}
                    >
                      <DeleteIcon stroke="#ff2a58" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
