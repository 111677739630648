import React from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";

const DateCreatedDisplay = ({ dateCreated }) => (
  <Stack direction="row" spacing={3}>
    <Typography sx={{ fontSize: 15, fontFamily: "Futura" }}>
      {moment(dateCreated).format("D/MM/yyyy")}
    </Typography>
  </Stack>
);

DateCreatedDisplay.propTypes = {
  dateCreated: PropTypes.string,
};

DateCreatedDisplay.defaultProps = {
  dateCreated: "",
};

export default DateCreatedDisplay;
