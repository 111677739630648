import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const BriefOwnerBriefStatusDisplay = ({ status }) => {
  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_IN_DRAFT":
        return {
          text: "Draft",
          backgroundColor: "#E5E5E5",
          textColor: "#464646",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px",
        };
      case "BRIEF_COMPLETE":
        return {
          text: "Submit for Approval",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD":
        return {
          text: "Research Lead Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD":
        return {
          text: "Changes Requested by Research Lead",
          backgroundColor: "rgba(236, 186, 9, 0.15)",
          textColor: "#ECBA09",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_RESEARCH_PLANNER_REVIEW":
        return {
          text: "Research Planner Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER":
        return {
          text: "Changes Requested by Research Planner",
          backgroundColor: "rgba(236, 186, 9, 0.15)",
          textColor: "#ECBA09",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_EXTERNAL_REVIEW":
        return {
          text: "Study Underway",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_RESEARCH_PLANNER_REPORT":
        return {
          text: "Study Underway",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD_REPORT":
        return {
          text: "Report Under Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_REJECTED":
        return {
          text: "Brief Rejected",
          backgroundColor: "rgba(239, 55, 62, 0.15)",
          textColor: "#EF373E",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CANCELLED":
        return {
          text: "Brief Cancelled",
          backgroundColor: "rgba(239, 55, 62, 0.15)",
          textColor: "#EF373E",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_STUDY_COMPLETED":
        return {
          text: "Study complete",
          backgroundColor: "rgba(44, 179, 74, 0.15)",
          textColor: "#2CB34A",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      default:
        return {
          text: "Unknown Status",
        };
    }
  };

  const {
    text,
    backgroundColor,
    textColor,
    fontSize,
    fontWeight,
    borderRadius,
    padding,
  } = getStatusDisplay(status);

  return (
    <Button
      sx={{
        backgroundColor,
        color: textColor,
        fontSize,
        fontWeight,
        borderRadius,
        fontFamily: "Futura",
        textTransform: "none",
        padding,
      }}
    >
      {text}
    </Button>
  );
};

BriefOwnerBriefStatusDisplay.propTypes = {
  status: PropTypes.string,
};

BriefOwnerBriefStatusDisplay.defaultProps = {
  status: "",
};

export default BriefOwnerBriefStatusDisplay;
