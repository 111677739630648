import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import customLocalStorage from "../services/local-storage";
import { logoStyle, drawerWidth } from "../styles/material-ui-theme";
import Profile from "./profile.js";
import MoreIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

TopNavBar.propTypes = {
  isSideDrawerOpen: PropTypes.bool,
  isSignedIn: PropTypes.bool,
};

TopNavBar.defaultProps = {
  isSideDrawerOpen: false,
  isSignedIn: false,
};

export default function TopNavBar(props) {
  const classes = useStyles();

  const { isSideDrawerOpen, isSignedIn } = props;

  const user = customLocalStorage.decrypt("brandPortalUser") || {};

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isSideDrawerOpen,
      })}
      style={{ backgroundColor: "#fff" }}
    >
      {isSignedIn && (
        <Toolbar>
          <img
            src="/project-images/saf-logo-green.png"
            style={logoStyle}
            alt="logo"
          />

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {user && isSignedIn && <Profile />}
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      )}
    </AppBar>
  );
}
