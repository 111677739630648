import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Chip,
  MenuItem,
  OutlinedInput,
  Divider,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  disabledButton,
  primaryButton,
  secondaryButton,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import AutocompleteField from "../../../shared-components/autocomplete";
import PropTypes from "prop-types";
import customLocalStorage from "../../../services/local-storage";

const validationSchema = yup.object({
  briefTitle: yup
    .string()
    .required("Brief title is required")
    .max(50, "Brief title must be at most 50 characters")
    .matches(
      /^[a-zA-Z\s'-]+$/,
      "Brief title can only contain letters, spaces, -, and '"
    ),
  expectedDeliveryDate: yup
    .date()
    .required("Expected Delivery Date is required"),
  // departmentId: yup.string().required("Department/Division is required"),
  targetSegments: yup
    .array()
    .min(1, "Please select at least one target segment"),
  regions: yup.array().min(1, "Please select at least one region"),
  ageBrackets: yup.array().min(1, "Please select at least one age bracket"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

CustomisedBriefOverview.propTypes = {
  updateSelectedBrief: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  selectedBrief: PropTypes.object,
  targetSegments: PropTypes.array,
  regions: PropTypes.array,
  ageBrackets: PropTypes.array,
  departments: PropTypes.array,
  briefId: PropTypes.string,
};

CustomisedBriefOverview.defaultProps = {
  selectedBrief: {},
  targetSegments: [],
  regions: [],
  ageBrackets: [],
  departments: [],
  briefId: "",
};

export default function CustomisedBriefOverview(props) {
  const {
    briefId,
    selectedBrief,
    targetSegments,
    regions,
    ageBrackets,
    departments,
    handleBack,
    handleNext,
  } = props;

  const initialState = {
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [briefTitle, setBriefTitle] = useState(selectedBrief?.briefTitle || "");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(
    selectedBrief?.expectedDeliveryDate || ""
  );
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);

  const { snackBarSeverity, loading } = state;

  const [selectedTargetSegmentIds, setSelectedTargetSegmentIds] = useState(
    (selectedBrief?.targetSegments || []).map(
      (targetSegmentOption) => targetSegmentOption.id
    )
  );

  const [selectedRegionIds, setSelectedRegionIds] = useState(
    (selectedBrief?.regions || []).map((regionOption) => regionOption.id)
  );

  const [selectedAgeBracketIds, setSelectedAgeBracketIds] = useState(
    (selectedBrief?.ageBrackets || []).map(
      (ageBracketOption) => ageBracketOption.id
    )
  );

  const [newlySelectedTargetSegmentIds, setNewlySelectedTargetSegmentIds] =
    useState([]);
  const [newlySelectedRegionIds, setNewlySelectedRegionIds] = useState([]);
  const [newlySelectedAgeBracketIds, setNewlySelectedAgeBracketIds] = useState(
    []
  );

  const currentDate = new Date().toISOString().split("T")[0];

  const formik = useFormik({
    initialValues: {
      briefTitle,
      expectedDeliveryDate,
      departmentId: selectedBrief?.coeDepartment?.id || "",
      targetSegments: selectedTargetSegmentIds,
      regions: selectedRegionIds,
      ageBrackets: selectedAgeBracketIds,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdateBrief(values);
    },
  });

  const { values, errors, touched, isValid, handleChange, handleBlur } = formik;

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    handleChange(e);

    if (name === "briefTitle") {
      setBriefTitle(value);
    } else if (name === "expectedDeliveryDate") {
      setExpectedDeliveryDate(value);
    }
  };

  const handleSelectionChange = (event) => {
    const { name, value } = event.target; 
    if(name === "targetSegments") setBreiefOverViewFormInputPlaceholders({...breiefOverViewFormInputPlaceholders, targetSegments: []});
    if(name === "regions") setBreiefOverViewFormInputPlaceholders({...breiefOverViewFormInputPlaceholders, regions: []});
    if(name === "ageBrackets") setBreiefOverViewFormInputPlaceholders({...breiefOverViewFormInputPlaceholders, ageBrackets: []});
    

    handleChange(event);

    switch (name) {
      case "targetSegments":
        const selectedTargetSegmentIds = Array.isArray(value)
          ? value.map((id) => id)
          : [value];

        setNewlySelectedTargetSegmentIds((prevSelections) => [
          ...prevSelections,
          ...selectedTargetSegmentIds,
        ]);

        setSelectedTargetSegmentIds([...selectedTargetSegmentIds]);

        break;

      case "regions":
        const selectedRegionIds = Array.isArray(value)
          ? value.map((id) => id)
          : [value];

        setNewlySelectedRegionIds((prevSelections) => [
          ...prevSelections,
          ...selectedRegionIds,
        ]);
        setSelectedRegionIds([...selectedRegionIds]);
        break;
      case "ageBrackets":
        const selectedAgeBracketIds = Array.isArray(value)
          ? value.map((id) => id)
          : [value];

        setNewlySelectedAgeBracketIds((prevSelections) => [
          ...prevSelections,
          ...selectedAgeBracketIds,
        ]);
        setSelectedAgeBracketIds([...selectedAgeBracketIds]);
        break;
      default:
        break;
    }
  };

  const getSelectedOptions = (
    allOptions,
    selectedBriefOptions = [],
    newlySelectedIds = {}
  ) => {
    const selectedOptions = {};

    Object.keys(newlySelectedIds).forEach((category) => {
      const selectedIdsFromBrief =
        selectedBriefOptions[category]?.map((briefOption) => briefOption.id) ||
        [];

      const allSelectedIds = [
        ...selectedIdsFromBrief,
        ...newlySelectedIds[category],
      ];

      selectedOptions[category] = allOptions
        ?.filter((option) => allSelectedIds.includes(option.id))
        .map((option) => option.id);
    });

    return selectedOptions;
  };

  const selectedTargetSegmentOptions = getSelectedOptions(
    targetSegments,
    {
      targetSegments: selectedBrief?.targetSegments || [],
    },
    {
      targetSegments: newlySelectedTargetSegmentIds,
    }
  );

  const selectedRegionOptions = getSelectedOptions(
    regions,
    {
      regions: selectedBrief?.regions || [],
    },
    {
      regions: newlySelectedRegionIds,
    }
  );

  const selectedAgeBracketOptions = getSelectedOptions(
    ageBrackets,
    {
      ageBrackets: selectedBrief?.ageBrackets || [],
    },
    {
      ageBrackets: newlySelectedAgeBracketIds,
    }
  );

  const handleDeleteTargetSegment = (itemToDelete) => () => {
    // Check if the item belongs to selectedBrief.targetSegments
    const isInSelectedBrief = selectedBrief?.targetSegments.some(
      (segment) => segment.id === itemToDelete
    );

    // If it's in selectedBrief, update it to remove the item
    if (isInSelectedBrief) {
      const updatedTargetSegments = selectedBrief.targetSegments.filter(
        (segment) => segment.id !== itemToDelete
      );

      // Update selectedBrief using the function passed as a prop
      props.updateSelectedBrief({
        ...selectedBrief,
        targetSegments: updatedTargetSegments,
      });
    }
    // Update selectedTargetSegmentIds
    setSelectedTargetSegmentIds((prevSelectedTargetSegments) =>
      prevSelectedTargetSegments.filter((id) => id !== itemToDelete)
    );

    // Update newlySelectedTargetSegmentIds
    setNewlySelectedTargetSegmentIds((prevNewlySelected) =>
      prevNewlySelected.filter((id) => id !== itemToDelete)
    );
  };

  const handleDeleteRegion = (itemToDelete) => () => {
    const isInSelectedBrief = selectedBrief?.regions.some(
      (region) => region.id === itemToDelete
    );

    if (isInSelectedBrief) {
      const updatedRegions = selectedBrief.regions.filter(
        (region) => region.id !== itemToDelete
      );

      props.updateSelectedBrief({
        ...selectedBrief,
        regions: updatedRegions,
      });
    }
    setSelectedRegionIds((prevSelectedRegions) =>
      prevSelectedRegions.filter((id) => id !== itemToDelete)
    );

    setNewlySelectedRegionIds((prevNewlySelected) =>
      prevNewlySelected.filter((id) => id !== itemToDelete)
    );
  };

  const handleDeleteAgeBracket = (itemToDelete) => () => {
    const isInSelectedBrief = selectedBrief?.ageBrackets.some(
      (ageBracket) => ageBracket.id === itemToDelete
    );

    if (isInSelectedBrief) {
      const updatedAgeBrackets = selectedBrief.ageBrackets.filter(
        (ageBracket) => ageBracket.id !== itemToDelete
      );

      props.updateSelectedBrief({
        ...selectedBrief,
        ageBrackets: updatedAgeBrackets,
      });
    }

    setSelectedAgeBracketIds((prevSelectedAgeBrackets) =>
      prevSelectedAgeBrackets.filter((id) => id !== itemToDelete)
    );

    setNewlySelectedAgeBracketIds((prevNewlySelected) =>
      prevNewlySelected.filter((id) => id !== itemToDelete)
    );
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "updatedBrief") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));

        if (data?.data) {
          handleNext();
        }
      }
      snackBarHandle(true, data?.message, severity);
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  // ##################### Handles back/continue Navigation state ##########  
  const [breiefOverViewFormInputPlaceholders, setBreiefOverViewFormInputPlaceholders] = useState();
  const saveFormDataToLocalStorage = () => {
    const department = departments.filter( department => department.id === selectedDepartmentId);
      const breiefOverViewFormData = {
        briefTitle: values.briefTitle,
        expectedDeliveryDate: values.expectedDeliveryDate,
        department: department,
        targetSegments: values.targetSegments,
        regions: values.regions,
        ageBrackets: values.ageBrackets
      }
      customLocalStorage.encrypt("breiefOverViewFormData", JSON.stringify(breiefOverViewFormData));
  }
  
  const handleBackAndSaveFormData = () => {
      saveFormDataToLocalStorage();
      handleBack();
  }
  
  useEffect(() => {
      const breiefOverViewFormData = customLocalStorage.decrypt("breiefOverViewFormData");
      if(breiefOverViewFormData) setBreiefOverViewFormInputPlaceholders(breiefOverViewFormData);      
  }, [])
  
  // ##################### 



  useEffect(() => {
    let isMounted = true;

    // Check if selectedBrief is truthy
    if (selectedBrief && isMounted) {
      const {
        briefTitle = "",
        expectedDeliveryDate = "",
        targetSegments = [],
        regions = [],
        ageBrackets = [],
        coeDepartment = {},
      } = selectedBrief;

      setState((prevState) => ({
        ...prevState,
        briefTitle,
        expectedDeliveryDate,
        targetSegments,
        regions,
        ageBrackets,
      }));

      if (targetSegments && targetSegments?.length > 0) {
        setSelectedTargetSegmentIds(
          selectedBrief?.targetSegments.map((option) => option.id) || []
        );
      }

      if (regions && regions?.length > 0) {
        setSelectedRegionIds(
          selectedBrief?.regions.map((option) => option.id) || []
        );
        console.log({ selectedRegionIds });
      }

      if (ageBrackets && ageBrackets?.length > 0) {
        setSelectedAgeBracketIds(
          selectedBrief?.ageBrackets.map((option) => option.id) || []
        );
      }
      // Set the initial state for departmentId
      const initialDepartmentId = coeDepartment?.id || null;
      setSelectedDepartmentId(initialDepartmentId);
    }

    return () => {
      isMounted = false;
    };
  }, [selectedBrief]);

  const handleDepartmentChange = (departmentId) => {
    // Update the state with the selected department ID
    setSelectedDepartmentId(departmentId);
    // update setBreiefOverViewFormInputPlaceholders by setting department object empty
    // setBreiefOverViewFormInputPlaceholders({...breiefOverViewFormInputPlaceholders, department: []});
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateBrief = async (values) => {    
    saveFormDataToLocalStorage(); // Handles back/continue Navigation state
    handleLoader(true);

    setBriefTitle(values.briefTitle);
    setExpectedDeliveryDate(values.expectedDeliveryDate);

    await makeApiRequest({
      uri: `/market-research/briefs/overview`,
      dataName: `updatedBrief`,
      method: `put`,
      data: {
        briefId,
        briefTitle,
        expectedDeliveryDate,
        departmentId: selectedDepartmentId,
        targetSegments: selectedTargetSegmentIds,
        regions: selectedRegionIds,
        ageBrackets: selectedAgeBracketIds,
      },
    });
    handleLoader(false);
  };
  
  console.log("targetSegments",targetSegments)


  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleUpdateBrief}>
        <Box sx={{ pl: 20, pr: 20, pt: 5 }}>
          {" "}
          <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
            <CircularProgress color="success" />
          </Backdrop>
          <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 4 }}>
            <TextField
              label="Brief Title"
              name="briefTitle"
              value={ breiefOverViewFormInputPlaceholders ? breiefOverViewFormInputPlaceholders?.briefTitle : values.briefTitle}
              onChange={handleFormChange}
              onBlur={handleBlur}
              type="text"
              size="small"
              fullWidth
              error={touched.briefTitle && Boolean(errors.briefTitle)}
              helperText={touched.briefTitle && errors.briefTitle}
            />

            <TextField
              id="date"
              label="Expected Delivery Date"
              name="expectedDeliveryDate"
              value={ breiefOverViewFormInputPlaceholders ? breiefOverViewFormInputPlaceholders?.expectedDeliveryDate :values.expectedDeliveryDate}
              onChange={handleFormChange}
              onBlur={handleBlur}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              sx={{ mt: -5 }}
              error={
                touched.expectedDeliveryDate &&
                Boolean(errors.expectedDeliveryDate)
              }
              helperText={
                touched.expectedDeliveryDate && errors.expectedDeliveryDate
              }
              inputProps={{ min: currentDate }}
            />
            <FormControl fullWidth>
              <AutocompleteField
                name="departmentId"
                label="Department/Division"
                options={departments}
                defaultValue={
                  selectedBrief ? selectedBrief?.coeDepartment : null
                }
                selectedBrief={selectedBrief}
                selectedDepartmentId={selectedDepartmentId}
                onDepartmentChange={handleDepartmentChange}
              />

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {/* from placeholders */}
                {breiefOverViewFormInputPlaceholders?.department ? 
                    <Chip
                      key=""
                      label={breiefOverViewFormInputPlaceholders?.department[0]?.departmentName}
                      onDelete={handleDeleteTargetSegment(breiefOverViewFormInputPlaceholders?.department[0]?.id)}
                      color="primary"
                      variant="outlined"
                      sx={{
                        border: "1px #2CB34A solid",
                        background: "none",
                        color: "#2CB34A",
                      }}
                    />:""
                }
              </Box>

            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="target-segments-chip-label">
                Target segments
              </InputLabel>
              <Select
                labelId="target-segments-chip-label"
                id="target-segments-chip"
                multiple
                name="targetSegments"
                value={values.targetSegments}
                onChange={handleSelectionChange}
                onBlur={handleBlur}
                input={
                  <OutlinedInput id="select-chip" label="Target segments" />
                }
                MenuProps={MenuProps}
                fullWidth
                error={touched.targetSegments && Boolean(errors.targetSegments)}
                helperText={touched.targetSegments && errors.targetSegments}
              >
                {targetSegments?.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.targetSegmentName}
                  </MenuItem>
                ))}
              </Select>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {selectedTargetSegmentOptions?.targetSegments?.map(
                  (id, _) => (
                    <Chip
                      key=""
                      label={
                        targetSegments.find((segment) => segment.id === id)
                          ?.targetSegmentName
                      }
                      onDelete={handleDeleteTargetSegment(id)}
                      color="primary"
                      variant="outlined"
                      sx={{
                        border: "1px #2CB34A solid",
                        background: "none",
                        color: "#2CB34A",
                      }}
                    />
                  )
                )}

                {/* from placeholders */}
                {breiefOverViewFormInputPlaceholders?.targetSegments ?
                    breiefOverViewFormInputPlaceholders.targetSegments?.map(
                    (id, _) => (
                      <Chip
                        key=""
                        label={
                          targetSegments.find((segment) => segment.id === id)
                            ?.targetSegmentName
                        }
                        onDelete={handleDeleteTargetSegment(id)}
                        color="primary"
                        variant="outlined"
                        sx={{
                          border: "1px #2CB34A solid",
                          background: "none",
                          color: "#2CB34A",
                        }}
                      />
                    )
                  ):""
                }
              </Box>

            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="regions-chip-label">Regions</InputLabel>
              <Select
                labelId="regions-chip-label"
                id="regions-chip"
                multiple
                name="regions"
                value={values.regions}
                onChange={handleSelectionChange}
                onBlur={handleBlur}
                input={
                  <OutlinedInput id="select-regions-chip" label="Regions" />
                }
                MenuProps={MenuProps}
                fullWidth
                error={touched.regions && Boolean(errors.regions)}
                helperText={touched.regions && errors.regions}
              >
                {regions?.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.regionName}
                  </MenuItem>
                ))}
              </Select>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {selectedRegionOptions?.regions?.map((id, _) => (
                  <Chip
                    key=""
                    label={
                      regions.find((region) => region.id === id)?.regionName
                    }
                    onDelete={handleDeleteRegion(id)}
                    color="primary"
                    variant="outlined"
                    sx={{
                      border: "1px #2CB34A solid",
                      background: "none",
                      color: "#2CB34A",
                    }}
                  />
                ))}
                {/* From placeholder */}
                {breiefOverViewFormInputPlaceholders?.regions  ? 
                    breiefOverViewFormInputPlaceholders.regions?.map((id, _) => (
                  <Chip
                    key=""
                    label={
                      regions.find((region) => region.id === id)?.regionName
                    }
                    onDelete={handleDeleteRegion(id)}
                    color="primary"
                    variant="outlined"
                    sx={{
                      border: "1px #2CB34A solid",
                      background: "none",
                      color: "#2CB34A",
                    }}
                  />
                )):""}
              </Box>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="age-brackets-chip-label">Age Brackets</InputLabel>

              <Select
                labelId="age-brackets-chip-label"
                id="age-brackets-chip"
                multiple
                name="ageBrackets"
                value={values.ageBrackets}
                onChange={handleSelectionChange}
                onBlur={handleBlur}
                input={
                  <OutlinedInput
                    id="select-age-brackets-chip"
                    label="Age Brackets"
                  />
                }
                MenuProps={MenuProps}
                fullWidth
                error={touched.ageBrackets && Boolean(errors.ageBrackets)}
                helperText={touched.ageBrackets && errors.ageBrackets}
              >
                {ageBrackets?.map((ageBracket) => (
                  <MenuItem key={ageBracket.id} value={ageBracket.id}>
                    {ageBracket.ageBracketName}
                  </MenuItem>
                ))}
              </Select>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                {selectedAgeBracketOptions?.ageBrackets?.map((id, _) => (
                  <Chip
                    key=""
                    label={
                      ageBrackets.find((ageBracket) => ageBracket.id === id)
                        ?.ageBracketName
                    }
                    onDelete={handleDeleteAgeBracket(id)}
                    color="primary"
                    variant="outlined"
                    sx={{
                      border: "1px #2CB34A solid",
                      background: "none",
                      color: "#2CB34A",
                    }}
                  />
                ))}
                {/* from placeholder */}
                {breiefOverViewFormInputPlaceholders?.ageBrackets && 
                    breiefOverViewFormInputPlaceholders?.ageBrackets?.map((id, _) => (
                  <Chip
                    key=""
                    label={
                      ageBrackets.find((ageBracket) => ageBracket.id === id)
                        ?.ageBracketName
                    }
                    onDelete={handleDeleteAgeBracket(id)}
                    color="primary"
                    variant="outlined"
                    sx={{
                      border: "1px #2CB34A solid",
                      background: "none",
                      color: "#2CB34A",
                    }}
                  />
                ))}
              </Box>
            </FormControl>
          </Stack>
          <Box sx={{ p: 5 }}>
            <Divider
              sx={{
                border: "0.5px dotted #000",
                opacity: 0.3,
              }}
            />

            <Stack
              direction="row"
              spacing={3}
              sx={{ mt: 2, justifyContent: "right" }}
            >
              <Button
                color="inherit"
                variant="contained"
                style={secondaryButton}
                disableElevation
                onClick={handleBackAndSaveFormData}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                style={!isValid ? disabledButton : primaryButton}
                onClick={handleUpdateBrief}
                disableElevation
                disabled={!isValid}
              >
                Save & Continue
              </Button>
            </Stack>
          </Box>
        </Box>
      </form>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </FormikProvider>
  );
}
