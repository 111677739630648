import * as React from "react";
import { Box, Typography } from "@mui/material";
import { emptyPageImageStyle } from "../styles/common-styles";
import PropTypes from "prop-types";

EmptyTaskPage.propTypes = {
  emptyPageTitle: PropTypes.string,
  emptyPageDescription: PropTypes.string,
};

EmptyTaskPage.defaultProps = {
  emptyPageTitle: "",
  emptyPageDescription: "",
};

export default function EmptyTaskPage(props) {
  const { emptyPageTitle, emptyPageDescription } = props;

  return (
    <Box
      sx={{
        background: "#fff",
        textAlign: "center",
        p: 10,
        ml: 3,
        borderRadius: 3,
      }}
    >
      <img
        src="/project-images/empty-brief-list.png"
        style={emptyPageImageStyle}
        alt="empty-task-page"
      />

      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: 18,
          mt: 1,
          color: "#637381",
        }}
      >
        {emptyPageTitle}
      </Typography>

      <Box sx={{ width: "270px", m: "0 auto", pt: 2 }}>
        <Typography sx={{ opacity: 0.5, fontSize: 14 }}>
          {emptyPageDescription}
        </Typography>
      </Box>
    </Box>
  );
}
