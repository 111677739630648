import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const MarketResearchLeadBriefStatusDisplay = ({ status }) => {
  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_MARKET_RESEARCH_LEAD":
        return {
          text: "Review Brief",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD_REPORT":
        return {
          text: "Review Report",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      default:
        return {
          text: "Unknown Status",
        };
    }
  };

  const {
    text,
    backgroundColor,
    textColor,
    fontSize,
    fontWeight,
    borderRadius,
    padding,
  } = getStatusDisplay(status);

  return (
    <Button
      sx={{
        backgroundColor,
        color: textColor,
        fontSize,
        fontWeight,
        borderRadius,
        fontFamily: "Futura",
        textTransform: "none",
        padding,
      }}
    >
      {text}
    </Button>
  );
};

MarketResearchLeadBriefStatusDisplay.propTypes = {
  status: PropTypes.string,
};

MarketResearchLeadBriefStatusDisplay.defaultProps = {
  status: "",
};

export default MarketResearchLeadBriefStatusDisplay;
