import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Backdrop,
  CircularProgress,
  IconButton,
  Modal,
  Fade,
  TextField,
  FormControl,
  Stack,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import apiRequest from "../../services/api-request";
import SnackbarComponent from "../../shared-components/snackbar";
import {
  approveButton,
  disabledButton,
  modalStyle,
  modalStyle3,
  primaryButton,
  secondaryApprovalButton,
  secondaryButton,
} from "../../styles/common-styles";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import SelectUserToAssignTaskAutocompleteField from "../market-research-lead/select-user-to-assign-task-autocomplete";
import BriefDetails from "../../shared-components/brief-details";
import { statusFailStyle, statusSuccessStyle } from "../../styles/form-styles";
import PropTypes from "prop-types";
import ArrowBackIcon from "../../assets/icons-js-files/arrow-back-icon";
import DeleteIcon from "../../assets/icons-js-files/delete-icon";

// Validation schema for the "report upload and email recipient" form
const reportValidationSchema = yup.object({
  file: yup.mixed().required("File is required"),
  email: yup.string().email("Invalid email"),
  stakeHoldersEmails: yup.array().min(1, "At least one email is required"),
  comment: yup
    .string()
    .required("Comment is required")
    .max(500, "Comment must be at most 500 characters")
    .matches(
      /^[a-zA-Z0-9\s.'\-\[\]\(\)]+$/,
      "Comment can only contain letters, numbers, spaces, periods, hyphens, apostrophes, and brackets"
    ),
});

// Validation schema for the "comment" form
const commentValidationSchema = yup.object({
  comment: yup
    .string()
    .required("Comment is required")
    .max(500, "Comment must be at most 500 characters")
    .matches(
      /^[a-zA-Z\s'-]+$/,
      "Comment can only contain letters, spaces, -, and '"
    ),
});

MarketResearchPlannerApprovalPage.propTypes = {
  togglePage: PropTypes.func.isRequired,
  roles: PropTypes.array,
  brandCoeId: PropTypes.string,
  selectedBrief: PropTypes.object,
};

MarketResearchPlannerApprovalPage.defaultProps = {
  selectedBrief: {},
  brandCoeId: "",
  roles: [],
};

export default function MarketResearchPlannerApprovalPage(props) {
  const { selectedBrief, roles, brandCoeId, togglePage } = props;
  const { briefId } = selectedBrief;

  const initialState = {
    currentPage: "taskDetailsPage",
    currentSelection: "",
    reportDoc: "",
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [externalReviewers, setExternalUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [comment, setComment] = useState("");
  const [reportDocUploaded, setReportDocUploaded] = useState(false);
  const [stakeHoldersEmails, setStakeholdersEmails] = useState([]);
  const [fullBrief, setFullBrief] = useState({});

  const { currentSelection, loading, snackBarSeverity } = state;

  const commentFormik = useFormik({
    initialValues: {
      comment: "",
    },
    enableReinitialize: true,
    validationSchema: commentValidationSchema,
    onSubmit: () => {
      handleSubmitApproval();
    },
    validateOnMount: true,
  });

  const reportFormik = useFormik({
    initialValues: {
      file: null,
      email: "",
      comment: "",
      stakeHoldersEmails: [],
    },
    validationSchema: reportValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      handleSubmitReport();
    },
  });

  const {
    values: reportValues,
    errors: reportErrors,
    touched: reportTouched,
    isValid: reportIsValid,
    handleChange: reportHandleChange,
    handleBlur: reportHandleBlur,
  } = reportFormik;

  const {
    values: commentValues,
    errors: commentErrors,
    touched: commentTouched,
    isValid: commentIsValid,
    handleChange,
    handleBlur: commentHandleBlur,
  } = commentFormik;

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "fullBrief") {
        setFullBrief(data?.data);
      }
      if (dataName === "externalReviewers") {
        setExternalUsers(data?.data);
      }
      if (dataName === "reviewedBrief" || dataName === "submittedReport") {
        window.location.reload();
      }
      if (dataName === "reportDoc") {
        setReportDocUploaded(true);
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetFullBrief = async () => {
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/${briefId}`,
      dataName: `fullBrief`,
      method: `get`,
      data: "",
    });
  };

  const handleGetExternalReviews = async () => {
    const getExternalReviewerRoleId = (roleName) => {
      const role = roles.find((item) => item.name === roleName);
      return role ? role.id : null;
    };

    const externalReviewerRoleId = getExternalReviewerRoleId(
      "ROLE_EXTERNAL_REVIEWER"
    );

    handleLoader(true);
    await makeApiRequest({
      uri: `/user-management/users/role/${externalReviewerRoleId}/brand-coe/${brandCoeId}`,
      dataName: `externalReviewers`,
      method: `get`,
      data: "",
    });
  };

  useEffect(() => {
    (async () => {
      await handleGetFullBrief();
      await handleGetExternalReviews();
    })();
  }, []);

  const handleUserChange = (userId) => {
    setSelectedUserId(userId);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    handleChange(e);

    if (name === "comment") {
      setComment(value);
    }
  };

  const handleReportFormChange = (e) => {
    const { name, value } = e.target;

    reportHandleChange(e);

    if (name === "comment") {
      setComment(value);
    }
  };

  const handleFileUpload = async (params) => {
    const { event, docType } = params;
    if (event?.target?.files) {
      const file = event.target.files[0];
      const fileExtension = file.type;
      const allowedFiles = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_TYPES;

      if (
        fileExtension &&
        allowedFiles
          .toLocaleLowerCase()
          .includes(fileExtension.toLocaleLowerCase())
      ) {
        const data = new FormData();
        data.append("file", file);
        await makeApiRequest({
          uri: `/market-research/briefs/tasks/upload/${selectedBrief?.briefId}`,
          dataName: docType,
          method: `post`,
          data,
        });
      } else {
        alert(`${fileExtension} Not allowed. You can only upload a .pdf file `);
        return;
      }
    }
  };

  const handleFileUploadChange = (event) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      reportFormik.setFieldValue("file", file);

      setReportDocUploaded(false);
      handleFileUpload({ event, docType: "reportDoc" });
    }
  };

  const handleAddEmailRecipients = () => {
    const email = reportFormik.values.email.trim();
    if (email && !stakeHoldersEmails.includes(email)) {
      setStakeholdersEmails([...stakeHoldersEmails, email]);
      reportFormik.setFieldValue("stakeHoldersEmails", [
        ...stakeHoldersEmails,
        email,
      ]);
      reportFormik.setFieldValue("email", "");
    }
  };

  const handleDeleteEmail = (email) => {
    const updatedEmails = stakeHoldersEmails.filter(
      (existingEmail) => existingEmail !== email
    );
    setStakeholdersEmails(updatedEmails);
    reportFormik.setFieldValue("stakeHoldersEmails", updatedEmails);
  };

  const handleApproval = (currentSelection) => {
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handleCloseModal = () => {
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleSubmitApproval = async () => {
    handleLoader(true);

    let approvalTaskValue;

    switch (currentSelection) {
      case "approveBrief":
        approvalTaskValue = "APPROVE";
        break;
      case "requestForChanges":
        approvalTaskValue = "REQUEST_CHANGES";
        break;
      default:
        break;
    }

    await makeApiRequest({
      uri: `/market-research/briefs/tasks`,
      dataName: `reviewedBrief`,
      method: `post`,
      data: {
        briefId: selectedBrief?.briefId,
        approvalTask: approvalTaskValue,
        comment,
        userId: selectedUserId,
      },
    });
    handleLoader(false);
    handleCloseModal();
  };

  const handleSubmitReport = async () => {
    handleLoader(true);

    await makeApiRequest({
      uri: `/market-research/briefs/tasks/submit-report`,
      dataName: `submittedReport`,
      method: `post`,
      data: {
        briefId: selectedBrief?.briefId,
        comment,
        stakeHoldersEmails,
      },
    });
    handleLoader(false);
    handleCloseModal();
  };

  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_RESEARCH_PLANNER_REVIEW":
        return {
          text: "Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_EXTERNAL_REVIEW":
        return {
          text: "Upload Report",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD_REPORT":
        return {
          text: "Report Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_RESEARCH_PLANNER_REPORT":
        return {
          text: "Report Changes Requested",
          backgroundColor: "rgba(236, 186, 9, 0.15)",
          textColor: "#ECBA09",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_STUDY_COMPLETED":
        return {
          text: "Study complete",
          backgroundColor: "rgba(44, 179, 74, 0.15)",
          textColor: "#2CB34A",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      default:
        return {
          text: "Unknown Status",
          // Set default styles for unknown status values
        };
    }
  };

  const statusDisplay = getStatusDisplay(selectedBrief?.briefStatus);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Grid container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() =>
            togglePage({
              currentPage: "taskListPage",
              selectedBrief,
            })
          }
        >
          <ArrowBackIcon
            stroke="#2CB34A"
            style={{
              width: "16px",
              height: "16px",
            }}
          />{" "}
        </IconButton>

        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          Brief #{selectedBrief?.briefId}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={9} lg={9}>
          <BriefDetails
            selectedBrief={fullBrief}
            statusDisplay={statusDisplay}
          />
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          {selectedBrief?.briefStatus === "BRIEF_RESEARCH_PLANNER_REVIEW" && (
            <Box>
              <Button
                variant="contained"
                style={approveButton}
                sx={{ mb: 3 }}
                onClick={() => handleApproval("approveBrief")}
              >
                Approve Brief
              </Button>

              <Button
                variant="contained"
                style={secondaryApprovalButton}
                sx={{ mb: 3 }}
                onClick={() => handleApproval("requestForChanges")}
              >
                Request Additional Info
              </Button>
            </Box>
          )}
          {selectedBrief?.briefStatus === "BRIEF_EXTERNAL_REVIEW" && (
            <Button
              variant="contained"
              style={secondaryApprovalButton}
              sx={{ mb: 3 }}
              onClick={() => handleApproval("uploadReport")}
            >
              Upload Final Report
            </Button>
          )}
        </Grid>

        <FormikProvider value={commentFormik}>
          <Modal
            aria-labelledby="approval-modal"
            aria-describedby="approval-modal-description"
            open={currentSelection}
            onClose={handleCloseModal}
            closeAfterTransition
          >
            <Fade in={currentSelection !== ""}>
              <Box>
                <Box style={modalStyle} sx={{ p: 5 }}>
                  {currentSelection === "approveBrief" && (
                    <>
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          id="approval-modal"
                          variant="h6"
                          component="h2"
                          sx={{ color: "#2CB34A", fontWeight: "bold" }}
                        >
                          Select Research Planner to Assign Brief
                        </Typography>
                      </Box>

                      <FormControl fullWidth>
                        <SelectUserToAssignTaskAutocompleteField
                          name="userId"
                          label="External Reviewer"
                          options={externalReviewers}
                          defaultValue=""
                          selectedBrief={fullBrief}
                          selectedUserId={selectedUserId}
                          onUserChange={handleUserChange}
                        />
                      </FormControl>

                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={commentValues.comment}
                          onChange={handleFormChange}
                          onBlur={commentHandleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={
                            commentTouched.comment &&
                            Boolean(commentErrors.comment)
                          }
                          helperText={
                            commentTouched.comment && commentErrors.comment
                          }
                        />
                      </Box>
                    </>
                  )}

                  {currentSelection === "requestForChanges" && (
                    <form onSubmit={commentFormik.handleSubmit}>
                      <Typography
                        id="approval-modal"
                        variant="h6"
                        component="h2"
                        sx={{ color: "#2CB34A", fontWeight: "bold" }}
                      >
                        Request for Additional Information
                      </Typography>

                      <Box spacing={5} sx={{ mt: 3 }}>
                        <TextField
                          label="Add Comment"
                          name="comment"
                          value={commentValues.comment}
                          onChange={handleFormChange}
                          onBlur={commentHandleBlur}
                          type="text"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={
                            commentTouched.comment &&
                            Boolean(commentErrors.comment)
                          }
                          helperText={
                            commentTouched.comment && commentErrors.comment
                          }
                        />
                      </Box>
                    </form>
                  )}

                  {currentSelection === "uploadReport" && (
                    <Box style={modalStyle3} sx={{ p: 5 }}>
                      <Box sx={{ mb: 3 }}>
                        <Typography
                          id="approval-modal"
                          variant="h6"
                          component="h2"
                          sx={{ color: "#2CB34A", fontWeight: "bold" }}
                        >
                          Brief Report
                        </Typography>
                      </Box>

                      <form onSubmit={reportFormik.handleSubmit}>
                        <TableContainer sx={{ mt: 3, ml: -2 }}>
                          <Table
                            sx={{ minWidth: 500 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  File
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Type
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                  Status
                                </TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  Final Report
                                </TableCell>
                                <TableCell>PDF/DOC/PPT</TableCell>
                                <TableCell>
                                  <Typography
                                    style={
                                      reportDocUploaded
                                        ? statusSuccessStyle
                                        : statusFailStyle
                                    }
                                  >
                                    {reportDocUploaded
                                      ? "Uploaded successfully"
                                      : "Not uploaded"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <label htmlFor="reportDoc">
                                    <input
                                      data-testid="file-upload-input"
                                      type="file"
                                      id="reportDoc"
                                      accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                        application/msword,application/vnd.openxmlformats-officedocument.presentationml.presentation,
                                        application/vnd.ms-powerpoint,.docx,.doc,.pptx,.ppt"
                                      style={{ display: "none" }}
                                      onChange={handleFileUploadChange}
                                    />
                                    <Button
                                      style={secondaryButton}
                                      variant="contained"
                                      component="span"
                                      disableElevation
                                    >
                                      Upload
                                    </Button>
                                  </label>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Typography sx={{ mt: 3, fontWeight: "bold" }}>
                          Add Email Recipients
                        </Typography>

                        <Stack direction="row" spacing={3} sx={{ mt: 4 }}>
                          <FormControl fullWidth>
                            <TextField
                              data-testid="email-input"
                              label="Email address"
                              name="email"
                              type="text"
                              size="small"
                              value={reportValues.email}
                              onChange={reportHandleChange}
                              onBlur={reportHandleBlur}
                              error={
                                reportTouched.email &&
                                Boolean(reportErrors.email)
                              }
                              helperText={
                                reportTouched.email && reportErrors.email
                              }
                            />
                          </FormControl>
                          <Box>
                            <Button
                              style={primaryButton}
                              onClick={handleAddEmailRecipients}
                            >
                              Add
                            </Button>
                          </Box>
                        </Stack>

                        <Box
                          sx={{
                            backgroundColor: "#f9f9f9",
                            p: 2,
                            mb: 5,
                            mt: 3,
                          }}
                        >
                          <Typography
                            textAlign="left"
                            sx={{ textDecoration: "underline" }}
                          >
                            Email Recipients
                          </Typography>
                          <List sx={{ pt: 2 }}>
                            {stakeHoldersEmails &&
                              stakeHoldersEmails?.map((email) => (
                                <Box>
                                  <Stack direction="row">
                                    <ListItem>
                                      <ListItemText primary={email} />
                                    </ListItem>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => handleDeleteEmail(email)}
                                    >
                                      <DeleteIcon
                                        stroke="#ff2a58"
                                        style={{
                                          marginRight: "10px",
                                          width: "18px",
                                        }}
                                      />{" "}
                                    </IconButton>
                                  </Stack>
                                </Box>
                              ))}
                          </List>
                        </Box>

                        <Box sx={{ mt: 3, mb: 3 }}>
                          <TextField
                            label="Add Comment"
                            name="comment"
                            value={reportValues.comment}
                            onChange={handleReportFormChange}
                            onBlur={reportHandleBlur}
                            type="text"
                            size="small"
                            fullWidth
                            multiline
                            minRows={4}
                            error={
                              reportTouched.comment &&
                              Boolean(reportErrors.comment)
                            }
                            helperText={
                              reportTouched.comment && reportErrors.comment
                            }
                          />
                        </Box>

                        {currentSelection === "uploadReport" && (
                          <Button
                            style={
                              reportIsValid ? primaryButton : disabledButton
                            }
                            disabled={!reportIsValid}
                            onClick={() => handleSubmitReport()}
                          >
                            Submit Report
                          </Button>
                        )}
                      </form>
                    </Box>
                  )}

                  <Box sx={{ mt: 3 }}>
                    {currentSelection === "approveBrief" && (
                      <Button
                        style={!commentIsValid ? disabledButton : primaryButton}
                        onClick={() => handleSubmitApproval()}
                        disabled={!commentIsValid}
                      >
                        Submit Approval
                      </Button>
                    )}

                    {currentSelection === "requestForChanges" && (
                      <Button
                        style={commentIsValid ? primaryButton : disabledButton}
                        onClick={() => handleSubmitApproval()}
                        disabled={!commentIsValid}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </FormikProvider>
      </Grid>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
