import React from "react";
import { Typography } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import { EditorState } from "draft-js";

RichTextEditor.propTypes = {
  onEditorStateChange: PropTypes.func.isRequired,
  editorState: PropTypes.instanceOf(EditorState).isRequired,
  title: PropTypes.string,
};

RichTextEditor.defaultProps = {
  title: "",
};

export default function RichTextEditor(props) {
  const { editorState, onEditorStateChange, title } = props;

  const customToolbarStyle = {
    borderBottom: "none",
    borderTop: "1px solid #C8C8C8",
    borderLeft: "1px solid #C8C8C8",
    borderRight: "1px solid #C8C8C8",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    marginTop: "5px",
    padding: "10px",
    fontFamily: "Futura",
  };

  const customEditorStyle = {
    borderBottom: "1px solid #C8C8C8",
    borderLeft: "1px solid #C8C8C8",
    borderRight: "1px solid #C8C8C8",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    marginTop: "-7px",
    padding: "10px",
    height: "200px",
    fontFamily: "Futura",
  };

  return (
    <>
      <Typography>{title}</Typography>
      <Editor
        editorStyle={customEditorStyle}
        editorState={editorState}
        toolbarStyle={customToolbarStyle}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
    </>
  );
}
