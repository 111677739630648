import React, { useState } from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
} from "@mui/material";
import EmptyTaskPage from "../empty-task-page";

export default function BriefOwnerDashboardPage() {
  const initialState = {
    loading: false,
  };

  const [state, setState] = useState(initialState);

  const { loading } = state;

  return (
    <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, mt: 8 }}>
      <Backdrop
        sx={{ zIndex: 1, color: "#fff" }}
        open={loading}
        data-testid="backdrop"
      >
        <CircularProgress color="success" data-testid="circular-progress" />
      </Backdrop>
      <Box sx={{ borderColor: "divider", mb: 3, ml: 3 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex" }}
        >
          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Dashboard
          </Typography>
        </Stack>
      </Box>
      <EmptyTaskPage emptyPageDescription="Dashboard coming soon" />;
    </Box>
  );
}
