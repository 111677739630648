import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Typography, Checkbox } from "@mui/material";
import { useField } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled } from "@mui/system";

const GreenCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "#2CB34A",
  },
});

const DepartmentFilterDropdown = ({
  name,
  options,
  label,
  selectedFilterId,
  onFilterChange,
}) => {
  const [meta, helpers] = useField(name || "");
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (selectedFilterId) {
      const initialSelected = options.filter((option) =>
        selectedFilterId.includes(option.name || option)
      );
      setSelectedValues(initialSelected);
    }
  }, [selectedFilterId, options]);

  const showError = meta.touched && Boolean(meta.error);

  const handleFilterChange = (values) => {
    setSelectedValues(values);
    const selectedNames = values.map((value) => value.name || value);
    helpers.setValue(selectedNames);
    onFilterChange && onFilterChange(selectedNames);
  };

  return (
    <>
      <Autocomplete
        multiple
        sx={{
          width: "180px",
          marginTop: 3,
          "& .MuiInputBase-input": {
            fontFamily: "Futura",
            height: "20px",
            padding: "10px 5px",
          },
          "& .MuiAutocomplete-option": {
            fontFamily: "Futura !important",
          },
          "& .MuiFormLabel-root": {
            fontFamily: "Futura",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#2CB34A",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2CB34A",
          },
          "&.Mui-focused .MuiInputLabel-root": {
            color: "#2CB34A",
          },
        }}
        options={options}
        getOptionLabel={(option) => option.name || option}
        value={selectedValues}
        onChange={(_, values) => handleFilterChange(values)}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ fontFamily: "Futura" }}>
            <GreenCheckbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selectedValues.some(
                (val) => val.name === option.name || val === option
              )}
            />
            <span style={{ fontFamily: "Futura" }}>
              {option.name || option}
            </span>
          </li>
        )}
        renderInput={(params) => {
          const errorMsg = meta.error ? meta.error : {};
          return (
            <TextField
              sx={{
                "& .MuiInputBase-input": {
                  fontFamily: "Futura",
                },
                "& .MuiFormLabel-root": {
                  fontFamily: "Futura",
                },
              }}
              {...params}
              label={label}
              name={name}
              error={showError}
              helperText={showError ? errorMsg.value : ""}
              onBlur={() => helpers.setTouched(true)}
            />
          );
        }}
      />
      {showError && (
        <Typography variant="caption" color="error">
          {meta.error.value}
        </Typography>
      )}
    </>
  );
};

export default DepartmentFilterDropdown;
