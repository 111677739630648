import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  IconButton,
  Button,
  Container,
  List,
  ListItem,
  TextField,
  FormControl,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import apiRequest from "../../services/api-request";
import SnackbarComponent from "../../shared-components/snackbar";
import BriefDetails from "../../shared-components/brief-details";
import {
  disabledButton,
  primaryButton,
  sectionHeadingStyle,
} from "../../styles/common-styles";
import SelectUserToAssignTaskAutocompleteField from "./select-user-to-assign-task-autocomplete";
import ArrowBackIcon from "../../assets/icons-js-files/arrow-back-icon";

const validationSchema = yup.object({
  comment: yup
    .string()
    .required("Comment is required")
    .max(500, "Comment must be at most 500 characters")
    .matches(
      /^[a-zA-Z0-9\s.,?!'-]+$/,
      "Comment can only contain letters, numbers, spaces, full stops, commas, question marks, hyphens, and apostrophes"
    ),
});

MarketResearchLeadAllTasksDetailsPage.propTypes = {
  togglePage: PropTypes.func.isRequired,
  roles: PropTypes.array,
  brandCoeId: PropTypes.string,
  navigatedFrom: PropTypes.string,
  selectedBrief: PropTypes.object,
};

MarketResearchLeadAllTasksDetailsPage.defaultProps = {
  selectedBrief: {},
  brandCoeId: "",
  navigatedFrom: "",
  roles: [],
};

export default function MarketResearchLeadAllTasksDetailsPage(props) {
  const { selectedBrief, togglePage, roles, brandCoeId, user } = props;

  const { briefId } = selectedBrief;

  const initialState = {
    currentPage: "mrAllTaskDetailsPage",
    currentSelection: "",
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [researchPlannerUsers, setResearchPlannerUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [comment, setComment] = useState("");
  const [fullBrief, setFullBrief] = useState({});

  const { loading, snackBarSeverity } = state;

  const hideReassignForm =
    selectedBrief &&
    [
      "BRIEF_IN_DRAFT",
      "BRIEF_COMPLETE",
      "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD",
      "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER",
      "BRIEF_EXTERNAL_REVIEW",
    ].includes(selectedBrief.briefStatus);

  const formik = useFormik({
    initialValues: {
      comment,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmitApproval(values);
    },
    validateOnMount: true,
  });

  const { values, errors, touched, isValid, handleChange, handleBlur } = formik;

  const handleUserChange = (userId) => {
    setSelectedUserId(userId);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "researchPlannerUsers") {
        setResearchPlannerUsers(data?.data);
      }
      if (dataName === "fullBrief") {
        setFullBrief(data?.data);
      }
      if (dataName === "reviewedBrief") {
        window.location.reload();
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    handleChange(e);

    if (name === "comment") {
      setComment(value);
    }
  };

  const handleGetFullBrief = async () => {
    await makeApiRequest({
      uri: `/market-research/briefs/tasks/${briefId}`,
      dataName: `fullBrief`,
      method: `get`,
      data: "",
    });
  };

  const handleGetResearchPlannerUsers = async () => {
    // Function to get the Research Planner Role ID
    const getResearchPlannerRoleId = (roleName) => {
      console.log({ roles });
      let role = null;

      // Check if the user is a super admin by examining their roles
      const isSuperAdmin = user?.role.includes("ROLE_SUPER_ADMIN");

      if (isSuperAdmin) {
        // Iterate through each brand COE for super admin role structure
        for (const brand of roles) {
          role = brand.roles?.find((item) => item.name === roleName);
          if (role) {
            break; // Break the loop if the role is found
          }
        }
      } else {
        // Flat structure for market research lead
        role = roles.find((item) => item.name === roleName);
      }

      return role ? role.id : null;
    };

    const researchPlannerRoleId = getResearchPlannerRoleId(
      "ROLE_RESEARCH_PLANNER"
    );

    if (researchPlannerRoleId !== null) {
      handleLoader(true);
      await makeApiRequest({
        uri: `/user-management/users/role/${researchPlannerRoleId}/brand-coe/${brandCoeId}`,
        dataName: `researchPlannerUsers`,
        method: `get`,
        data: "",
      });
    } else {
      console.error("Research Planner role not found");
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetFullBrief();
      if (props.navigatedFrom === "pendingTaskListPage") {
        await handleGetResearchPlannerUsers();
      }
    })();
  }, []);

  const handleSubmitApproval = async (values) => {
    console.log("Form Values:", values); // Log the form values

    handleLoader(true);

    await makeApiRequest({
      uri: `/market-research/briefs/tasks`,
      dataName: `reviewedBrief`,
      method: `post`,
      data: {
        briefId: selectedBrief?.briefId,
        approvalTask: "REASSIGN",
        comment,
        userId: selectedUserId,
      },
    });
    handleLoader(false);
    window.location.reload();
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const getStatusDisplay = (status) => {
    switch (status) {
      case "BRIEF_COMPLETE":
        return {
          text: "Completed",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px 15px 5px 15px",
        };
      case "BRIEF_IN_DRAFT":
        return {
          text: "Draft",
          backgroundColor: "#E5E5E5",
          textColor: "#464646",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px",
        };
      case "BRIEF_MARKET_RESEARCH_LEAD":
        return {
          text: "Research Lead Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          padding: "5px",
        };
      case "BRIEF_RESEARCH_PLANNER_REVIEW":
        return {
          text: "Research Planner Review",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD":
        return {
          text: "Changes Requested",
          backgroundColor: "rgba(236, 186, 9, 0.15)",
          textColor: "#ECBA09",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_EXTERNAL_REVIEW":
        return {
          text: "Study Underway",
          backgroundColor: "#00B7FB26",
          textColor: "#00B7FB",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_CANCELLED":
        return {
          text: "Brief Cancelled",
          backgroundColor: "rgba(239, 55, 62, 0.15)",
          textColor: "#EF373E",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_REJECTED":
        return {
          text: "Brief Rejected",
          backgroundColor: "rgba(239, 55, 62, 0.15)",
          textColor: "#EF373E",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      case "BRIEF_STUDY_COMPLETED":
        return {
          text: "Study complete",
          backgroundColor: "rgba(44, 179, 74, 0.15)",
          textColor: "#2CB34A",
          fontSize: "12px",
          borderRadius: "20px",
          fontWeight: "bold",
          width: "auto",
          paddingRight: "10px",
          paddingLeft: "10px",
        };
      default:
        return {
          text: "Unknown Status",
          // Set default styles for unknown status values
        };
    }
  };

  const statusDisplay = getStatusDisplay(selectedBrief?.briefStatus);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Grid container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          data-testid="go-back-button"
          onClick={() =>
            togglePage({
              currentPage: props.navigatedFrom,
              selectedBrief,
            })
          }
        >
          <ArrowBackIcon
            stroke="#2CB34A"
            style={{
              width: "16px",
              height: "16px",
            }}
          />{" "}
        </IconButton>

        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          Brief #{selectedBrief?.briefId} Details
        </Typography>
      </Grid>

      {props.navigatedFrom === "pendingTaskListPage" && hideReassignForm && (
        <BriefDetails selectedBrief={fullBrief} statusDisplay={statusDisplay} />
      )}

      {props.navigatedFrom === "pendingTaskListPage" && !hideReassignForm && (
        <Grid container spacing={5}>
          <Grid item xs={12} md={8} lg={8}>
            <BriefDetails
              selectedBrief={fullBrief}
              statusDisplay={statusDisplay}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <FormikProvider value={formik}>
              <Box sx={{ backgroundColor: "#fff", p: 3 }}>
                <Container sx={{ backgroundColor: "#f9f9f9" }}>
                  <Grid container sx={{ width: "280px", ml: -3, pr: 2 }}>
                    <Grid item xs={6}>
                      <List>
                        {["Current User Assigned to Task"].map((value) => {
                          const listItemId = `${value}`;
                          return (
                            <ListItem sx={{ p: 1 }}>
                              <Typography
                                id={listItemId}
                                sx={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                {`${value}`}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem>
                          <Typography sx={{ fontSize: "14px" }}>
                            {fullBrief?.researchPlanner?.firstname}{" "}
                            {fullBrief?.researchPlanner?.lastname}{" "}
                          </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Container>

                <Typography
                  textAlign="left"
                  style={sectionHeadingStyle}
                  sx={{ mb: 3, mt: 2 }}
                >
                  Assign task to different user
                </Typography>

                <FormControl fullWidth>
                  <SelectUserToAssignTaskAutocompleteField
                    name="userId"
                    label="Assign User"
                    options={researchPlannerUsers}
                    defaultValue=""
                    selectedBrief={fullBrief}
                    selectedUserId={selectedUserId}
                    onUserChange={handleUserChange}
                  />
                </FormControl>

                <Box spacing={5} sx={{ mt: 3 }}>
                  <TextField
                    label="Add Comment"
                    name="comment"
                    value={values.comment}
                    onChange={handleFormChange}
                    onBlur={handleBlur}
                    type="text"
                    size="small"
                    fullWidth
                    multiline
                    minRows={4}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={touched.comment && errors.comment}
                  />
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Button
                    style={isValid ? primaryButton : disabledButton}
                    onClick={() => handleSubmitApproval()}
                    disabled={!isValid}
                  >
                    Reassign Task
                  </Button>
                </Box>
              </Box>
            </FormikProvider>
          </Grid>
        </Grid>
      )}

      {props.navigatedFrom === "completedTaskListPage" && (
        <BriefDetails selectedBrief={fullBrief} statusDisplay={statusDisplay} />
      )}

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
