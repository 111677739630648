import React, { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";
import LogoutIcon from "../assets/icons-js-files/logout-icon";

const drawerWidth = 270;

SideDrawer.propTypes = {
  onMenuItemClick: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  mobileOpen: PropTypes.bool,
  defaultComponent: PropTypes.string,
  selectedComponent: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isBriefOwner: PropTypes.bool,
  isMarketResearchLead: PropTypes.bool,
  isMarketResearchPlanner: PropTypes.bool,
  // isEventsBusinessPartner: PropTypes.bool,
  // isFinancialBusinessPartner: PropTypes.bool,
  // isEventsManager: PropTypes.bool,
};

SideDrawer.defaultProps = {
  mobileOpen: false,
  defaultComponent: "",
  selectedComponent: "",
  isSuperAdmin: false,
  isAdmin: false,
  isBriefOwner: false,
  isMarketResearchLead: false,
  isMarketResearchPlanner: false,
  // isEventsBusinessPartner: false,
  // isFinancialBusinessPartner: false,
  // isEventsManager: false,
};

export default function SideDrawer(props) {
  const {
    onMenuItemClick,
    handleDrawerToggle,
    mobileOpen,
    defaultComponent,
    selectedComponent,
    isSuperAdmin,
    isAdmin,
    isBriefOwner,
    isMarketResearchLead,
    isMarketResearchPlanner,
    // isEventsBusinessPartner,
    // isFinancialBusinessPartner,
    // isEventsManager,
  } = props;

  const [openCollapse, setOpenCollapse] = useState(true);
  const [selectedItem, setSelectedItem] = useState(
    selectedComponent || defaultComponent
  );
  const [isDesktopDrawerOpen] = useState(true);

  useEffect(() => {
    setSelectedItem(selectedComponent);
  }, [selectedComponent]);

  const handleOpenAdditionalMenuItems = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleItemClick = (componentName) => {
    setSelectedItem(componentName);
    onMenuItemClick(componentName);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleMenuItems = () => {
    switch (true) {
      case isBriefOwner:
        return [
          {
            component: "Dashboard",
            text: "Dashboard",
            icon: (
              <img
                src="/project-icons/dashboard.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            text: "Briefs",
            hasNestedItems: true,
            icon: (
              <img
                src="/project-icons/clipboard-plus.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
            nestedItems: [
              {
                component: "MarketResearchBriefListPage",
                text: "Market Research and Customer Insights",
              },
              // Use this component when adding other brand team briefs to the system
              // {
              //   component: "BriefHomePage",
              //   text: "All Briefs",
              // },
              {
                component: "EmptyEventsPage",
                text: "Events",
              },
            ],
          },
        ];
      case isSuperAdmin:
        return [
          {
            component: "User Management",
            text: "Users",
            icon: (
              <img
                src="/project-icons/users.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            component: "Setups",
            text: "System setups",
            icon: (
              <img
                src="/project-icons/settings.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            component: "Tasks",
            text: "Task management",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
        ];
      case isMarketResearchLead && !isMarketResearchPlanner && !isAdmin:
        return [
          {
            component: "MarketResearchLeadHomePage",
            text: "Your Approval Tasks",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            component: "MarketResearchLeadAllTasksPage",
            text: "All Tasks",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
        ];
      case !isMarketResearchLead && isMarketResearchPlanner && !isAdmin:
        return [
          {
            component: "MarketResearchResearchPlannerHomePage",
            text: "Your Approval Tasks",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
        ];
      case !isMarketResearchLead && isMarketResearchPlanner && isAdmin:
        return [
          {
            component: "MarketResearchResearchPlannerHomePage",
            text: "Your Approval Tasks",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            component: "MarketResearchAdminPage",
            text: "Manage Users",
            icon: (
              <img
                src="/project-icons/settings.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
        ];
      case isMarketResearchLead && isAdmin:
        return [
          {
            component: "MarketResearchLeadHomePage",
            text: "Your Approval Tasks",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            component: "MarketResearchLeadAllTasksPage",
            text: "All Tasks",
            icon: (
              <img
                src="/project-icons/tasks.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
          {
            component: "MarketResearchAdminPage",
            text: "Manage Users",
            icon: (
              <img
                src="/project-icons/settings.svg"
                style={{ opacity: 0.5, width: "18px", height: "18px" }}
                alt=""
              />
            ),
          },
        ];
      default:
        return [];
    }
  };

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
          },
          cursor: "pointer",
        }}
      >
        {renderDrawerContent()}
      </Drawer>

      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
          },
          cursor: "pointer",
        }}
        open={isDesktopDrawerOpen}
      >
        {renderDrawerContent()}
      </Drawer>
    </Box>
  );

  function renderDrawerContent() {
    const menuItems = handleMenuItems();

    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
            border: "none",
          }}
        ></Box>
        <Divider sx={{ mb: 10 }} />

        {menuItems.map((menuItem) => (
          <List sx={{ mt: -2, pl: 2 }} key={menuItem.component}>
            {menuItem.hasNestedItems ? (
              <>
                <ListItemButton onClick={handleOpenAdditionalMenuItems}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.text} sx={{ fontSize: 16 }} />

                  {openCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menuItem.nestedItems.map((nestedItem, index) => (
                      <ListItemButton
                        sx={{ pl: 9 }}
                        key={nestedItem.component}
                        onClick={() => handleItemClick(nestedItem.text)}
                        selected={
                          selectedItem === nestedItem.text ||
                          (!selectedItem &&
                            defaultComponent === nestedItem.text)
                        } // Highlight selected nested item based on selectedComponent or defaultComponent
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {nestedItem.text}
                        </Typography>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItemButton
                onClick={() => handleItemClick(menuItem.component)}
                selected={
                  selectedItem === menuItem.component ||
                  (!selectedItem && defaultComponent === menuItem.component)
                }
                sx={{ mb: 1 }}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.text} />
              </ListItemButton>
            )}
          </List>
        ))}

        <List sx={{ mt: "auto", pb: 8 }}>
          <Box
            sx={{
              backgroundColor: "#f9f9f9",
              borderRadius: 5,
              width: "210px",
              height: "170px",
              ml: 2,
              mr: 2,
              mb: 2,
              p: 2,
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              Your feedback is valuable!
            </Typography>
            <Typography sx={{ fontSize: 12, mt: 2, mb: 3 }}>
              Kindly share with us a few details about your experience, and how
              you would like us to improve.
            </Typography>
            <Button
              style={{
                background: "#2CB34A",
                color: "#fff",
                width: "200px",
                borderRadius: "20px",
                "&:hover": {
                  background: "#000",
                },
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Share your feedback
            </Button>
          </Box>

          <Box sx={{ pl: 2 }}>
            <ListItem key="Help">
              <ListItemIcon>
                <img
                  src="/project-icons/headphones.svg"
                  alt="logout"
                  style={{ width: "16px", height: "16px", opacity: 0.5 }}
                />
              </ListItemIcon>
              <ListItemText primary="Help & Support" />
            </ListItem>
            <ListItemButton key="Logout" onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon
                  stroke="#ff2a58"
                  style={{ width: "16px", height: "16px", opacity: 0.8 }}
                />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ color: "#ff2a58" }} />
            </ListItemButton>
          </Box>
        </List>
      </>
    );
  }
}
