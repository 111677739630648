import SimpleCrypto from "simple-crypto-js";

const encryptData = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION;
let encryptInit;

if (encryptData === "true") {
  encryptInit = new SimpleCrypto(process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY);
}

export default {
  removeItem: (name) => {
    let tempName = name;
    if (encryptData === "true") {
      try {
        Object.entries(localStorage).forEach(([itemKey, itemValue]) => {
          if (encryptInit.decrypt(itemKey) === name) {
            tempName = itemKey;
          }
        });
      } catch (err) {
        handleError(err);
      }
    }
    localStorage.removeItem(tempName);
  },

  encrypt: (name, data) => {
    if (encryptData === "true") {
      try {
        name = encryptInit.encrypt(name);
        data = encryptInit.encrypt(data);
        Object.entries(localStorage).forEach(([itemKey, itemValue]) => {
          if (encryptInit.decrypt(itemKey) === name) {
            name = itemKey;
          }
        });
      } catch (err) {
        handleError(err);
      }
    }
    localStorage.setItem(name, data);
  },

  decrypt: (name) => {
    let decryptedData = localStorage.getItem(name);

    if (encryptData === "true") {
      try {
        Object.entries(localStorage).forEach(([itemKey, itemValue]) => {
          const decryptedKey = encryptInit.decrypt(itemKey);
          if (decryptedKey === name) {
            decryptedData = encryptInit.decrypt(itemValue);
          }
        });
      } catch (err) {
        handleError(err);
      }
    }

    return decryptedData ? JSON.parse(decryptedData) : null;
  },
};

function handleError(err) {
  const error = { message: err };
  localStorage.setItem("error", JSON.stringify(error));
}