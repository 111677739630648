import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import apiRequest from "../../services/api-request";
import EmptyBriefListPage from "./empty-brief-list-page";
import CreateBriefButton from "../create-brief-button";
import SnackbarComponent from "../snackbar";
import SelectBrandTeamBriefPage from "./select-brand-team-brief";
import { getMuiDatatableTheme } from "../../styles/material-ui-theme";
import MarketResearchBriefHomePage from "../../market-research/mr-brief-owner/mr-brief-home-page";
import MarketResearchBriefOwnerDetailsPage from "../../market-research/mr-brief-owner/mr-brief-owner-details-page";
import ConfirmationDialog from "../confirmation-dialog";
import DateUpdatedDisplay from "../../market-research/datatable-components/date-updated-display";
import DateCreatedDisplay from "../../market-research/datatable-components/date-created-display";
import DeleteIcon from "../../assets/icons-js-files/delete-icon";
import ViewIcon from "../../assets/icons-js-files/view-icon";
import EditIcon from "../../assets/icons-js-files/edit-icon";
import CancelIcon from "../../assets/icons-js-files/cancel-icon";
import BriefOwnerBriefStatusDisplay from "../../market-research/datatable-components/brief-owner-brief-status-display";
import DepartmentDisplay from "../../market-research/datatable-components/department-display";

import StatusFilterDropdown from "./filters/status-filter-dropdown";
import DepartmentFilterDropdown from "./filters/department-filter-dropdown";

const validationSchema = yup.object({});

const msBriefTypes = [
  {
    msBriefTypeName: "Customised Brief",
    msBriefTypeId: "1",
    msBriefTypeDescription: "",
  },
  {
    msBriefTypeName: "Communication Testing Brief",
    msBriefTypeId: "2",
    msBriefTypeDescription: "Coming soon",
  },
];

export default function BriefHomePage() {
  // const { firstname, lastname } =
  //   customLocalStorage.decrypt("brandPortalUser") || {};

  const initialState = {
    currentPage: "briefHomePage",
    currentBrief: {},
    briefStatuses: [],
    brandCoeId: 0,
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [briefIdToDelete, setBriefIdToDelete] = useState(null);

  const [briefIdToCancel, setBriefIdToCancel] = useState(null);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  const [allBriefs, setAllBriefs] = useState([]);
  const [targetSegments, setAllTargetSegments] = useState([]);
  const [regions, setAllRegions] = useState([]);
  const [ageBrackets, setAllAgeBrackets] = useState([]);
  const [departments, setAllDepartments] = useState([]);
  const [brandTeams, setAllBrandTeams] = useState([]);

  const [selectedBrief, setSelectedBrief] = useState(null);

  const [selectedBriefStatuses, setSelectedBriefStatuses] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [msBriefType, setMsBriefType] = useState(null);

  const { currentPage, loading, briefStatuses, snackBarSeverity } = state;

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log({ values });
      // handleUpdateBrief(values);
    },
  });

  const updateSelectedBrief = (newBrief) => {
    setSelectedBrief(newBrief);
  };

  const handleOpen = (currentPage) => {
    setState((prevState) => ({
      ...prevState,
      currentPage,
    }));
  };

  const resetState = () => {
    setState((prevState) => ({
      ...prevState,
      currentBrief: {},
    }));
    // window.location.reload();
  };

  const handleGoBack = () => {
    setState((prevState) => ({
      ...prevState,
      currentPage: "briefHomePage",
    }));
    resetState();
  };

  const buttonText = "Create a Brief";
  const emptyPageTitle = "No Brief Created";
  const emptyPageDescription =
    "Click on create brief button to create your first brief.";

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity } = params;

    if (severity === "error") {
      snackBarHandle(true, data, severity);
    }

    if (severity === "success") {
      if (dataName === "briefStatuses") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }

      if (dataName === "brandTeams") {
        setAllBrandTeams(data?.data || []);
      }

      if (dataName === "targetSegments") {
        setAllTargetSegments(data?.data || []);
      }

      if (dataName === "regions") {
        setAllRegions(data?.data || []);
      }
      if (dataName === "ageBrackets") {
        setAllAgeBrackets(data?.data || []);
      }
      if (dataName === "departments") {
        setAllDepartments(data?.data || []);
      }

      if (dataName === "allBriefs") {
        setAllBriefs(data?.data || []);
      }

      if (dataName === "selectedBriefDetails") {
        console.log({ data });
        setSelectedBrief((prevBrief) => ({
          ...prevBrief,
          ...data?.data,
        }));
      }

      if (dataName === "deletedBrief") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data,
        }));
        window.location.reload();
      }

      snackBarHandle(true, data?.message, severity);
    }
  };

  const handleGetAllTargetSegments = async () => {
    await makeApiRequest({
      uri: `/market-research/setup/target-segments`,
      dataName: `targetSegments`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllRegions = async () => {
    await makeApiRequest({
      uri: `/market-research/setup/regions`,
      dataName: `regions`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllAgeBrackets = async () => {
    await makeApiRequest({
      uri: `/market-research/setup/age-bracket`,
      dataName: `ageBrackets`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllBrandTeams = async () => {
    await makeApiRequest({
      uri: `/market-research/setup/brand-coe`,
      dataName: `brandTeams`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllCoeDepartments = async () => {
    await makeApiRequest({
      uri: `/market-research/setup/coe-department`,
      dataName: `departments`,
      method: `get`,
      data: "",
    });
  };

  const handleGetBriefStatuses = async () => {
    await makeApiRequest({
      uri: `/market-research/enum/brief-statuses`,
      dataName: `briefStatuses`,
      method: `get`,
      data: "",
    });
  };

  const handleGetAllBriefs = async () => {
    await makeApiRequest({
      uri: `/market-research/briefs`,
      dataName: `allBriefs`,
      method: `get`,
      data: "",
    });
  };

  const handleGetBriefDetails = async (briefId) => {
    await makeApiRequest({
      uri: `/market-research/briefs/${briefId}`,
      dataName: `selectedBriefDetails`,
      method: `get`,
      data: "",
    });
  };

  const handleGetBriefsByStatus = async (selectedValues) => {
    setSelectedBriefStatuses(selectedValues);
    const statuses = selectedValues.join(",");
    await makeApiRequest({
      uri: `/market-research/briefs?statuses=${statuses}`,
      method: "get",
      dataName: `allBriefs`,
      data: "",
    });
  };

  const handleGetBriefsByDepartment = async (selectedValues) => {
    setSelectedDepartments(selectedValues);
    const departments = selectedValues.join(",");
    await makeApiRequest({
      uri: `/market-research/briefs?departments=${departments}`,
      method: "get",
      dataName: `allBriefs`,
      data: "",
    });
  };

  const handleBriefStatusChange = (selectedValues) => {
    setSelectedBriefStatuses(selectedValues);
    handleGetBriefsByStatus(selectedValues);
  };

  const handleDepartmentChange = (selectedValues) => {
    setSelectedDepartments(selectedValues);
    handleGetBriefsByDepartment(selectedValues);
  };

  const handleOpenDeleteBriefDialog = (selectedBrief) => {
    setBriefIdToDelete(selectedBrief?.briefId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteBrief = async (briefId) => {
    await makeApiRequest({
      uri: `/market-research/briefs/${briefId}`,
      dataName: `deletedBrief`,
      method: `delete`,
      data: {},
    });
  };

  const handleConfirmDeleteBrief = async () => {
    if (briefIdToDelete) {
      await handleDeleteBrief(briefIdToDelete);
      // Refresh the component after deletion
      await handleApiData({ dataName: "allBriefs" });
    }
    setDeleteConfirmationOpen(false);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllBriefs();
      await handleGetAllTargetSegments();
      await handleGetAllRegions();
      await handleGetAllAgeBrackets();
      await handleGetAllBrandTeams();
      await handleGetAllCoeDepartments();
      await handleGetBriefStatuses();
    })();
  }, []);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const togglePage = (params) => {
    const {
      currentPage,
      selectedBrief,
      targetSegments,
      departments,
      regions,
      ageBrackets,
    } = params;

    if (
      (params.currentPage === "mrBriefingForm" && params.selectedBrief) ||
      (params.currentPage === "mrBriefDetailsPage" && params.selectedBrief)
    ) {
      setSelectedBrief(selectedBrief);
    } else {
      setSelectedBrief(null);
    }

    setState((prevState) => ({
      ...prevState,
      currentPage,
      selectedBrief,
      targetSegments,
      regions,
      ageBrackets,
      departments,
      navigatedFrom: currentPage,
    }));
  };

  // const actions = ["View Brief", "Delete Brief"];

  const handleOpenCancelBriefDialog = (selectedBrief) => {
    setBriefIdToCancel(selectedBrief?.briefId);
    setCancelConfirmationOpen(true);
  };

  const handleCancelBrief = async (briefId) => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/briefs/submit-for-approval`,
      dataName: `cancelledBrief`,
      method: `post`,
      data: {
        briefId,
        comment: "Brief cancelled by Brief Owner",
        approvalTask: "CANCEL",
      },
    });
    handleLoader(false);
  };

  const handleConfirmCancelBrief = async () => {
    if (briefIdToCancel) {
      await handleCancelBrief(briefIdToCancel);
      await handleApiData({ dataName: "allBriefs" });
    }
    setCancelConfirmationOpen(false);
  };

  const handleCancelCancellation = () => {
    setCancelConfirmationOpen(false);
  };

  const getBrandCoeId = (teamName) => {
    const team = brandTeams.find((item) => item.brandCoeName === teamName);
    return team ? team.id : null;
  };

  const marketResearchTeamName = "Market Research";
  const brandCoeId = getBrandCoeId(marketResearchTeamName);

  const briefColumns = [
    {
      name: "briefTitle",
      label: "Brief Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "coeDepartment",
      label: "Brief Department",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <DepartmentDisplay departmentName={value?.departmentName} />
        ),
      },
    },
    {
      name: "approximateBudget",
      label: "Brief Budget",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Date created",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateCreatedDisplay dateCreated={value} />,
      },
    },
    {
      name: "updatedAt",
      label: "Last updated",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => <DateUpdatedDisplay dateUpdated={value} />,
      },
    },
    {
      name: "briefStatus",
      label: "Status",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (status) => (
          <BriefOwnerBriefStatusDisplay status={status} />
        ),
      },
    },
    {
      name: "briefId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[5]; // Assuming 'briefStatus' is the fifth column
          const isEditable = [
            "BRIEF_IN_DRAFT",
            "BRIEF_COMPLETE",
            "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD",
            "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER",
          ].includes(status);
          const selectedBrief = allBriefs?.find(
            (brief) => brief?.briefId === value
          );

          return (
            <>
              {isEditable && (
                <Tooltip title="Edit Brief">
                  <IconButton
                    onClick={async () => {
                      await handleGetBriefDetails(selectedBrief?.briefId);
                      togglePage({
                        currentPage: "mrBriefingForm",
                        selectedBrief,
                      });
                    }}
                  >
                    <EditIcon
                      stroke="#121212"
                      style={{ marginRight: "10px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!isEditable && (
                <>
                  <Tooltip title="View Brief">
                    <IconButton
                      onClick={() =>
                        togglePage({
                          currentPage: "mrBriefDetailsPage",
                          selectedBrief,
                        })
                      }
                    >
                      <ViewIcon
                        stroke="#121212"
                        style={{ marginRight: "10px" }}
                      />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Delete Brief">
                    <IconButton
                      onClick={() => handleOpenDeleteBriefDialog(selectedBrief)}
                    >
                      <DeleteIcon
                        stroke="#ff2a58"
                        style={{
                          marginRight: "10px",
                        }}
                      />
                    </IconButton>
                  </Tooltip> */}
                </>
              )}
              {["BRIEF_IN_DRAFT", "BRIEF_COMPLETE", "BRIEF_REJECTED"].includes(
                status
              ) && (
                <Tooltip title="Delete Brief">
                  <IconButton
                    onClick={() => handleOpenDeleteBriefDialog(selectedBrief)}
                  >
                    <DeleteIcon
                      stroke="#ff2a58"
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {[
                "BRIEF_CHANGES_REQUESTED_MARKET_RESEARCH_LEAD",
                "BRIEF_CHANGES_REQUESTED_RESEARCH_PLANNER",
              ].includes(status) && (
                <Tooltip title="Cancel Brief">
                  <IconButton
                    onClick={() => handleOpenCancelBriefDialog(selectedBrief)}
                  >
                    <CancelIcon
                      stroke="#ff2a58"
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    download: false,
    responsive: "stacked",
    print: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 20, 25, 50, 100],
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, pl: 5, pr: 10, mb: 5, mt: 8 }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      {currentPage === "briefHomePage" && (
        <Box sx={{ borderColor: "divider", ml: 3 }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <Typography
              variant="h5"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              All Briefs
            </Typography>

            <CreateBriefButton
              buttonText={buttonText}
              emptyPageTitle={emptyPageTitle}
              onClick={() => handleOpen("mrBriefingForm")}
            />
          </Stack>

          {/* <Typography textAlign="left" sx={{ mt: 1, mb: 3 }}>
            Welcome {firstname} {lastname}
          </Typography> */}
        </Box>
      )}

      <Box sx={{ mb: 4, mt: 5, ml: 3 }}>
        {currentPage === "briefHomePage" && allBriefs?.length > 0 && (
          <ThemeProvider theme={getMuiDatatableTheme()}>
            <FormikProvider value={formik}>
              <MUIDataTable
                title={
                  <Stack direction="row" spacing={3} sx={{ mb: 2, mt: 3 }}>
                    <StatusFilterDropdown
                      name="statusIds"
                      label="Brief Status"
                      options={briefStatuses.map((status) => ({
                        name: status,
                        id: status,
                      }))}
                      selectedFilterId={selectedBriefStatuses}
                      onFilterChange={handleBriefStatusChange}
                    />
                    <DepartmentFilterDropdown
                      name="departmentIds"
                      label="Brief Department"
                      options={departments.map((dept) => ({
                        name: dept.departmentName,
                        id: dept.id,
                      }))}
                      selectedFilterId={selectedDepartments}
                      onFilterChange={handleDepartmentChange}
                    />
                  </Stack>
                }
                data={allBriefs}
                columns={briefColumns}
                options={options}
              />
            </FormikProvider>
          </ThemeProvider>
        )}

        {currentPage === "briefHomePage" && allBriefs?.length < 1 && (
          <Box sx={{ ml: -3 }}>
            <EmptyBriefListPage
              buttonText={buttonText}
              emptyPageTitle={emptyPageTitle}
              emptyPageDescription={emptyPageDescription}
              onClick={() => handleOpen("mrBriefingForm")}
            />
          </Box>
        )}

        {currentPage === "selectBrandTeamPage" && (
          <SelectBrandTeamBriefPage
            handleGoBack={handleGoBack}
            allBriefs={allBriefs}
            setSelectedBrief={setSelectedBrief}
            handleGetAllBriefs={handleGetAllBriefs}
            updateSelectedBrief={updateSelectedBrief}
            msBriefTypes={msBriefTypes}
            targetSegments={targetSegments}
            regions={regions}
            ageBrackets={ageBrackets}
            departments={departments}
            msBriefType={msBriefType}
            setMsBriefType={setMsBriefType}
          />
        )}

        {currentPage === "mrBriefingForm" && (
          <MarketResearchBriefHomePage
            handleGoBack={handleGoBack}
            handleGetAllBriefs={handleGetAllBriefs}
            allBriefs={allBriefs}
            selectedBrief={selectedBrief}
            updateSelectedBrief={updateSelectedBrief}
            msBriefTypes={msBriefTypes}
            targetSegments={targetSegments}
            regions={regions}
            ageBrackets={ageBrackets}
            departments={departments}
            brandCoeId={brandCoeId}
          />
        )}

        {currentPage === "mrBriefDetailsPage" && (
          <MarketResearchBriefOwnerDetailsPage
            selectedBrief={selectedBrief}
            togglePage={togglePage}
            navigatedFrom="briefHomePage"
          />
        )}
      </Box>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        handleClose={handleCancelDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this brief?"
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        handleCancel={handleCancelDelete}
        handleConfirm={handleConfirmDeleteBrief}
      />

      <ConfirmationDialog
        open={cancelConfirmationOpen}
        handleClose={handleCancelCancellation}
        title="Confirm Cancellation"
        content="Are you sure you want to cancel brief?"
        cancelButtonText="Close"
        confirmButtonText="Confirm Cancellation"
        handleCancel={handleCancelCancellation}
        handleConfirm={handleConfirmCancelBrief}
      />

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
