import React from "react";
import PropTypes from "prop-types";

const SuccessIcon = ({ stroke }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3332 14.7735V16.0001C29.3315 18.8754 28.4005 21.673 26.679 23.9759C24.9574 26.2788 22.5376 27.9634 19.7803 28.7787C17.0231 29.5939 14.0762 29.496 11.3791 28.4996C8.68208 27.5032 6.37938 25.6616 4.81445 23.2496C3.24953 20.8375 2.50623 17.9842 2.69541 15.1152C2.88459 12.2462 3.99611 9.51525 5.86421 7.32957C7.73231 5.1439 10.2569 3.62065 13.0614 2.98701C15.866 2.35336 18.8002 2.64326 21.4265 3.81348M29.3332 5.33348L15.9998 18.6801L11.9998 14.6801"
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

SuccessIcon.propTypes = {
  stroke: PropTypes.string,
};

SuccessIcon.defaultProps = {
  stroke: "",
};

export default SuccessIcon;
