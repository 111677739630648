import React from "react";
import {
  Box,
  Grid,
  Container,
  List,
  ListItem,
  Typography,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { sectionHeadingStyle } from "../styles/common-styles";
import PropTypes from "prop-types";

const BriefDetails = ({ selectedBrief, statusDisplay }) => {
  return (
    <>
      <Button
        status={selectedBrief?.briefStatus}
        sx={{
          backgroundColor: statusDisplay.backgroundColor,
          color: statusDisplay.textColor,
          fontSize: statusDisplay.fontSize,
          fontWeight: statusDisplay.fontWeight,
          borderRadius: statusDisplay.borderRadius,
          width: statusDisplay.width,
          fontFamily: "Futura",
          textTransform: "none",
          padding: statusDisplay.padding,
          mb: 3,
        }}
      >
        {statusDisplay.text}
      </Button>

      <Grid container spacing={2}>
        <Container sx={{ pt: 3 }}>
          <Typography textAlign="left" style={sectionHeadingStyle}>
            Brief Summary
          </Typography>

          <Container>
            <Grid container sx={{ pt: 2, ml: -4 }}>
              <Grid item xs={6} md={6} lg={6}>
                <List>
                  {[
                    "Brief Title",
                    "Expected Delivery Date",
                    "Department",
                    "Approximate Budget",
                    "Budget Line",
                    "Date of Creation",
                  ].map((value) => {
                    const listItemId = `${value}`;
                    return (
                      <ListItem sx={{ p: 1 }} key="">
                        <Typography
                          key=""
                          id={listItemId}
                          sx={{ fontWeight: "bold" }}
                        >
                          {`${value}`}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>

              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <Typography>{selectedBrief?.briefTitle}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.expectedDeliveryDate}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.coeDepartment?.departmentName}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{selectedBrief?.approximateBudget}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{selectedBrief?.budgetLine}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography>
                      {new Date(selectedBrief?.createdAt).toLocaleString()}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Typography
        textAlign="left"
        style={sectionHeadingStyle}
        sx={{ mt: 2, ml: 1 }}
      >
        Brief Owner Details
      </Typography>

      <Container>
        <Grid container sx={{ pt: 2, ml: -3 }}>
          <Grid item xs={6} md={6} lg={6}>
            <List>
              {["Full Name", "Email", "Phone Number"].map((value) => {
                const listItemId = `${value}`;
                return (
                  <ListItem sx={{ p: 1 }} key="">
                    <Typography
                      key=""
                      id={listItemId}
                      sx={{ fontWeight: "bold" }}
                    >
                      {`${value}`}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Grid item xs={6}>
            <List>
              <ListItem>
                <Typography>
                  {selectedBrief?.briefOwner?.firstname}{" "}
                  {selectedBrief?.briefOwner?.lastname}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>{selectedBrief?.briefOwner?.email}</Typography>
              </ListItem>
              <ListItem>
                <Typography>{selectedBrief?.briefOwner?.msisdn}</Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>

      <Divider sx={{ mt: 2 }} />

      <Typography
        textAlign="left"
        style={sectionHeadingStyle}
        sx={{ mt: 2, ml: 1 }}
      >
        Brief Reviewer Details
      </Typography>

      {selectedBrief?.researchLead && (
        <Box>
          <Typography
            textAlign="left"
            sx={{
              mt: 2,
              ml: 1,
              fontWeight: "bold",
              fontSize: "15px",
              color: "#2CB34A",
            }}
          >
            Research Lead Details
          </Typography>

          <Container>
            <Grid container sx={{ pt: 2, ml: -3 }}>
              <Grid item xs={6} md={6} lg={6}>
                <List>
                  {["Full Name", "Email", "Phone Number"].map((value) => {
                    const listItemId = `${value}`;
                    return (
                      <ListItem sx={{ p: 1 }} key="">
                        <Typography
                          key=""
                          id={listItemId}
                          sx={{ fontWeight: "bold" }}
                        >
                          {`${value}`}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>

              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.researchLead?.firstname}{" "}
                      {selectedBrief?.researchLead?.lastname}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.researchLead?.email}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.researchLead?.msisdn}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      {selectedBrief?.researchPlanner && (
        <Box>
          <Typography
            textAlign="left"
            sx={{
              mt: 2,
              ml: 1,
              fontWeight: "bold",
              fontSize: "15px",
              color: "#2CB34A",
            }}
          >
            Research Planner Details
          </Typography>

          <Container>
            <Grid container sx={{ pt: 2, ml: -3 }}>
              <Grid item xs={6} md={6} lg={6}>
                <List>
                  {["Full Name", "Email", "Phone Number"].map((value) => {
                    const listItemId = `${value}`;
                    return (
                      <ListItem sx={{ p: 1 }} key="">
                        <Typography
                          key=""
                          id={listItemId}
                          sx={{ fontWeight: "bold" }}
                        >
                          {`${value}`}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>

              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.researchPlanner?.firstname}{" "}
                      {selectedBrief?.researchPlanner?.lastname}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.researchPlanner?.email}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      {selectedBrief?.researchPlanner?.msisdn}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <Divider sx={{ mt: 2 }} />

      <Box sx={{ mt: 2, ml: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Target Segments</Typography>

        <Container>
          <List sx={{ ml: -5 }}>
            {(selectedBrief?.targetSegments || []).map((segment) => (
              <ListItem key={segment?.id}>
                <Typography>{segment?.targetSegmentName}</Typography>
              </ListItem>
            ))}
          </List>
        </Container>

        <Typography mt={2} sx={{ fontWeight: "bold" }}>
          Regions
        </Typography>

        <Container>
          <List sx={{ ml: -5 }}>
            {(selectedBrief?.regions || []).map((region) => (
              <ListItem key={region?.id}>
                <Typography>{region?.regionName}</Typography>
              </ListItem>
            ))}
          </List>
        </Container>

        <Typography mt={2} sx={{ fontWeight: "bold" }}>
          Age Brackets
        </Typography>

        <Container>
          <List sx={{ ml: -5 }}>
            {(selectedBrief?.ageBrackets || []).map((bracket) => (
              <ListItem key={bracket?.id}>
                <Typography>{bracket?.ageBracketName}</Typography>
              </ListItem>
            ))}
          </List>
        </Container>
      </Box>

      <Divider sx={{ mt: 2 }} />

      <Typography textAlign="left" style={sectionHeadingStyle} sx={{ mt: 2 }}>
        Brief Background and Objectives
      </Typography>

      {/*  */}
      <Box sx={{ mt: 2 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Study Background
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(selectedBrief?.studyBackground?.blocks || []).map((block) => (
                <ListItem key={block.key}>
                  <ListItemText primary={block.text} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Marketing Objectives
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(selectedBrief?.marketingObjectives?.blocks || []).map(
                (block) => (
                  <ListItem key={block.key}>
                    <ListItemText primary={block.text} />
                  </ListItem>
                )
              )}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Research Objectives
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(selectedBrief?.researchObjectives?.blocks || []).map(
                (block) => (
                  <ListItem key={block.key}>
                    <ListItemText primary={block.text} />
                  </ListItem>
                )
              )}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography mt={3} mb={3} sx={{ fontWeight: "bold" }}>
              Execution Plan
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List>
              {(selectedBrief?.executionPlan?.blocks || []).map((block) => (
                <ListItem key={block.key}>
                  <ListItemText primary={block.text} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

BriefDetails.propTypes = {
  selectedBrief: PropTypes.object,
  statusDisplay: PropTypes.object,
};

BriefDetails.defaultProps = {
  selectedBrief: {},
  statusDisplay: {},
};

export default BriefDetails;
