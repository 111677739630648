import React, { useEffect, useState } from "react";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  IconButton,
} from "@mui/material";
import apiRequest from "../../services/api-request";
import { ThemeProvider } from "@mui/styles";
import { numberContainer, primaryButton } from "../../styles/common-styles";
import AddMarketResearchUser from "./add-mr-user";
import MUIDataTable from "mui-datatables";
import { AddCircle } from "@mui/icons-material";
import customLocalStorage from "../../services/local-storage";
import CustomEmailVerificationStatusCell from "../../shared-components/mui-table-custom-user-status";
import CustomStatusCell from "../../shared-components/mui-table-custom-status-cell";
import EditIcon from "../../assets/icons-js-files/edit-icon";
import SnackbarComponent from "../../shared-components/snackbar";

export default function MarketResearchAdminPage() {
  const { brandCoeId } = customLocalStorage.decrypt("brandPortalUser") || {};

  const initialState = {
    users: [],
    currentUser: {},
    marketResearchRoles: [],
    loading: false,
    snackBarSeverity: "info",
  };

  const [state, setState] = useState(initialState);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const {
    action,
    currentUser,
    users,
    marketResearchRoles,
    loading,
    snackBarSeverity,
  } = state;

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity } = params;

    if (severity === "success") {
      if (dataName === "users") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data ?? [],
        }));
      }

      if (dataName === "marketResearchRoles") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data ?? [],
        }));
      }
    }
    snackBarHandle(true, data?.message, severity);
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      handleLoader,
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    makeApiRequest({
      uri: `/user-management/users`,
      dataName: `users`,
      method: `get`,
      data: {},
    });
    makeApiRequest({
      uri: `/user-management/roles`,
      dataName: `marketResearchRoles`,
      method: `get`,
      data: {},
    });
  }, []);

  const allUserColumns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "msisdn",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "brandTeam",
      label: "Brand Team",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Stack direction="row" spacing={3}>
              <Typography sx={{ fontSize: 15 }}>
                {value?.brandCoeName}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "role",
      label: "Roles",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <Stack direction="column" spacing={1}>
            {value.map((role) => (
              <Typography key={role.id} sx={{ fontSize: 15 }}>
                {role.displayName}
              </Typography>
            ))}
          </Stack>
        ),
      },
    },
    {
      name: "emailVerificationStatus",
      label: "Email Verified",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <CustomEmailVerificationStatusCell status={value} />
        ),
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => <CustomStatusCell status={value} />,
      },
    },
    {
      name: "userId",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const currentUser = users?.filter((user) => user?.userId == value)[0];
          return (
            <Stack direction="row">
              <IconButton
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    currentUser,
                    action: "Update",
                  }))
                }
              >
                <EditIcon
                  stroke="#121212"
                  style={{ marginRight: "10px", width: "18px" }}
                />
              </IconButton>
            </Stack>
          );
        },
      },
    },
  ];

  const marketResearchUsers = users.filter(
    (user) => user?.brandTeam?.brandCoeName === "Market Research"
  );

  const userCount = marketResearchUsers?.length;

  const options = {
    selectableRows: "none",
    download: false,
    jumpToPage: true,
  };

  const handleAddUser = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Add",
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Close",
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status, message, severity) => {
    setState((prevState) => ({
      ...prevState,
      snackBarSeverity: severity,
    }));

    setSnackbarMessage(message);
    setSnackbarOpen(status);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mt: 8, ml: 8, mr: 8 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          Manage Users
        </Typography>

        <Typography sx={{ mt: 2 }}>
          Add or update the users of your team
        </Typography>

        <Box sx={{ mt: 3, mb: 3 }}>
          <Typography variant="h6" textAlign="left" sx={{ fontWeight: "bold" }}>
            <span>Users</span>
            <span style={numberContainer}>{userCount}</span>
          </Typography>
        </Box>

        <Box>
          {(action === "Add" || action === "Update") && (
            <AddMarketResearchUser
              action={action}
              users={users}
              marketResearchRoles={marketResearchRoles}
              brandCoeId={brandCoeId}
              handleCloseDialog={handleCloseDialog}
              currentUser={currentUser}
            />
          )}
        </Box>

        <MUIDataTable
          style={{ padding: 10 }}
          title={
            <Button
              data-testid="add-user-button"
              style={primaryButton}
              onClick={() => {
                handleAddUser();
              }}
              startIcon={<AddCircle />}
            >
              New User
            </Button>
          }
          data={marketResearchUsers}
          columns={allUserColumns}
          options={options}
        />
      </Box>

      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </ThemeProvider>
  );
}
