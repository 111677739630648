import React from "react";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

const CustomEmailVerificationStatusCell = ({ status }) => {
    const getButtonProps = (status) => {
      if (status) {
        return {
          text: "True",
          backgroundColor: "rgba(44, 179, 74, 0.15)",
          textColor: "#2CB34A",
        };
      } else {
        return {
          text: "False",
          backgroundColor: "#E5E5E5",
          textColor: "#fff",
        };
      }
    };
  
    const { text, backgroundColor, textColor } = getButtonProps(status);
  
    return (
      <Button
        variant="contained"
        disableElevation
        sx={{
          backgroundColor,
          color: textColor,
          fontFamily: "Futura",
          textTransform: "none",
          borderRadius: "20px",
          padding: "5px 15px",
        }}
      >
        <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>{text}</Typography>
      </Button>
    );
  };

  CustomEmailVerificationStatusCell.propTypes = {
    status: PropTypes.string,
  };

  CustomEmailVerificationStatusCell.defaultProps = {
    status: "",
  };
  
  export default CustomEmailVerificationStatusCell;