import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { inputStyle, helperTextStyle } from "../../../styles/form-styles";
import {
  primaryButton,
  disabledButton,
  setupsTableHeaderStyle,
} from "../../../styles/common-styles";
import apiRequest from "../../../services/api-request";
import SnackbarComponent from "../../../shared-components/snackbar";
import * as yup from "yup";
import { useFormik } from "formik";
import EditIcon from "../../../assets/icons-js-files/edit-icon";
import DeleteIcon from "../../../assets/icons-js-files/delete-icon";

const validationSchema = yup.object({
  brandCoeName: yup
    .string()
    .required("Brand team name cannot be empty")
    .matches(/^[a-zA-Z\s-'.,]+$/, "Invalid characters in brand team name")
    .max(
      Number(process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY),
      "Exceeded max length"
    ),
  brandCoeShortCode: yup
    .string()
    .required("Brand team short code cannot be empty")
    .matches(/^[a-zA-Z]+$/, "Invalid characters in short code")
    .max(
      Number(process.env.REACT_APP_MAX_INPUT_LENGTH_TEN),
      "Exceeded max length"
    ),
  brandCoeDescription: yup
    .string()
    .required("Brand team description is required")
    .matches(/^[a-zA-Z0-9\s-'.,]+$/, "Invalid characters in description")
    .max(
      Number(process.env.REACT_APP_MAX_INPUT_LENGTH_FIVE_HUNDRED),
      "Exceeded max length"
    ),
});

export default function BrandCoeTeamsSetup() {
  const [state, setState] = useState({
    brandTeams: [],
    currentBrandTeam: {},
    loading: false,
    snackbarOpen: false,
    snackbarMessage: "",
    snackBarSeverity: "info",
  });
  const [isUpdatingBrandTeam, setIsUpdatingBrandTeam] = useState(false);

  const {
    brandTeams,
    currentBrandTeam,
    loading,
    snackbarOpen,
    snackbarMessage,
    snackBarSeverity,
  } = state;

  const formik = useFormik({
    initialValues: {
      brandCoeName: currentBrandTeam.brandCoeName || "",
      brandCoeShortCode: currentBrandTeam.brandCoeShortCode || "",
      brandCoeDescription: currentBrandTeam.brandCoeDescription || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleAddbrandTeams(values);
    },
  });

  const handleAddbrandTeams = async (values) => {
    let newBrandTeams = [...brandTeams];
    if (newBrandTeams?.length >= 15) {
      alert("Can't add more than 15 brand teams");
      return;
    }
    if (isUpdatingBrandTeam) {
      const indexOfBrandTeam = newBrandTeams.findIndex(
        (brandTeam) => brandTeam?.id === values?.id
      );
      newBrandTeams[indexOfBrandTeam] = values;
      setIsUpdatingBrandTeam(false);
    } else {
      newBrandTeams.push(values);
    }
    newBrandTeams = removeDuplicateBrandTeamFromArrayOfObjects(newBrandTeams);

    setState((prevState) => ({
      ...prevState,
      brandTeams: newBrandTeams,
    }));

    handleSubmitBrandTeam(values);
    formik.resetForm();
  };

  const removeDuplicateBrandTeamFromArrayOfObjects = (objArray) => {
    if (objArray.length < 1) return [];

    return [
      ...new Map(objArray.map((item) => [item["brandCoeName"], item])).values(),
    ];
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitBrandTeam = async (brandTeam) => {
    const action = brandTeam?.id ? "update" : "create";
    let uri = `/market-research/setup/brand-coe`;
    let method = `post`;
    if (action === "update") {
      uri = `/market-research/setup/brand-coe`;
      method = `put`;
    }
    await makeApiRequest({
      uri,
      dataName: `addedBrandTeam`,
      method,
      data: {
        brandTeam,
      },
    });
  };

  const handleApiData = async (params) => {
    const { dataName, data, severity, message } = params;

    if (severity === "success") {
      if (dataName === "brandTeams") {
        console.log({ data });

        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.data || [],
        }));
      }

      if (dataName === "addedBrandTeam") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleSelectBrandTeam = (brandTeam) => {
    setIsUpdatingBrandTeam(true);
    setState((prevState) => ({
      ...prevState,
      currentBrandTeam: brandTeam,
    }));
  };

  const handleDeleteBrandTeam = async (id) => {
    await makeApiRequest({
      uri: `/market-research/setup/brand-coe/${id}`,
      dataName: `deletedBrandTeam`,
      method: `delete`,
      data: {},
    });
    const newBrandTeams = brandTeams.filter((brandTeam) => brandTeam.id !== id);
    setState((prevState) => ({
      ...prevState,
      brandTeams: newBrandTeams,
    }));
  };

  const handleGetAllBrandTeams = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/market-research/setup/brand-coe`,
      dataName: `brandTeams`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetAllBrandTeams();
    })();
  }, []);

  console.log({ brandTeams });

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleCloseSnackBar = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2, width: "700px" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box>
        <Typography sx={{ mt: 1, fontSize: "14px" }}>
          Add/Edit Brand COE Teams
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Brand Team Name"
              name="brandCoeName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandCoeName}
              fullWidth
              required
            />
            {formik.touched.brandCoeName && formik.errors.brandCoeName && (
              <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                {formik.errors.brandCoeName}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Brand Team Short Code"
              name="brandCoeShortCode"
              type="text"
              size="small"
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandCoeShortCode}
              fullWidth
              required
            />
            {formik.touched.brandCoeShortCode &&
              formik.errors.brandCoeShortCode && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -1 }}>
                  {formik.errors.brandCoeShortCode}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>

        <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Brand Team Description"
              name="brandCoeDescription"
              type="text"
              size="small"
              multiline
              rows={3}
              style={inputStyle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandCoeDescription}
              fullWidth
              required
            />
            {formik.touched.brandCoeDescription &&
              formik.errors.brandCoeDescription && (
                <FormHelperText style={helperTextStyle} sx={{ mt: -3 }}>
                  {formik.errors.brandCoeDescription}
                </FormHelperText>
              )}
          </FormControl>
        </Stack>
        <Box sx={{ mt: 3 }}>
          <Button
            data-testid="submit-brand-team-button"
            type="submit"
            style={
              formik.isValid && formik.dirty ? primaryButton : disabledButton
            }
            disabled={!(formik.isValid && formik.dirty)}
          >
            {isUpdatingBrandTeam ? "UPDATE" : "ADD"}
          </Button>
        </Box>
      </form>

      <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5, mt: 4 }}>
        <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
          Brand COE Teams
        </Typography>

        <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={setupsTableHeaderStyle}>
                  Brand Team Name
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Brand Team Short Code
                </TableCell>
                <TableCell style={setupsTableHeaderStyle}>
                  Brand Team Description
                </TableCell>

                <TableCell style={setupsTableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {brandTeams?.map((brandTeam) => (
                <TableRow
                key={brandTeam}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {brandTeam?.brandCoeName}
                  </TableCell>

                  <TableCell>{brandTeam?.brandCoeShortCode}</TableCell>
                  <TableCell>{brandTeam?.brandCoeDescription}</TableCell>

                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleSelectBrandTeam(brandTeam);
                      }}
                    >
                      <EditIcon stroke="#121212" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteBrandTeam(brandTeam?.id);
                      }}
                    >
                      <DeleteIcon stroke="#ff2a58" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <SnackbarComponent
        snackbarMessage={snackbarMessage}
        snackBarSeverity={snackBarSeverity}
        snackbarOpen={snackbarOpen}
        snackBarHandle={snackBarHandle}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </Box>
  );
}
