import CryptoJS from "crypto-js";

// Encrypts the given data using AES encryption
export const encrypt = (data) => {
  console.log({ data });
  return CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PUBLIC_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(
        process.env.REACT_APP_PUBLIC_KEY.substring(0, 16)
      ),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
};

// Decrypts the given encrypted data using AES decryption
export const decrypt = (encryptedData) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PUBLIC_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(
          process.env.REACT_APP_PUBLIC_KEY.substring(0, 16)
        ),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    console.log(JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)));
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed", error);
    return null;
  }
};
